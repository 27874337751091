import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {getAuthState} from "../../redux/auth/authSelectors";
import {connect} from "react-redux";
import "./DMChatMainDialog.scss"
import {FaPlus, FaLocationArrow} from "react-icons/fa";
import {DEFAULT_PAGINATION} from "./DMChat";
import {Spinner} from "react-bootstrap";
import DMChatMainDialogMessage from "./DMChatMainDialogMessage";
import DMChatMainDialogFileModal from "./DMChatMainDialogFileModal";

export const MESSAGE_RESTRICTIONS = {
    LENGTH: 1000
}

const DMChatMainDialog = (props) => {
    const [message, setMessage] = useState("");
    const [error, setError] = useState("")
    const [scrollReference, setScrollReference] = useState(null);
    const [secondaryScrollReference, setSecondaryScrollReference] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [files, setFiles] = useState([]);
    const {chat, auth, sendMessage, pageNr, incrementPageNumber, totalPages, isLoading, downloadFile} = props;
    const messagesEndRef = useRef(null)
    const messagesScroll = useRef(null);

    useEffect(()  => {
        let messages = messagesScroll.current;
        messages.addEventListener("scroll", scrollSet)
        return () => messages.removeEventListener("scroll", scrollSet)
    })

    const scrollSet = (element) => {
        setSecondaryScrollReference(element.target.scrollTop)
    }

    const chatScrollToBottom = () => messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })

    useEffect(() => {
        chatScrollToBottom()
        if(!secondaryScrollReference && !scrollReference) {
            return chatScrollToBottom()
        }
        if (secondaryScrollReference && secondaryScrollReference > 50) {
            messagesScroll.current.scrollTo({top: secondaryScrollReference, left: 0, behavior: "smooth"}); // secondary scroll memory used for when you are scrolled up but chat is refreshed
        } else {
            messagesScroll.current.scrollTo({top: scrollReference, left: 0, behavior: "smooth"});
        }
        // eslint-disable-next-line
    }, [chat]);

    const commentEnterSubmit = (e) => {
        if (e.key === "Enter" && e.shiftKey === false && message.trim().length > 0) {
            if(message.length > MESSAGE_RESTRICTIONS.LENGTH) {
                return setMessageLengthError()
            }
            e.preventDefault()
            sendMessage(message, null, DEFAULT_PAGINATION.pageNr, DEFAULT_PAGINATION.size)
            resetMessageAndScrollState()
        }
    }

    const submitForm = (e) => {
        e.preventDefault()
        if(message.length === 0) {
            return setError("Įveskite tekstą prieš siunčiant")
        }
        if(message.length > MESSAGE_RESTRICTIONS.LENGTH) {
            return setMessageLengthError()
        }
        sendMessage(message, null, DEFAULT_PAGINATION.pageNr, DEFAULT_PAGINATION.size)
        resetMessageAndScrollState()
    }

    const resetMessageAndScrollState = () => {
        setMessage("")
        setScrollReference(null)
        setSecondaryScrollReference(null)
    }

    const typeMessage = (e) => {
        setMessage(e.target.value)
        if(message.length > MESSAGE_RESTRICTIONS.LENGTH) {
            return setMessageLengthError()
        }
        setError("")
    }

    const setMessageLengthError = () => {
        return setError(`Negalima viršyti ${MESSAGE_RESTRICTIONS.LENGTH} simbolių`);
    }

    const loadMoreMessages = () => {
        setSecondaryScrollReference(null)
        incrementPageNumber()
        setScrollReference((prevState) => scrollReference ? prevState  : messagesScroll.current.scrollHeight)
    }

    const isLoadMoreMessagesButtonDisabled = () => {
        return pageNr + 1 >= totalPages
    }

    return (
        <div className={"dm-chat-main-dialog__wrapper"}>
            <div className={"dm-chat-main-dialog"} ref={messagesScroll}>
                {isLoading ? <div className={"d-flex justify-content-center w-100"}>
                    <Spinner animation={"border"} variant={"primary"} />
                </div> : <button className={"dm-chat-main-dialog__load-messages"}
                        onClick={loadMoreMessages}
                        disabled={isLoadMoreMessagesButtonDisabled()}
                ><span>Krauti daugiau žinučių</span></button>}
                {chat && chat.length > 0 ? chat.map((chatDTO, idx) =>
                    (<DMChatMainDialogMessage
                        key={idx}
                        auth={auth}
                        chatDTO={chatDTO}
                        idx={idx}
                        downloadFile={downloadFile}
                    />)) : <div>Pradėkite pokalbį su šiuo vartotoju</div>}
                <div ref={messagesEndRef} />
            </div>
            <div className={"px-3 px-lg-5"}>
                <div className={"dm-chat-main__divider"} />
                <div className={"d-flex mt-2"}>
                    <button onClick={() => setShowModal(true)}>
                        <FaPlus />
                    </button>
                    <form onSubmit={submitForm} className={"w-100 d-flex"}>
                        <div className={"d-flex flex-column w-100"}>
                            <textarea
                                    className={"form-control shadow-none border-0 rounded w-100 px-3 bg-transparent min-vh-25"}
                                    placeholder={"Įrašykite žinutę čia"}
                                    onKeyPress={ commentEnterSubmit}
                                    value={message}
                                    onChange={typeMessage}
                            />
                            <span className={"text-danger"}>{error}</span>
                        </div>
                        <button type={"submit"}>
                            <FaLocationArrow />
                        </button>
                    </form>
                </div>
            </div>
            <DMChatMainDialogFileModal
                files={files} setFiles={setFiles}
                setShowModal={setShowModal} showModal={showModal}
                sendMessage={sendMessage} pageNr={pageNr}
            />
        </div>
    );
};

DMChatMainDialog.propTypes = {
    chat: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string,
        senderUserId: PropTypes.number.isRequired,
        receiverUserId: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        file: PropTypes.shape({
            createdAt: PropTypes.string.isRequired,
            createdBy: PropTypes.object.isRequired,
            fileName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired
        })
    })),
    sendMessage: PropTypes.func.isRequired,
    pageNr: PropTypes.number.isRequired,
    totalPages: PropTypes.number,
    incrementPageNumber: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    downloadFile: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        auth: getAuthState(state),
    };
}

export default connect(mapStateToProps)(DMChatMainDialog);
