import * as React from "react";
import * as PropTypes from "prop-types";
import {Badge} from "react-bootstrap";

class JobUserStatusBadge extends React.Component {

    render() {
        const { status } = this.props;
        let variant, label;
        switch (status) {
            case 'NOT_APPROVED':
                variant = 'secondary';
                label = 'Nepatvirtinta'
                break;
            case 'REKYL':
                variant = 'warning';
                label = 'Rekyl'
                break;
            case 'APPROVED':
                variant = 'success';
                label = 'Patvirtinta'
                break;
            case 'REJECTED':
                variant = 'danger';
                label = 'Atmesta'
                break;
            default:
                variant = 'secondary';
                label = 'Nepatvirtinta'
                break;
        }
        return (
            <Badge pill variant={variant}>{label}</Badge>
        )
    }
}

export default JobUserStatusBadge;

JobUserStatusBadge.propTypes = {
    status: PropTypes.oneOf(["NOT_APPROVED", "REKYL", "APPROVED", "REJECTED"]).isRequired,
}
