import React from 'react';
import * as PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaCalendar, FaFacebookMessenger, FaHammer, FaHistory, FaUsers, FaEnvelopeOpenText } from 'react-icons/fa';
import { logoutAction } from '../../../redux/auth/authActions';
import {
    getUserRole,
    isAdminRole,
    isTeamLeaderRole,
    isUserLoggedIn,
    isUserRole
} from '../../auth/AuthenticationService';
import { AiOutlineTable } from "react-icons/ai";
import { IoIosContact, IoIosLogOut, IoIosSettings } from "react-icons/io";
import { getAuthState } from "../../../redux/auth/authSelectors";
import { fetchUnreadNotificationCount } from "../../../redux/notification/notificationActions";
import { fetchDMNotifications } from "../../../redux/dm/dmActions"
import { getUnreadNotificationCount } from "../../../redux/notification/notificationSelectors";
import { addSocketTopic, discardSocketTopic } from "../../../redux/socket/socketActions";
import { UnreadWrapper } from "./Navigation.style";
import {getUnreadDMNotificationCount} from "../../../redux/dm/dmSelector";

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
        };

        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        const { addSocketTopic, fetchUnreadNotificationCount, auth, fetchDMNotifications } = this.props;
        const { user: { email } } = auth;
        addSocketTopic(`/users/${email}/notifications/status`);
        fetchUnreadNotificationCount();
        fetchDMNotifications()
    }

    handleLogout() {
        const { logoutAction } = this.props;
        logoutAction();
    }

    render() {
        const { auth, unreadNotificationCount, unreadDMNotificationCount } = this.props;
        if (!auth) {
            return <Redirect to="/logout"/>;
        }
        const { name } = auth.user;
        const role = getUserRole(auth);

        return (
            <>
                {isUserLoggedIn() && (
                    <Navbar bg="primary" expand="lg">
                        <Navbar.Brand href="#">
                            <img
                                src="/assets/img/logo.png"
                                height="25"
                                className="d-inline-block align-top"
                                alt="logo"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            {isAdminRole(role) && (
                                <>
                                    <Nav className="mr-auto">
                                        <NavLink
                                            to="/administration/users"><Button><FaUsers/> Vartotojai</Button></NavLink>
                                        <NavLink
                                            to="/administration/reports"><Button><AiOutlineTable/> Ataskaitos</Button></NavLink>
                                        <NavLink to="/jobs"><Button><FaHammer/>Darbai</Button></NavLink>
                                        <NavLink to="/administration/history"><Button><FaHistory/> Veiksmų
                                            istorija</Button></NavLink>
                                        <NavLink
                                            to="/messaging"><Button><FaFacebookMessenger/> Susirašinėjimai <UnreadWrapper>{unreadDMNotificationCount > 0 && ` ${unreadDMNotificationCount}!`}</UnreadWrapper>
                                            </Button>
                                        </NavLink>
                                        <NavLink to="/calendar"><Button><FaCalendar/> Kalendorius</Button></NavLink>
                                        <NavLink to="/notifications"><Button><FaEnvelopeOpenText/> Žinutės<UnreadWrapper>{unreadNotificationCount > 0 && ` ${unreadNotificationCount}!`}</UnreadWrapper></Button></NavLink>
                                        {/*<NavLink to="/administration/company"><Button><FaBuilding /> Mūsų įmonės</Button></NavLink>*/}
                                        {/*<NavLink to="/administration/customer"><Button><TiContacts /> Klientai</Button></NavLink>*/}
                                    </Nav>
                                    <NavLink to="/profile"><Button><IoIosContact/>{name}</Button></NavLink>
                                    <NavLink to="/administration/settings"><Button><IoIosSettings/> Nustatymai</Button></NavLink>
                                </>
                            )}
                            {isTeamLeaderRole(role) && (
                                <>
                                    <Nav className="mr-auto">
                                        <NavLink to="/users"><Button><FaUsers/> Vartotojai</Button></NavLink>
                                        <NavLink to="/jobs"><Button><FaHammer/> Darbai</Button></NavLink>
                                        <NavLink
                                            to="/messaging"><Button><FaFacebookMessenger/> Susirašinėjimai <UnreadWrapper>{unreadDMNotificationCount > 0 && ` ${unreadDMNotificationCount}!`}</UnreadWrapper>
                                            </Button>
                                        </NavLink>
                                        <NavLink
                                            to="/notifications"><Button><FaFacebookMessenger/> Pranešimai
                                            <UnreadWrapper>{unreadNotificationCount > 0 && ` ${unreadNotificationCount}!`}</UnreadWrapper>
                                        </Button>
                                        </NavLink>
                                        <NavLink to="/calendar"><Button><FaCalendar/> Kalendorius</Button></NavLink>
                                    </Nav>
                                    <NavLink to="/profile"><Button><IoIosContact/>{name}</Button></NavLink>
                                    <NavLink to="/administration/settings"><Button><IoIosSettings/> Nustatymai</Button></NavLink>
                                </>
                            )}
                            {isUserRole(role) && (
                                <>
                                    <Nav className="mr-auto">
                                        <NavLink to="/jobs"><Button><FaHammer/> Darbai</Button></NavLink>
                                        <NavLink
                                            to="/notifications"><Button><FaFacebookMessenger/> Pranešimai
                                            <UnreadWrapper>{unreadNotificationCount > 0 && ` ${unreadNotificationCount}!`}</UnreadWrapper>
                                            </Button>
                                        </NavLink>
                                        <NavLink
                                            to="/messaging"><Button><FaFacebookMessenger/> Susirašinėjimai <UnreadWrapper>{unreadDMNotificationCount > 0 && ` ${unreadDMNotificationCount}!`}</UnreadWrapper>
                                        </Button>
                                        </NavLink>
                                        <NavLink to="/calendar"><Button><FaCalendar/> Kalendorius</Button></NavLink>
                                    </Nav>
                                    <NavLink to="/profile"><Button><IoIosContact/> {name}</Button></NavLink>
                                </>
                            )}
                            <NavLink to="/login"><Button onClick={this.handleLogout}><IoIosLogOut/> Atsijungti</Button></NavLink>
                        </Navbar.Collapse>
                    </Navbar>
                )}
            </>
        );
    }
}

Navigation.propTypes = {
    auth: PropTypes.shape({
        isAuthenticated: PropTypes.bool.isRequired,
        user: PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            tokenExpiration: PropTypes.object,
            role: PropTypes.string,
        }).isRequired,
    }).isRequired,
    logoutAction: PropTypes.func.isRequired,
    addSocketTopic: PropTypes.func.isRequired,
    discardSocketTopic: PropTypes.func.isRequired,
    fetchUnreadNotificationCount: PropTypes.func.isRequired,
    unreadNotificationCount: PropTypes.number.isRequired,
    fetchDMNotifications: PropTypes.func.isRequired,
    unreadDMNotificationCount: PropTypes.number.isRequired
};

function mapStateToProps(state) {
    return {
        auth: getAuthState(state),
        unreadNotificationCount: getUnreadNotificationCount(state),
        unreadDMNotificationCount: getUnreadDMNotificationCount(state)
    };
}

export default connect(mapStateToProps, {
    logoutAction,
    fetchUnreadNotificationCount,
    addSocketTopic,
    discardSocketTopic,
    fetchDMNotifications
})(Navigation);
