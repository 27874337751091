import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';


const SimpleFieldModal = ({
    show, onClose, onSubmit, modalTitle, fieldsLabels
  }) => {

  const [fieldsValues, setFieldsValues] = useState();

  const handleChange = (event) => {
    const { target } = event;
    const index = target.name.replace("value", "");
    let tempArray = fieldsValues == null ? [] : fieldsValues;
    tempArray[index] = target.value;
    setFieldsValues(tempArray);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(fieldsValues);
  }

  return (
    <Modal show={show} onHide={() => onClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <div className="d-flex flex-row justify-content-between">
            { fieldsLabels.map((label, index) =>
              <Form.Group className="w-30 m-1" key={index}>
                <Form.Label>{label}</Form.Label>
                <Form.Control type="string" name={"value"+index} onChange={handleChange} required />
              </Form.Group>)
            }
          </div>
          <div className="d-flex justify-content-end m-1">
            <Button type="submit" variant="success">Saugoti</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

SimpleFieldModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  fieldsLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SimpleFieldModal;
