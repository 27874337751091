import axios from "axios";
import FileSaver from 'file-saver';
import {
    getIsShowAllNotifications,
    getNotificationFormValues,
    getNotificationTemporaryFiles
} from "./notificationSelectors";
import { handleError } from "../../utilities/ErrorHandler";

export const CLEAR_NOTIFICATIONS_STATE = 'CLEAR_NOTIFICATIONS_STATE';

export const FETCH_NOTIFICATIONS_BEGIN = 'FETCH_NOTIFICATIONS_BEGIN';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const FETCH_NOTIFICATION_USERS_BEGIN = 'FETCH_NOTIFICATION_USERS_BEGIN';
export const FETCH_NOTIFICATION_USERS_SUCCESS = 'FETCH_NOTIFICATION_USERS_SUCCESS';
export const FETCH_NOTIFICATION_USERS_FAILURE = 'FETCH_NOTIFICATION_USERS_FAILURE';

export const CHANGE_NOTIFICATION_STATUS_BEGIN = 'CHANGE_NOTIFICATION_STATUS_BEGIN';
export const CHANGE_NOTIFICATION_STATUS_SUCCESS = 'CHANGE_NOTIFICATION_STATUS_SUCCESS';
export const CHANGE_NOTIFICATION_STATUS_FAILURE = 'CHANGE_NOTIFICATION_STATUS_FAILURE';

export const SEND_NOTIFICATION_BEGIN = 'SEND_NOTIFICATION_BEGIN';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';

export const TOGGLE_SHOW_ALL_NOTIFICATIONS = 'TOGGLE_SHOW_ALL_NOTIFICATIONS';

export const UPDATE_UNREAD_NOTIFICATION_COUNT = 'UPDATE_UNREAD_NOTIFICATION_COUNT';

export const UPDATE_NOTIFICATION_FORM_MESSAGE = 'UPDATE_NOTIFICATION_FORM_MESSAGE';
export const UPDATE_NOTIFICATION_FORM_RECEIVERS = 'UPDATE_NOTIFICATION_FORM_RECEIVERS';
export const UPDATE_NOTIFICATION_FORM_RECEIVER_GROUPS = 'UPDATE_NOTIFICATION_FORM_RECEIVER_GROUPS';
export const UPDATE_NOTIFICATION_FORM_SUBMIT_DISABLED = 'UPDATE_NOTIFICATION_FORM_SUBMIT_DISABLED';

export const NOTIFICATION_FORM_STORE_TEMPORARY_FILE = 'NOTIFICATION_FORM_STORE_TEMPORARY_FILE';
export const NOTIFICATION_FORM_DELETE_TEMPORARY_FILE = 'NOTIFICATION_FORM_DELETE_TEMPORARY_FILE';
export const NOTIFICATION_FILE_DOWNLOAD_FAILURE = 'NOTIFICATION_FILE_DOWNLOAD_FAILURE';

export const updateUnreadNotificationCount = (value) => ({
    type: UPDATE_UNREAD_NOTIFICATION_COUNT,
    payload: {
        value
    }
});

export const clearNotificationsState = () => ({
    type: CLEAR_NOTIFICATIONS_STATE
});

export const fetchNotificationsBegin = () => ({
    type: FETCH_NOTIFICATIONS_BEGIN
});

export const fetchNotificationsSuccess = (value) => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: { value }
});

export const fetchNotificationsFailure = (value) => ({
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: { value }
});

export const fetchNotificationUsersBegin = () => ({
    type: FETCH_NOTIFICATION_USERS_BEGIN
});

export const fetchNotificationUsersSuccess = ({ users }) => ({
    type: FETCH_NOTIFICATION_USERS_SUCCESS,
    payload: { value: users }
});

export const fetchNotificationUsersFailure = (value) => ({
    type: FETCH_NOTIFICATION_USERS_FAILURE,
    payload: { value }
});

export const toggleShowAllNotifications = () => ({
    type: TOGGLE_SHOW_ALL_NOTIFICATIONS
});

export const changeNotificationStatusBegin = () => ({
    type: CHANGE_NOTIFICATION_STATUS_BEGIN
});

export const changeNotificationStatusSuccess = () => ({
    type: CHANGE_NOTIFICATION_STATUS_SUCCESS
});

export const changeNotificationStatusFailure = (value) => ({
    type: CHANGE_NOTIFICATION_STATUS_FAILURE,
    payload: { value }
});

export const sendNotificationBegin = () => ({
    type: SEND_NOTIFICATION_BEGIN
});

export const sendNotificationSuccess = () => ({
    type: SEND_NOTIFICATION_SUCCESS
});

export const sendNotificationFailure = (value) => ({
    type: SEND_NOTIFICATION_FAILURE,
    payload: { value }
});

export const updateNotificationFormMessage = (value) => ({
    type: UPDATE_NOTIFICATION_FORM_MESSAGE,
    payload: { value }
});

export const updateNotificationFormReceivers = (value) => ({
    type: UPDATE_NOTIFICATION_FORM_RECEIVERS,
    payload: { value }
});

export const updateNotificationFormReceiverGroups = (value) => ({
    type: UPDATE_NOTIFICATION_FORM_RECEIVER_GROUPS,
    payload: { value }
});

export const updateNotificationFormSubmitDisabled = (value) => ({
    type: UPDATE_NOTIFICATION_FORM_SUBMIT_DISABLED,
    payload: { value }
});

export const notificationFormStoreTemporaryFile = (value) => ({
    type: NOTIFICATION_FORM_STORE_TEMPORARY_FILE,
    payload: { value }
});

export const notificationFormDeleteTemporaryFile = (value) => ({
    type: NOTIFICATION_FORM_DELETE_TEMPORARY_FILE,
    payload: { value }
});

export const notificationFileDownloadFailure = (value) => ({
    type: NOTIFICATION_FILE_DOWNLOAD_FAILURE,
    payload: { value }
});

export const fetchNotifications = () =>
    (dispatch, getState) => {
        const state = getState();
        const isShowAll = getIsShowAllNotifications(state);

        dispatch(fetchNotificationsBegin());
        return axios.get(`${process.env.REACT_APP_API_URL}/notifications`, { params: { isShowAll } })
            .then(({ data }) => dispatch(fetchNotificationsSuccess(data)))
            .then(() => dispatch(fetchUnreadNotificationCount()))
            .catch(err => dispatch(fetchNotificationsFailure(handleError(err))));
    };

export const fetchNotificationUsers = () =>
    (dispatch) => {
        dispatch(fetchNotificationUsersBegin());
        return axios.get(`${process.env.REACT_APP_API_URL}/user/all`)
            .then(({ data }) => dispatch(fetchNotificationUsersSuccess(data)))
            .catch(err => dispatch(fetchNotificationUsersFailure(handleError(err))));
    }

export const changeNotificationStatus = (notificationId) =>
    (dispatch) => {
        dispatch(changeNotificationStatusBegin());
        return axios.put(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}/mark-as-read`)
            .then(() => dispatch(changeNotificationStatusSuccess()))
            .then(() => dispatch(fetchNotifications()))
            .catch(err => dispatch(changeNotificationStatusFailure(handleError(err))));
    }

export const sendNotification = () => {
    return (dispatch, getState) => {
        const form = getNotificationFormValues(getState());
        dispatch(sendNotificationBegin());
        return axios.post(`${process.env.REACT_APP_API_URL}/notifications`, form)
            .then(({ data }) => dispatch(uploadNotificationFiles(data)))
            .then(() => dispatch(sendNotificationSuccess()))
            .then(() => dispatch(fetchNotifications()))
            .catch(err => dispatch(sendNotificationFailure(handleError(err))));
    }
};

export const uploadNotificationFiles = (notificationId) => {
    return (dispatch, getState) => {
        const files = getNotificationTemporaryFiles(getState());
        return Promise.all(files.map(file => uploadNotificationFile(notificationId, file)));
    }
};

const uploadNotificationFile = (notificationId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}/files`, formData);
}

export const downloadNotificationFile = ({ notificationId, id, fileName }) =>
    (dispatch) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}/files/${id}`, { responseType: 'blob' })
            .then(({ data }) => FileSaver.saveAs(data, fileName))
            .catch((err) => dispatch(notificationFileDownloadFailure(handleError(err))));
    };

export const fetchUnreadNotificationCount = () =>
    (dispatch) => {
        return axios.get(`${process.env.REACT_APP_API_URL}/notifications/unread`)
            .then(({ data }) => dispatch(updateUnreadNotificationCount(data)))
            .catch(() => dispatch(updateUnreadNotificationCount(0)));
    };