import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isUserLoggedIn } from './AuthenticationService';

class AuthenticatedRoute extends Component {
  render() {
    if (isUserLoggedIn()) {
      return <Route {...this.props} />;
    }
    return <Redirect to="/logout" />;
  }
}

export default AuthenticatedRoute;
