import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    background-color: white;
  }
`;

export const ReturnWrapper = styled.section`
  display: inline-block;
  white-space: nowrap;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 27px;
`;

export const Border = styled.div`
  border-bottom: 1px solid #d0dae5;
  margin: 0 10px;
  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    display: none;
  }
`;

export const Information = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Name = styled.div`
  font-size: 18px;
  color: #585858;
  font-weight: 500;
  line-height: 20px;
  overflow-wrap: anywhere;
  margin-left: 10px;
  text-transform: capitalize;
`;

export const Description = styled.div`
  font-size: 12px;
  color: #9b9b9b;
  font-weight: 400;
  margin-left: 10px;
  overflow-wrap: anywhere;
  line-height: 20px;
  text-transform: capitalize;
  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    line-height: 15px;
  }
`;
