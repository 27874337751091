import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  :hover {
    background-color: #d0dae5;
  }
`;

export const About = styled.div`
  padding-left: 20px;
  flex-grow: 1;
`;

export const UserName = styled.div`
  text-transform: capitalize;
  font-weight: 500;
  line-height: 20px;
`;

export const Avatar = styled.div`
  width: 36px;
  height: 36px;
`;

export const UserTitle = styled.div`
  font-size: 12px;
  color: #9b9b9b;
  font-weight: 400;
  line-height: 20px;
`;

export const ActiveDot = styled.span`
  font-size: 0px;
  border: 5px solid;
  border-radius: 50%;
  height: 0px;
  width: 0px;
  border-color: ${props => (props.active ? "#B8E986" : "#E9EEF4")};
  margin-left: 10px;
  vertical-align: 6px;
`;

