import { WS_MESSAGE_TYPE } from "./socketModel";
import { conversationMessageReceived } from "../chat/messages/messageActions";
import { conversationMemberStatusUpdated } from "../chat/conversation/conversationActions";
import { fetchUnreadNotificationCount } from "../notification/notificationActions";

export const SOCKET_TOPIC_UPDATED = 'SOCKET_TOPIC_UPDATED';
export const SOCKET_TOPIC_DISCARDED = 'SOCKET_TOPIC_DISCARDED';

export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';

export const SOCKET_MESSAGE_SENT = 'SOCKET_MESSAGE_SENT';

export const addSocketTopic = (topic) => {
    return dispatch => {
        return dispatch({
            type: SOCKET_TOPIC_UPDATED,
            payload: {
                value: topic
            }
        });
    };
};
export const discardSocketTopic = (topic) => {
    return dispatch => {
        return dispatch({
            type: SOCKET_TOPIC_DISCARDED,
            payload: {
                value: topic
            }
        });
    };
};

export const onSocketConnected = () => {
    return dispatch => {
        return dispatch({
            type: SOCKET_CONNECTED
        });
    };
};

export const onSocketDisconnected = () => {
    return dispatch => {
        return dispatch({
            type: SOCKET_DISCONNECTED
        });
    };
}

export const sendSocketMessage = (topic, content) => {
    return dispatch => {
        return dispatch({
            type: SOCKET_MESSAGE_SENT,
            payload: {
                value: {
                    topic,
                    content
                }
            }
        });
    };
}

export const onSocketMessageReceived = (message) => dispatch => dispatch(resolveSocketAction(message))

const resolveSocketAction = message => {
    switch (message.type) {
        case WS_MESSAGE_TYPE.CHAT_MESSAGE:
            return conversationMessageReceived(message);
        case WS_MESSAGE_TYPE.USER_STATUS_UPDATE:
            return conversationMemberStatusUpdated(message);
        case WS_MESSAGE_TYPE.NOTIFICATION_STATUS_UPDATED:
            return fetchUnreadNotificationCount();
        default:
            return null;
    }
}