import React, {useEffect, useState} from "react"
import axios from "axios";
import DebouncedInputText from "./DebouncedInputText";
import DMScrollableUserSearch from "./DMScrollableUserSearch";
import "./DMChat.scss"
import DMChatMain from "./DMChatMain";
import {FaTimes} from "react-icons/all";
import {STANDART_POLLING_TIME, usePolling} from "../../webhooks/usePolling";
import {Helmet} from "react-helmet";
import FileSaver from 'file-saver';
import {handleBlobTypeErrorMessage} from "../../utilities/ErrorHandler";

export const DEFAULT_PAGINATION = {
    size: 20,
    defaultSizeIncrementor: 20,
    pageNr: 0
}

const DMChat = () => {
    const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
    const [recentChats, setRecentChats] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [filteredUsers, setFilteredUsers] = useState(recentChats)
    const [selectedUserChat, setSelectedUserChat] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [pageNr, setPageNr] = useState(DEFAULT_PAGINATION.pageNr)
    const [error, setError] = useState("")

    const MOBILE_WIDTH = 768;
    const isMobile = window.innerWidth < MOBILE_WIDTH;

    useEffect(() => {
        if(query !== "") {
            setFilteredUsers(() => recentChats.filter(user => user.name.toLowerCase().includes(query.toLowerCase())))
        } else {
            setFilteredUsers(recentChats)
        }
        //eslint-disable-next-line
    }, [query, recentChats]);

    useEffect(() => {
        getRecentChatsByUser(currentSelectedUser ? currentSelectedUser.id : undefined, pageNr, DEFAULT_PAGINATION.defaultSizeIncrementor)
        //eslint-disable-next-line
    }, [pageNr])

    usePolling(() => {
            if(currentSelectedUser) {
                updateChatAfterPolling(currentSelectedUser ? currentSelectedUser.id : undefined, DEFAULT_PAGINATION.pageNr, DEFAULT_PAGINATION.defaultSizeIncrementor)
            }
        },
        STANDART_POLLING_TIME.TEN_SECONDS)

    const updateChatAfterPolling = (userId, page, pageSize) => {
        setIsLoading(true)
        const size = (pageNr+1) * pageSize;
        axios.get(`${process.env.REACT_APP_API_URL}/dm${userId ? `/${userId}` : ""}`, {params: {page, size}})
            .then(({ data }) => {
                assignChatStateOnPollingUpdate(data?.userReferences, data?.currentlySelectedUser,data?.directMessagePage.totalPages,
                    data?.directMessagePage.content)
            })
            .catch((error) => setError(error.response?.data?.message
                ? error.response?.data?.message
                : error.message))
            .finally(() => setIsLoading(false))
    }

    const assignChatStateOnUpdate = (userReferences, currentlySelectedUser, directMessagePageTotalPages, directMessagePageContent) => {
        if(currentSelectedUser?.id === currentlySelectedUser?.id && selectedUserChat && selectedUserChat.length > 0) {
            setSelectedUserChat([...directMessagePageContent, ...selectedUserChat])
        } else {
            setSelectedUserChat(directMessagePageContent)
        }
        setRecentChats(userReferences)
        setCurrentSelectedUser(currentlySelectedUser)
        setTotalPages(directMessagePageTotalPages)
    }

    const assignChatStateOnPollingUpdate = (userReferences, currentlySelectedUser, directMessagePageTotalPages, directMessagePageContent) => {
        setSelectedUserChat(directMessagePageContent)
        setRecentChats(userReferences)
        setCurrentSelectedUser(currentlySelectedUser)
        setTotalPages(directMessagePageTotalPages)
    }

    const resetChatState = () => {
        setSelectedUserChat(null)
        setCurrentSelectedUser(null)
        setTotalPages(null)
    }

    const incrementPageNumber = () => {
        if(pageNr + 1 > totalPages) return
        return setPageNr((prevState)=> prevState+1)
    }

    const getRecentChatsByUser = (userId = undefined, page, size) => {
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/dm${userId ? `/${userId}` : ""}`, {params: {page, size}})
            .then(({ data }) => {
                assignChatStateOnUpdate(data?.userReferences, data?.currentlySelectedUser,
                    data?.directMessagePage.totalPages, data?.directMessagePage.content)
            })
            .catch((error) => setError(error.response?.data?.message
                ? error.response?.data?.message
                : error.message))
            .finally(() => setIsLoading(false))
    }

    const postMessage = (resolve, reject, multipartRequest, page, pageSize) => {
        const size = (pageNr+1) * pageSize;
        axios.post(`${process.env.REACT_APP_API_URL}/dm`, multipartRequest, {params: {page, size}})
            .then(({ data }) => {
                assignChatStateOnPollingUpdate(data?.userReferences, data?.currentlySelectedUser,data?.directMessagePage.totalPages,
                    data?.directMessagePage.content)
                resolve()
            })
            .catch((error) => {
                setError(error.response?.data?.message
                    ? error.response?.data?.message
                    : error.message)
                reject()
            })
            .finally(() => setIsLoading(false))
    }

    const formSendMessageDataPromise = async (message, file, page, size) => {
        const multipartRequest = new FormData();
        if(message) multipartRequest.append("message", message)
        if(file) multipartRequest.append("file", file)
        multipartRequest.append("userId", currentSelectedUser.id)

        return new Promise((resolve, reject) => postMessage(resolve, reject, multipartRequest, page, size))
    }

    const downloadFile = (dmId, fileId) => {
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/dm/${dmId}/files/${fileId}`, {responseType: "blob"})
            .then((response) => {
                FileSaver.saveAs(response.data, response.fileName)
            })
            .catch((err) => {
                handleBlobTypeErrorMessage(err).then((data) => data.message ? setError(data.message) : setError(data.error))
            })
            .finally(() => setIsLoading(false))
    }

    const onDeleteClick = (receiverId) => {
        console.log("Delete ", receiverId)
        setIsLoading(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/dm/${receiverId}`)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                setError(error.response?.data?.message
                    ? error.response?.data?.message
                    : error.message)
            })
            .finally(() => setIsLoading(false))
    }

    return <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover" />
            </Helmet>
            <div className={"dm-chat"}>
                <div className={"p-0 h-100 mb-2"}>
                    {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{error}</strong>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <FaTimes onClick={() => setError("")} />
                        </button>
                    </div>}
                    {isMobile ?
                        <div className={"dm-chat__wrapper"}>
                            {currentSelectedUser ?
                            <div className={"dm-chat__main"}>
                                <DMChatMain currentSelectedUser={currentSelectedUser}
                                            selectedUserChat={selectedUserChat}
                                            sendMessage={formSendMessageDataPromise}
                                            pageNr={pageNr}
                                            totalPages={totalPages}
                                            incrementPageNumber={incrementPageNumber}
                                            isLoading={isLoading}
                                            downloadFile={downloadFile}
                                            resetChatState={resetChatState}
                                /></div>:
                            <div className={"dm-chat__scrollable"}>
                                <DebouncedInputText onChange={setQuery}
                                                    type={"text"}
                                                    defaultValue={query}
                                                    placeholder={"Įveskite vardą"}
                                />
                                <DMScrollableUserSearch filteredUsers={filteredUsers}
                                                        setCurrentSelectedUser={setCurrentSelectedUser}
                                                        currentSelectedUser={currentSelectedUser}
                                                        getRecentChatsByUser={getRecentChatsByUser}
                                                        setSelectedUserChat={setSelectedUserChat}
                                                        setPageNr={setPageNr}
                                                        onDeleteClick={onDeleteClick}

                                />
                            </div>}
                        </div>
                        :
                        <div className={"dm-chat__wrapper"}>
                            <div className={"dm-chat__scrollable"}>
                                <DebouncedInputText onChange={setQuery}
                                                    type={"text"}
                                                    defaultValue={query}
                                                    placeholder={"Įveskite vardą"}
                                />
                                <DMScrollableUserSearch filteredUsers={filteredUsers}
                                                        setCurrentSelectedUser={setCurrentSelectedUser}
                                                        currentSelectedUser={currentSelectedUser}
                                                        getRecentChatsByUser={getRecentChatsByUser}
                                                        setSelectedUserChat={setSelectedUserChat}
                                                        setPageNr={setPageNr}
                                                        onDeleteClick={onDeleteClick}

                                />
                            </div>
                            <div className={"dm-chat__main"}>
                                <DMChatMain currentSelectedUser={currentSelectedUser}
                                            selectedUserChat={selectedUserChat}
                                            sendMessage={formSendMessageDataPromise}
                                            pageNr={pageNr}
                                            totalPages={totalPages}
                                            incrementPageNumber={incrementPageNumber}
                                            isLoading={isLoading}
                                            downloadFile={downloadFile}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            </>
}

export default DMChat;
