import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Typeahead } from "react-bootstrap-typeahead";
import { get } from "lodash";
import JobFormByType from './JobFormByType';
import { JOB_TYPE, NEW_USER } from './constants';
import UploadFile from "../../shared/file/UploadFile";
import UploadedFile from "../../shared/file/UploadedFile";
import TemporaryFile from "../../shared/file/TemporaryFile";
import SearchableUserSelector from "../../shared/select/user/SearchableUserSelector";
import JobFormTotals from "./totals/JobFormTotals";
import { getUserId, getUserRole, isAdminRole, isPrivilegedRole, isUserRole } from "../../auth/AuthenticationService";

export default ({
                    onSubmit,
                    onChange,
                    form,
                    auth,
                    classifiers,
                    isSubmitting,
                    isFormReadOnly,
                    isEditMode,
                    onDownloadFile,
                    onDeleteFile,
                    isFileDeleteAllowed,
                    onAddArrayItem,
                    onDeleteArrayItem,
                    onUserRemove,
                    onDescriptionsChange
                }) => {
    const handleInputChange = (event) => onChange(event.target.name)(event, event.target.value);
    const role = getUserRole(auth);
    const isResponsiblePerson = getUserId(auth) === get(form, 'responsiblePerson[0].userId', null);

    return (
        <fieldset disabled={isFormReadOnly}>
            <Form onSubmit={onSubmit} readOnly disabled={true}>
                <Form.Row className="align-items-center mt-4 mb-4">
                    <Col sm={5} className="mt-2 mb-2">
                        <Form.Label>Pavadinimas*</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="name"
                            onChange={handleInputChange}
                            value={form.name}
                        />
                    </Col>
                    <Col sm={2} className="mt-2 mb-2">
                        <Form.Label>Projekto Nr.</Form.Label>
                        <Form.Control
                            type="text"
                            name="projectId"
                            onChange={handleInputChange}
                            value={form.projectId}
                            disabled={!isAdminRole(role)}
                        />
                    </Col>
                    <Col sm={2} className="mt-2 mb-2">
                        <Form.Label>Data*</Form.Label>
                        <Form.Control
                            type="date"
                            name="date"
                            format="YYYY-MM-DD"
                            mode="date"
                            required
                            onChange={handleInputChange}
                            value={form.date}
                        />
                    </Col>
                    <Col sm={3} className="mt-2 mb-2">
                        <Form.Label>Darbo aprašymas</Form.Label>
                        <Typeahead
                            labelKey="name"
                            id="job-description"
                            name="descriptions"
                            multiple
                            disabled={isFormReadOnly}
                            placeholder="Darbo aprašymai"
                            options={classifiers.descriptions}
                            selected={form.descriptions}
                            onChange={onDescriptionsChange}>
                        </Typeahead>
                    </Col>
                </Form.Row>
                <Form.Row className="align-items-center mt-4 mb-4">
                    <Col sm={2} className="">
                        <Form.Label>Metrai</Form.Label>
                        <Form.Control
                            type="numbers"
                            name="meters"
                            onChange={handleInputChange}
                            value={form.meters}
                        />
                    </Col>
                    <Col sm={6} className="mt-2 mb-2">
                        <Form.Label>Aprašymas*</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="2"
                            name="description"
                            required
                            onChange={handleInputChange}
                            value={form.description}
                        />
                    </Col>
                    <Col sm={4} className="mt-2 mb-2">
                        <Form.Label>Pastaba</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="2"
                            maxLength={255}
                            disabled={!isUserRole(role)}
                            name="note"
                            onChange={handleInputChange}
                            value={form.note}
                        />
                    </Col>
                    <Col sm={6} className="mt-2 mb-2">
                        <Form.Label>Atsakingo asmens pastaba</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="2"
                            maxLength={255}
                            name="responsiblePersonNote"
                            disabled={isUserRole(role) || (isPrivilegedRole(role) && !isResponsiblePerson)}
                            onChange={handleInputChange}
                            value={form.responsiblePersonNote}
                        />
                    </Col>
                    <Col sm={6} className="mt-2 mb-2">
                        <Form.Label>Vadovo pastaba</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="2"
                            maxLength={255}
                            disabled={!isAdminRole(role)}
                            name="adminNote"
                            onChange={handleInputChange}
                            value={form.adminNote}
                        />
                    </Col>
                </Form.Row>
                <Form.Row className="align-items-center mt-4 mb-4">
                    <Col md={4} sm={6} className="mt-2 mb-2">
                        <Form.Label>Atsakingas asmuo*</Form.Label>
                        <SearchableUserSelector
                            id="responsiblePerson"
                            labelKey="name"
                            onChange={value => onChange('responsiblePerson')(undefined, value)}
                            options={classifiers.responsiblePersons}
                            selected={form.responsiblePerson}
                            inputProps={{ required: true }}
                            name="responsiblePerson"
                        />
                    </Col>
                    <Col md={4} sm={6} className="mt-2 mb-2">
                        <Form.Label>Darbo tipas</Form.Label>
                        <Form.Control
                            as="select"
                            name="jobType"
                            onChange={handleInputChange}
                            value={form.jobType}
                            disabled={isEditMode}
                        >
                            {Object.values(JOB_TYPE).map((type) =>
                                <option
                                    key={type.value}
                                    value={type.value}>
                                    {type.name}
                                </option>
                            )}
                        </Form.Control>
                    </Col>
                    <Col md={4} sm={6} className="mt-2 mb-2">
                        <Form.Label>Failai</Form.Label>
                        <UploadFile onUploadFile={onAddArrayItem('tempFiles')}/>
                    </Col>
                </Form.Row>
                <JobFormByType
                    jobType={form.jobType}
                    form={form}
                    onChange={onChange}
                    selectableUsers={classifiers.users}
                    selectableUnitMeasures={classifiers.unitMes}
                    onAddArrayItem={onAddArrayItem}
                    onUserRemove={onUserRemove}
                />
                <Button
                    variant="success"
                    disabled={isFormReadOnly}
                    className="mt-2 mb-2"
                    onClick={() => onAddArrayItem('users')(Object.create(NEW_USER))}>
                    Pridėti darbuotoją
                </Button>
                <Form.Row className="align-items-center mt-4 mb-4">
                    {JOB_TYPE.STANDARD.value === form.jobType &&
                    <Col sm={8} className="mt-2 mb-2">
                        <JobFormTotals totals={form.totalJobHours}/>
                    </Col>
                    }
                    <Col sm={4}>
                        {form.files.length > 0 && form.files.map(file => (
                            <UploadedFile
                                key={file.id}
                                file={file}
                                onDelete={onDeleteFile}
                                onDownload={onDownloadFile}
                                isDeletable={isFileDeleteAllowed}/>
                        ))}
                        {form.tempFiles.length > 0 && form.tempFiles.map((file, index) => (
                            <TemporaryFile
                                file={file}
                                id={index}
                                key={index}
                                onDelete={onDeleteArrayItem('tempFiles')}/>
                        ))}
                    </Col>
                </Form.Row>
                <div className="m-4 d-flex justify-content-end align-items-center">
                    <Button className="col-lg-2" variant="success" type="submit"
                            disabled={isSubmitting}>Išsaugoti</Button>
                </div>
            </Form>
        </fieldset>
    );
};
