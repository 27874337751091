import * as React from 'react';
import { Alert, Container } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import axios from 'axios';
import UserSchema from '../../../schemas/UserSchema';
import FormSubmitBtn from '../../shared/util/FormSubmitBtn';
import {customSelectComponent, datePickerWidget} from '../../../schemas/Widgets';
import { formatStandartDate } from '../../../utilities/DateHelper';
import { handleError } from '../../../utilities/ErrorHandler';
import SearchableUserSelector from "../../shared/select/user/SearchableUserSelector";

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: null,
      error: null,
      backendData: null,
      duties: null,
      employmentDate: null,
      firedDate: null,
      rentStartDate: null,
      subordinates: [],
      users: []
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFiredDateChange = this.handleFiredDateChange.bind(this);
    this.handleEmploymentDateChange = this.handleEmploymentDateChange.bind(this);
    this.handleRentStartDateChange = this.handleRentStartDateChange.bind(this);
    this.handleIsManager = this.handleIsManager.bind(this);
    this.handleOnSubordinatesChange = this.handleOnSubordinatesChange.bind(this);
    this.handleJobUsersFilterChange = this.handleJobUsersFilterChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
  }

  onError(error) {
    this.setState({
      error,
    });
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/user/form`)
      .then((response) => {
        this.setState({
          error: null,
          backendData: UserSchema.formatBackendData(response.data.user),
          users: response.data.users,
        });
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  onSubmit() {
    const { formValues } = this.state;
    Object.assign(formValues, { ...formValues, userDuty: { id: formValues.duties } });
    axios.post(`${process.env.REACT_APP_API_URL}/user`, {...formValues, subordinates: this.state.subordinates})
      .then(() => {
        this.setState({
          error: null,
        });
        const { history } = this.props;
        history.push('/administration/users');
      })
      .catch((err) => {
        console.log("error: ", err)
        handleError(err, this.onError);
      });
  }

  handleFormChange(e) {
    const { duties, backendData } = this.state;
    if (e.formData.duties && e.formData.duties !== duties) {
      const { salary } = backendData.duties.all.find((x) => x.id === e.formData.duties);
      this.setState({
        formValues: { ...e.formData, salary },
        duties: e.formData.duties,
      });
      return;
    }
    this.setState({
      formValues: e.formData,
    });
  }

  handleEmploymentDateChange(value) {
    const { formValues } = this.state;
    const employmentDate = formatStandartDate(value);
    this.setState({
      formValues: { ...formValues, employmentDate },
      employmentDate,
    });
  }

  handleFiredDateChange(value) {
    const { formValues } = this.state;
    const firedDate = formatStandartDate(value);
    this.setState({
      formValues: { ...formValues, firedDate },
      firedDate,
    });
  }

  handleRentStartDateChange(value) {
    const { formValues } = this.state;
    const rentStartDate = formatStandartDate(value);
    this.setState({
      formValues: { ...formValues, rentStartDate },
      rentStartDate,
    });
  }

  handleIsManager(manager) {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, manager },
      manager,
    });
  }

  handleOnSubordinatesChange(subordinates) {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, subordinates },
      subordinates,
    });
  }

  handleJobUsersFilterChange(users = []) {
    this.setState({
      subordinates: users
    });
  }

  render() {
    const {
      formValues, error, backendData, employmentDate, firedDate, rentStartDate,
    } = this.state;

    const widgets = {
      employmentDatePicker: (props) => datePickerWidget(props, employmentDate, this.handleEmploymentDateChange),
      firedDatePicker: (props) => datePickerWidget(props, firedDate, this.handleFiredDateChange),
      rentStartDatePicker: (props) => datePickerWidget(props, rentStartDate, this.handleRentStartDateChange),
      // CheckboxWidget: (props) => checkboxWidget(props, formValues?.manager, this.handleIsManager),
      CustomSelect: (props) => customSelectComponent(props, formValues.subordinates, this.handleOnSubordinatesChange),
    };

    return (
      <Container>
        <h2 className="text-center">Pridėti vartotoją</h2>
        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}
        { backendData && (
          <Form
            schema={UserSchema.initSchema(backendData)}
            validate={UserSchema.validate}
            showErrorList={false}
            formData={formValues}
            onChange={this.handleFormChange}
            onSubmit={this.onSubmit}
            uiSchema={UserSchema.uiSchema}
            widgets={widgets}
          >
            <div className={"px-3"}>
              <input type="checkbox" onChange={(e)=> this.handleIsManager(e.currentTarget.checked)} defaultChecked={formValues?.manager} id={"isManager"} />
              <label htmlFor={"isManager"} className={"ml-2"}>Ar vartotojas turi pavaldinių?</label>
            </div>
            {formValues?.manager &&
                <div className="p-3">
                  <SearchableUserSelector
                      className="w-100"
                      placeholder="Pasirinkite kuriuos pavaldinius norite priskirti prie vadovo"
                      options={[...this.state.users]}
                      multiple
                      onChange={this.handleJobUsersFilterChange}
                      labelKey="name"
                      id="job-creators"
                  />
                </div>
            }
            <FormSubmitBtn />
          </Form>
        )}
      </Container>
    );
  }
}

export default AddUser;
