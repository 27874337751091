import React from "react";
import { Wrapper } from "./MessageEditor.style";
import TextMessageEditor from "../text/editor/TextMessageEditor";

export default ({
                    message,
                    sendDraft,
                    updateDraft
                }) => (
    <Wrapper>
        <TextMessageEditor
            message={message}
            sendDraft={sendDraft}
            updateDraft={updateDraft}
        />
    </Wrapper>
);

