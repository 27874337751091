import * as React from 'react';
import { FaQuestion } from 'react-icons/all';
import { Badge } from "react-bootstrap";

import TooltipComponent from '../Components/shared//tooltip/TooltipComponent';
import moment from "moment";
import getUserColorByRole from "./getUserColorByRole";

export function headerFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center text-center">
            {filterElement}
            {column.text}
            {sortElement}
            {column.tooltipText && <TooltipComponent component={<Badge pill variant='success'><FaQuestion/></Badge>}
                                                     tooltipText={column.tooltipText}/>}
        </div>
    );
}

export const dateFormatter = column => moment(column).format("YYYY-MM-DD");

export const dateTimeFormatter = column => moment(column).format("YYYY-MM-DD hh:mm:ss");

export const userCellFormatter = (cell) => <span style={{ color: getUserColorByRole(cell.role) }}>{cell.name}</span>

export const zeroValueFormatter = (cell) => <span>{cell > 0 ? cell : ''}</span>;