import * as React from "react";
import PropTypes from 'prop-types';
import {Button, Spinner} from "react-bootstrap";

class FlippingBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: props.clicked,
            isLoading: false,
        }

        this.handleClick = this.handleClick.bind(this);
    }

    async handleClick() {
        const { onClickCallBack, clickValue } = this.props;
        this.setState({
            isLoading: true,
        })
        const clicked = await onClickCallBack(clickValue);
        this.setState({
            clicked,
            isLoading: false,
        });
    }

    render() {
        const { clicked, isLoading } = this.state;
        const { clickedLabel, notClickedLabel, successColor, size } = this.props;

        const label = clicked ? clickedLabel : notClickedLabel;
        const color = clicked ? successColor : 'danger';
        return (
            <Button onClick={this.handleClick} variant={color} size={size}>
                {isLoading && (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
                {label}
            </Button>
        );
    }
}

export default FlippingBtn;

FlippingBtn.propTypes = {
    onClickCallBack: PropTypes.func.isRequired,
    clickedLabel: PropTypes.string.isRequired,
    notClickedLabel: PropTypes.string.isRequired,
    clicked: PropTypes.bool.isRequired,
    clickValue: PropTypes.any.isRequired,
    successColor: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'lg']),
}

FlippingBtn.defaultProps = {
    successColor: 'success',
    size: 'sm',
}