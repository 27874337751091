import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCaretLeft, FaTimes } from "react-icons/all";
import {
    BackIconWrapper,
    CloseIcon,
    getAnimatedWrapperVariants,
    Header,
    ScrollableView,
    Title,
    Wrapper
} from "./ConversationMembers.style";
import {
    getCurrentConversationMembers,
    getMemberListDisplayState
} from "../../../../redux/chat/conversation/conversationSelectors";
import MemberDescription from "./description/MemberDescription";
import { conversationMembersViewHidden } from "../../../../redux/chat/conversation/conversationActions";
import { addSocketTopic, discardSocketTopic } from "../../../../redux/socket/socketActions";
import { isOnline } from "../../../../redux/chat/conversation/conversationModel";

const orderByStatus = (members) => members.sort(memberSorter);

const memberSorter = (userA, userB) => {
    if (userA.status === userB.status) {
        return 0;
    }
    return isOnline(userA) ? -1 : 1;
};

export default () => {
    const members = useSelector(getCurrentConversationMembers);
    const isDisplayed = useSelector(getMemberListDisplayState);
    const dispatch = useDispatch();

    const topic = `/users/status`;

    useEffect(() => {
        dispatch(discardSocketTopic(topic));
        dispatch(addSocketTopic(topic));
    }, [ dispatch, topic ]);

    return (
        <Wrapper
            animate={isDisplayed ? "open" : "closed"}
            variants={getAnimatedWrapperVariants()}
            transition={{ ease: "linear", duration: .15 }}
        >
            <Header>
                <Title>
                    <BackIconWrapper
                        onClick={() => {
                            dispatch(conversationMembersViewHidden());
                        }}
                    >
                        <FaCaretLeft title="back"/>
                    </BackIconWrapper>
                    Vartotojai
                </Title>
                <CloseIcon
                    onClick={() => {
                        dispatch(conversationMembersViewHidden());
                    }}
                >
                    <FaTimes/>
                </CloseIcon>
            </Header>
            <ScrollableView>
                {orderByStatus(members).map(user => (
                    <MemberDescription user={user} key={user.id}/>
                ))}
            </ScrollableView>
        </Wrapper>
    );
};
