import { NAVIGATION_DISPLAYED, NAVIGATION_HIDDEN } from "./navigationActions";

const initialState = {
    isDisplayed: true
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case NAVIGATION_HIDDEN:
            return {
                ...state,
                isDisplayed: false
            };
        case NAVIGATION_DISPLAYED:
            return {
                ...state,
                isDisplayed: true
            }
        default:
            return state;
    }
};
