import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { Wrapper } from "./MessageInput.style";
import { sendMessage } from "../../../../redux/chat/messages/messageActions";
import MessageEditor from "./message/MessageEditor";

const defaultState = {
    text: ''
};

export default () => {
    const [ message, setMessage ] = useState(defaultState);
    const dispatch = useDispatch();
    const sendRef = useRef();

    const send = (appMessage) => {
        sendRef.current.blur();
        if (appMessage.text !== '') {
            dispatch(sendMessage(appMessage));
            setMessage(defaultState);
        }
    };

    return (
        <Wrapper>
            <MessageEditor
                message={message}
                sendDraft={send}
                updateDraft={setMessage}
            />
            <Button
                ref={sendRef}
                type="button"
                className="rounded-circle ml-3"
                variant="success"
                onClick={() => send(message)}>Siųsti</Button>
        </Wrapper>
    );
};
