import jwtDecode from "jwt-decode";
import moment from "moment";

export class User {
    constructor(jwtToken) {
        const decodedToken = jwtToken ? jwtDecode(jwtToken): {};
        this.email =  decodedToken.sub;
        this.role = decodedToken.role;
        this.name = decodedToken.name || '';
        this.surname = decodedToken.surname || '';
        this.userId = decodedToken.userId || '';
        this.tokenExpiration = moment.unix(decodedToken.exp);
    }
}