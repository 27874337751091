import {useMemo} from "react";

export const DOTS = '...';

export const usePagination = ({
      totalPageCount,
      currentPage
  }) => {
    return useMemo(() => {
        if (totalPageCount === 0) {
           return [0]
        }
        if(totalPageCount < 4) {
            return [...Array(totalPageCount + 1).keys()]
        }
        if (currentPage === 0) {
            return [currentPage, currentPage + 1, currentPage + 2, DOTS, totalPageCount]
        }
        if (currentPage === 1) {
            return [0, currentPage, currentPage + 1, DOTS, totalPageCount]
        }
        if (currentPage === 2) {
            return [0, 1, currentPage, currentPage + 1, DOTS, totalPageCount]
        }
        if (currentPage === totalPageCount) {
            return [0, DOTS, currentPage - 2, currentPage - 1, currentPage]
        }
        if (currentPage === totalPageCount - 1) {
            return [0, DOTS, currentPage - 1, currentPage, totalPageCount]
        }
        if (currentPage === totalPageCount - 2) {
            return [0, DOTS, currentPage -1, currentPage, totalPageCount - 1, totalPageCount]
        }
        return [0, DOTS, currentPage - 1, currentPage, currentPage + 1, DOTS, totalPageCount]
    }, [totalPageCount, currentPage]);
};