import * as React from 'react';
import { Container, } from 'react-bootstrap';
import axios from 'axios';
import ReturnHomeHandler from '../ReturnHomeHandler';
import { formatStandartDate } from '../../../utilities/DateHelper';
import MonthPicker from "../../shared/date/MonthPicker";
import JobReportsTable from "./JobReportsTable";
import StatList from "../../shared/containers/StatList";
import ResponseContainer from "../../shared/containers/ResponseContainer";
import { handleError } from '../../../utilities/ErrorHandler';

class JobReportsHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      date: new Date(),
      stats: new Map(),
      success: null,
      reports: null,
      isLoading: true
    };

    this.fetchAllReports = this.fetchAllReports.bind(this);
    this.setReportsDate = this.setReportsDate.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }

  componentDidMount() {
    this.fetchAllReports();
  }

  onError(error) {
    this.setState({
      error,
      success: null,
      isLoading: false,
    });
  }

  fetchAllReports() {
    const { date } = this.state;
    axios.get(`${process.env.REACT_APP_API_URL}/job/report`, {params: { date: formatStandartDate(date) }})
      .then(({ data }) => this.handleReportsResponse(data))
      .catch((err) => handleError(err, this.onError));
  }

  handleStatusChange(jobUserId) {
    return axios.put(`${process.env.REACT_APP_API_URL}/job/user/${jobUserId}/status/toggle`)
        .then(({ data }) => data)
        .catch((err) => handleError(err, this.onError));
  }

  handleReportsResponse(data) {
    const { reports, daysWorked } = data;
    const { stats } = this.state;
    stats.set('Dirbta dienų', daysWorked);
    this.setState({
      reports,
      stats,
      error: null,
      isLoading: false,
    });
  }

  setReportsDate(date) {
    this.setState({
      date,
      isLoading: true,
    }, () => {
      this.fetchAllReports(date);
    });
  }

  render() {
    const { error, success, date, reports, stats, isLoading } = this.state;
    return (
      <Container fluid>
        <div className="m-2">
          <h3>Darbuotojų ataskaitos</h3>
          <div className="d-flex justify-content-between mb-2">
          <div className="mb-2">
              <ReturnHomeHandler/>
            </div>
            <MonthPicker date={date} onChange={this.setReportsDate} />
            <StatList elements={stats} />
          </div>
          <ResponseContainer error={error} success={success} isLoading={isLoading} />
        </div>
        { !isLoading && reports && (
            <JobReportsTable
                reports={reports}
                handleStatusChange={this.handleStatusChange}
                handleEditClick={this.handleEditClick}
            />
        )}
      </Container>
    );
  }
}

export default JobReportsHome;

JobReportsHome.propTypes = {

};
