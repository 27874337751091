import getUserColorByRole from "../../utilities/getUserColorByRole";

export const CALENDAR_MESSAGES = {
    allDay: 'Visą dieną',
    previous: '<',
    next: '>',
    today: 'Šiandien',
    month: 'Mėnuo',
    week: 'Savaitė',
    day: 'Diena',
    work_week: 'Darbo dienos',
    agenda: 'dienotvarkė',
    date: 'Data',
    time: 'Laikas',
    event: 'įvykis'
};

export const CALENDAR_VIEWS = {
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day'
};

export const eventPropGetter = ({ createdBy }) => ({
    style: ({
        backgroundColor: getUserColorByRole(createdBy.role)
    })
});