import * as React from "react";
import { Badge } from "react-bootstrap";
import * as PropTypes from "prop-types";
import { getVacationStatuses, VACATION_STATUS } from "./vacationStatus";

export const VacationStatusBadge = ({ status }) => {
    let variant, label;
    switch (status) {
        case VACATION_STATUS.PENDING:
            variant = 'secondary';
            label = 'Nepatvirtinta'
            break;
        case VACATION_STATUS.APPROVED:
            variant = 'success';
            label = 'Patvirtinta'
            break;
        case VACATION_STATUS.REJECTED:
            variant = 'danger';
            label = 'Atmesta'
            break;
        default:
            variant = 'secondary';
            label = 'Nepatvirtinta'
            break;
    }
    return (
        <Badge pill variant={variant}>{label}</Badge>
    )
}

export default VacationStatusBadge;

VacationStatusBadge.propTypes = {
    status: PropTypes.oneOf(getVacationStatuses()).isRequired,
}
