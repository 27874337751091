import React from 'react';
import PropTypes from 'prop-types';
import DMChatMainHeader from "./DMChatMainHeader";
import "./DMChatMain.scss"
import DMChatMainDialog from "./DMChatMainDialog";

const DMChatMain = ({currentSelectedUser, selectedUserChat, sendMessage, totalPages,
                        pageNr, incrementPageNumber, isLoading, downloadFile,resetChatState}) => {
    return (
        <div className={"dm-chat-main__wrapper"}>
            {currentSelectedUser ? <>
                <DMChatMainHeader currentSelectedUser={currentSelectedUser} resetChatState={resetChatState} />
                <div className={"dm-chat-main__divider"} />
                <DMChatMainDialog chat={selectedUserChat}
                                  sendMessage={sendMessage}
                                  totalPages={totalPages}
                                  pageNr={pageNr}
                                  incrementPageNumber={incrementPageNumber}
                                  isLoading={isLoading}
                                  downloadFile={downloadFile}

                />
            </> : <>
                <div>
                    <h2 className={"dm-chat-main__error-text"}>Pasirinkite vartotoja, kad matyti susirašinėjimus su juo.</h2>
                </div>
            </>}
        </div>
    );
};

DMChatMain.propTypes = {
    currentSelectedUser: PropTypes.shape({
        id: PropTypes.number.isRequired,
        latestMessage: PropTypes.string,
        latestMessageCreatedAt: PropTypes.string,
        phoneNr: PropTypes.string,
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        unreadMessagesCount: PropTypes.number
    }),
    selectedUserChat: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string,
        senderUserId: PropTypes.number.isRequired,
        receiverUserId: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        file: PropTypes.shape({
            createdAt: PropTypes.string.isRequired,
            createdBy: PropTypes.object.isRequired,
            fileName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired
        })
    })),
    sendMessage: PropTypes.func.isRequired,
    pageNr: PropTypes.number.isRequired,
    totalPages: PropTypes.number,
    incrementPageNumber: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    downloadFile: PropTypes.func.isRequired,
    resetChatState: PropTypes.func
};

export default DMChatMain;
