import React from "react";
import FileWrapper from "./FileWrapper";

export default ({ file, onDownload, isDeletable, onDelete }) => (
    <FileWrapper
        key={file.id}
        id={file.id}
        fileName={file.fileName}
        onDownload={onDownload}
        isDeleteAllowed={isDeletable}
        onDelete={onDelete}/>
);