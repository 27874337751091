import React from "react";
import FileWrapper from "./FileWrapper";

export default ({file, id, onDelete}) => {
    return (
        <FileWrapper
            key={id}
            id={id}
            fileName={file.name}
            isDownloadAllowed={false}
            onDelete={onDelete}/>
    );
};