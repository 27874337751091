import useInstallReminder from "./useInstallReminder";
import {isAndroid} from "react-device-detect";

const iosInstallPromptedAt = 'androidOperaInstallPromptedAt';

const useAndroidInstallPrompt = () => {
    const [userShouldBeRemindedToInstall, handleUserInteractedWithPrompt] = useInstallReminder(iosInstallPromptedAt);

    return [isAndroid && userShouldBeRemindedToInstall, handleUserInteractedWithPrompt];
};
export default useAndroidInstallPrompt;