import * as React from 'react';
import {
    Alert,
    Badge, Button,
    ButtonGroup,
    Card, CardColumns, Container,
    Form,
    ListGroup,
    Modal,
    ModalFooter,
    Table,
    ToggleButton
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ResponseContainer from "../../shared/containers/ResponseContainer";
import TextOrNothing from "../../shared/containers/TextOrNothing";
import JobUserStatusBadge from "../shared/JobUserStatusBadge";
import JobUserTlStatusBadge from "../shared/JobUserTlStatusBadge";
import axios from "axios";
import {getRoles, isAdminRole, isTeamLeaderRole} from "../../auth/AuthenticationService";
import { handleError } from '../../../utilities/ErrorHandler';
import { JOB_TYPE } from '../form/constants';
import FileSaver from 'file-saver';
import JobEmployeesRejectModal from './JobEmployeesRejectModal';
import { TriangleAlert } from '../../shared/icons/TriangleAlert';
import { hints } from '../../shared/constants/texts';
import {
    IconWrapper,
} from "../../messages/conversations/occupancy/ConversationOccupancy.style";
import {FaCheck, FaPen} from "react-icons/all";

const initialData = {
    adminNote: '',
    canManagerApprove: false,
    description: '',
    employees: [],
    jobDate: '',
    jobName: '',
    jobType: '',
    note: '',
    projectId: '',
    meters: '',
    responsiblePersonNote: '',
    total: 0,
    totalJobUnitCount: 0,
    updateNotApprovedStatus: false,
    files: []
}

class JobEmployeesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobId: props.jobId,
            data: initialData,
            success: null,
            error: '',
            showRejectModal: false,
            rejectReason: "",
            isEditEnabled: false,
            isSubmitting: false,
        }

        this.fetchJobData = this.fetchJobData.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.handleJobDataResponse = this.handleJobDataResponse.bind(this);
        this.handleStatusResponse = this.handleStatusResponse.bind(this);
        this.onError = this.onError.bind(this);
        this.rejectModalHandler = this.rejectModalHandler.bind(this);
        this.confirmModalHandler = this.confirmModalHandler.bind(this);
        this.handleChangeRejectReason = this.handleChangeRejectReason.bind(this);
        this.toggleIsEdit = this.toggleIsEdit.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { jobId } = nextProps;
        this.setState({
            jobId,
            success: null,
            error: '',
        });
        if (!!jobId) {
            this.fetchJobData(jobId);
        }
    }

    fetchJobData(jobId) {
        axios.get(`${process.env.REACT_APP_API_URL}/job/${jobId}/employees?isForSubordinates=${!!this.props.isForSubordinates ? this.props.isForSubordinates : false}`)
            .then((response) => this.handleJobDataResponse(response.data))
            .catch((err) => handleError(err, this.onError));
    }

    handleJobDataResponse(data) {
        this.setState({
            data,
        });
    }

    handleStatusChange(e) {
        e.preventDefault();
        const { value } = e.target;
        this.changeStatus(value);
    }

    changeStatus(status, extraData) {
        const { jobId } = this.state;
        const request = {...extraData, status };
        axios.put(`${process.env.REACT_APP_API_URL}/job/${jobId}/status`, request)
            .then(() => this.fetchJobData(jobId))
            .then(this.handleStatusResponse)
            .catch((err) => handleError(err, this.onError));
    }

    handleStatusResponse() {
        this.setState({
            error: '',
            success: 'Statusas sėkmingai atnaujintas!',
        });
    }

    onError(error) {
        this.setState({
            success: null,
            isSubmitting: false,
            error,
        })
    }

    rejectModalHandler() {
        this.setState((prevState) => ({
            showRejectModal: !prevState.showRejectModal,
        }));
    }

    confirmModalHandler(e) {
        e.preventDefault();
        const { rejectReason } = this.state;
        this.changeStatus('REJECTED', { rejectReason });
        this.setState((prevState) => ({
            showRejectModal: !prevState.showRejectModal,
        }));
    }

    handleChangeRejectReason(event) {
        this.setState({ rejectReason: event.target.value });
    }

    onFieldChange(field) {
        return (event, value) => {
            const { data } = this.state;
            this.setState({
                data: { ...data, [field]: value }
            });
        }
    }

    handleInputChange(event) {
        this.onFieldChange(event.target.name)(event, event.target.value);
    }

    toggleIsEdit() {
        this.setState({
            isEditEnabled: true
        })
    }

    onSubmit(event) {
        event.preventDefault();
        const { data } = this.state;

        if (!data.jobName) {
            return this.setState({
                error: 'Butina irasyti pavadinima'
            })
        }

        this.setState({
            isSubmitting: true
        });
        const jobReferenceDTO = { name: data.jobName, projectId: data.projectId, meters: data.meters }
        axios.put(`${process.env.REACT_APP_API_URL}/job/${this.state.jobId}/reference`, jobReferenceDTO)
            .then(() => {
                this.setState({
                    isSubmitting: false,
                    isEditEnabled: false,
                    error: '',
                })
            })
            .catch((err) => handleError(err, this.onError));
    }



    render() {
        const { showModal, toggleJobEmployeesModal, role } = this.props;
        const { data, success, error, showRejectModal, isSubmitting } = this.state;
        const canManagerApprove = data && data.canManagerApprove;
        const isAdmin = isAdminRole(role);
        const onDownload = (fileId, fileName) => {
            axios.get(`${process.env.REACT_APP_API_URL}/job/${this.state.jobId}/file/${fileId}`, { responseType: 'blob' })
                .then((response) => FileSaver.saveAs(response.data, fileName))
                .catch((err) => handleError(err, this.onError));
        }
        return (
            <Modal size="xl" show={showModal} onHide={toggleJobEmployeesModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Form onSubmit={this.onSubmit} readOnly>
                            <div className={"d-flex"}>
                                <fieldset disabled={!isAdmin || !this.state.isEditEnabled} className="w-100">
                                    <div className="row">
                                        <div className="col-md-4 col-12 mb-3">
                                            <Form.Label style={{fontSize: '12px'}}>Pavadinimas</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="jobName"
                                                onChange={this.handleInputChange}
                                                value={data?.jobName}
                                            />
                                        </div>
                                        <div className="col-md-4 col-12 mb-3">
                                            <Form.Label style={{fontSize: '12px'}}>Projekto Nr.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="projectId"
                                                onChange={this.handleInputChange}
                                                value={data?.projectId}
                                            />
                                        </div>
                                        <div className="col-md-4 col-12 mb-3">
                                            <Form.Label style={{fontSize: '12px'}}>Metrai</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="meters"
                                                onChange={this.handleInputChange}
                                                value={data?.meters}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <div className={"d-flex justify-content-center align-items-end"}
                                     style={{height: '65px', margin: '10px'}}>
                                    {isAdmin && this.state.isEditEnabled ?
                                        <Button variant="success" type="button" disabled={!isAdmin}
                                                onClick={this.onSubmit}>
                                            <IconWrapper>
                                                <FaCheck/>
                                            </IconWrapper>
                                        </Button>
                                        :
                                        <Button variant="primary" type="button" disabled={!isAdmin}
                                                onClick={this.toggleIsEdit}>
                                            <IconWrapper>
                                                <FaPen/>
                                            </IconWrapper>
                                        </Button>
                                    }
                                </div>
                            </div>
                        </Form>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <Card className="mb-1 w-50">
                            <Card.Header as="h5">Aprašymas</Card.Header>
                            <Card.Text className="p-1">
                                <TextOrNothing object={data} field={'description'}/>
                            </Card.Text>
                        </Card>
                        <Card className="mb-1 ml-1 w-50">
                            <Card.Header as="h5">Darbo tipas</Card.Header>
                            <Card.Text className="p-1">
                                {data && data.jobType && JOB_TYPE[data.jobType].name}
                            </Card.Text>
                        </Card>
                    </div>
                    <div className="d-flex justify-content-between">
                        {data && data.note && <Card className="mb-1 w-50" bg='danger'>
                            <Card.Header as="h6">Pastaba</Card.Header>
                            <Card.Text className="p-1">
                                <TextOrNothing object={data} field={'note'}/>
                            </Card.Text>
                        </Card>
                        }
                        {data && data.responsiblePersonNote && <Card className="mb-1 ml-1 w-50" bg='danger'>
                            <Card.Header as="h6">Atsakingo asmens pastaba</Card.Header>
                            <Card.Text className="p-1">
                                <TextOrNothing object={data} field={'responsiblePersonNote'}/>
                            </Card.Text>
                        </Card>
                        }
                        {data && data.adminNote && <Card className="mb-1 ml-1 w-50" bg='danger'>
                            <Card.Header as="h6">Vadovo pastaba</Card.Header>
                            <Card.Text className="p-1">
                                <TextOrNothing object={data} field={'adminNote'}/>
                            </Card.Text>
                        </Card>
                        }
                    </div>
                    <div className={!data ? 'm-5' : ''}>
                        <ResponseContainer success={success} error={error} isLoading={!data || isSubmitting}/>
                    </div>
                    {data && (<Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Darbuotojas</th>
                            <th>Data</th>
                            <th>Mato vnt.</th>
                            <th>Kiekis</th>
                            <th>Statusas</th>
                            <th>Atsakingo Asmens Statusas</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.employees && data.employees.map((e) => (
                            <tr key={e.id}>
                                <td>{e.name}</td>
                                <td>{data.jobDate}</td>
                                <td>{e.unit}</td>
                                <td>{e.totalCount}</td>
                                <td><JobUserStatusBadge status={e.status}/></td>
                                <td>
                                  {data.updatedNotApprovedStatus && (
                                    <TriangleAlert hint={hints.teamLeadApprovedRejected} />)
                                  }
                                  <JobUserTlStatusBadge status={e.responsiblePersonStatus}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>)}
                    <Container>
                        <CardColumns>
                            {data?.files.map(file => (
                                <Card key={file.id}>
                                    <Card.Img
                                        variant="top"
                                        src={file.signedUrl}
                                        alt={file.fileName}
                                        style={{ objectFit: 'cover', height: '200px' }}
                                        onClick={() => onDownload(file.id, file.fileName)}
                                    />
                                </Card>
                            ))}
                        </CardColumns>
                    </Container>
                    <ModalFooter className="d-flex justify-content-between">
                        {isAdminRole(role) && (
                            <Form.Group className="d-flex flex-column" controlId="formStatusCtrl">
                                {!canManagerApprove
                                && <Alert variant='warning'>Keisti statuso negalima, nes atsakingas asmuo nepatvirtino
                                    darbuotojų valandų.</Alert>}
                                <Form.Label>Keisti visų statusą</Form.Label>
                                <ButtonGroup toggle>
                                    <ToggleButton type="radio" name="status" variant="danger"
                                                  disabled={!canManagerApprove} value="REJECTED"
                                                  onChange={(e) => this.rejectModalHandler(e)}>
                                        Atmesti
                                    </ToggleButton>
                                    <ToggleButton type="radio" name="status" variant="warning"
                                                  disabled={!canManagerApprove} value="REKYL"
                                                  onChange={(e) => this.handleStatusChange(e)}>
                                        Rekyl
                                    </ToggleButton>
                                    <ToggleButton
                                        type="radio"
                                        name="status"
                                        variant="success"
                                        value="APPROVED"
                                        disabled={!canManagerApprove}
                                        onChange={(e) => this.handleStatusChange(e)}>
                                        Patvirtinti
                                    </ToggleButton>
                                </ButtonGroup>
                            </Form.Group>)}
                        {isTeamLeaderRole(role) && (
                            <Form.Group className="d-flex flex-column" controlId="formStatusCtrl">
                                <Form.Label>Keisti visų statusą</Form.Label>
                                <ButtonGroup toggle>
                                    <ToggleButton type="radio" name="status" variant="secondary" value="NOT_APPROVED"
                                                  onChange={(e) => this.handleStatusChange(e)}>
                                        Nepatvirtinta
                                    </ToggleButton>
                                    <ToggleButton type="radio" name="status" variant="success" value="APPROVED"
                                                  onChange={(e) => this.handleStatusChange(e)}>
                                        Patvirtinti
                                    </ToggleButton>
                                    <ToggleButton type="radio" name="status" variant="danger" value="REJECTED"
                                                  onChange={(e) => this.rejectModalHandler(e)}>
                                        Atmesti
                                    </ToggleButton>
                                </ButtonGroup>
                            </Form.Group>)}
                            <JobEmployeesRejectModal
                                    showModal={showRejectModal}
                                    closeModalHandler={this.rejectModalHandler}
                                    confirmModalHandler={this.confirmModalHandler}
                                    handleChangeRejectReason={this.handleChangeRejectReason}
                                />
                        <ListGroup horizontal>
                            <ListGroup.Item>
                                Viso darbuotoju:
                                <Badge pill variant="secondary">
                                    <TextOrNothing object={data} field={'total'}/>
                                </Badge>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Viso kiekis:
                                <Badge pill variant="secondary">
                                    <TextOrNothing object={data} field={'totalJobUnitCount'}/>
                                </Badge>
                            </ListGroup.Item>
                        </ListGroup>
                    </ModalFooter>
                </Modal.Body>
                {data && data.employees.length === 0 && (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Darbuotojų pasirinktam periodui nėra.</Modal.Title>
                        </Modal.Header>
                    </>
                )}
            </Modal>
        );
    }
}

export default JobEmployeesModal;

JobEmployeesModal.propTypes = {
    toggleJobEmployeesModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    data: PropTypes.shape({
        employees: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            unit: PropTypes.string.isRequired,
            hours: PropTypes.number.isRequired,
            status: PropTypes.oneOf([ 'NOT_APPROVED', 'REKYL', 'APPROVED', 'REJECTED' ]).isRequired,
            responsiblePersonStatus: PropTypes.oneOf([ 'NOT_APPROVED', 'APPROVED', 'REJECTED' ]).isRequired,
            canManagerApprove: PropTypes.bool,
        })),
        total: PropTypes.number,
        totalHours: PropTypes.number,
        jobName: PropTypes.string.isRequired,
        note: PropTypes.string,
        responsiblePersonNote: PropTypes.string,
        adminNote: PropTypes.string,
        description: PropTypes.string,
    }),
    role: PropTypes.oneOf(getRoles()).isRequired,
    isForSubordinates: PropTypes.bool,
};

JobEmployeesModal.defaultProps = {
    jobId: null,
    isForSubordinates: false,
};
