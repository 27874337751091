import {
    CHANGE_NOTIFICATION_STATUS_BEGIN,
    CHANGE_NOTIFICATION_STATUS_FAILURE,
    CHANGE_NOTIFICATION_STATUS_SUCCESS,
    CLEAR_NOTIFICATIONS_STATE,
    FETCH_NOTIFICATION_USERS_BEGIN,
    FETCH_NOTIFICATION_USERS_FAILURE,
    FETCH_NOTIFICATION_USERS_SUCCESS,
    FETCH_NOTIFICATIONS_BEGIN,
    FETCH_NOTIFICATIONS_FAILURE,
    FETCH_NOTIFICATIONS_SUCCESS,
    NOTIFICATION_FILE_DOWNLOAD_FAILURE,
    NOTIFICATION_FORM_DELETE_TEMPORARY_FILE,
    NOTIFICATION_FORM_STORE_TEMPORARY_FILE,
    SEND_NOTIFICATION_BEGIN,
    SEND_NOTIFICATION_FAILURE,
    SEND_NOTIFICATION_SUCCESS,
    TOGGLE_SHOW_ALL_NOTIFICATIONS,
    UPDATE_NOTIFICATION_FORM_MESSAGE,
    UPDATE_NOTIFICATION_FORM_RECEIVER_GROUPS,
    UPDATE_NOTIFICATION_FORM_RECEIVERS,
    UPDATE_NOTIFICATION_FORM_SUBMIT_DISABLED,
    UPDATE_UNREAD_NOTIFICATION_COUNT
} from "./notificationActions";

const initialState = {
    receivers: [],
    message: '',
    receiverGroups: [],
    tempFiles: [],
    files: [],
    isSubmitDisabled: true,
    isShowAllNotifications: false,
    notifications: [],
    loading: false,
    users: [],
    success: null,
    error: null,
    unreadNotificationCount: 0
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case CLEAR_NOTIFICATIONS_STATE:
            return {
                ...state,
                error: null,
                success: null
            };
        case UPDATE_UNREAD_NOTIFICATION_COUNT: {
            return {
                ...state,
                unreadNotificationCount: action.payload.value
            };
        }
        case FETCH_NOTIFICATIONS_BEGIN:
        case FETCH_NOTIFICATION_USERS_BEGIN:
        case CHANGE_NOTIFICATION_STATUS_BEGIN:
        case SEND_NOTIFICATION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_NOTIFICATIONS_FAILURE:
        case FETCH_NOTIFICATION_USERS_FAILURE:
        case CHANGE_NOTIFICATION_STATUS_FAILURE:
        case SEND_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.value
            };
        case FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload.value,
                loading: false
            };
        case SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                receivers: [],
                receiverGroups: [],
                isSubmitDisabled: true,
                message: '',
                tempFiles: [],
                success: 'Pranešimas buvo sėkmingai išsiųstas'
            }
        case FETCH_NOTIFICATION_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.value
            };
        case TOGGLE_SHOW_ALL_NOTIFICATIONS:
            return {
                ...state,
                isShowAllNotifications: !state.isShowAllNotifications
            };
        case UPDATE_NOTIFICATION_FORM_MESSAGE:
            return {
                ...state,
                message: action.payload.value
            };
        case UPDATE_NOTIFICATION_FORM_RECEIVERS:
            return {
                ...state,
                receivers: action.payload.value
            };
        case UPDATE_NOTIFICATION_FORM_RECEIVER_GROUPS:
            return {
                ...state,
                receiverGroups: action.payload.value
            }
        case UPDATE_NOTIFICATION_FORM_SUBMIT_DISABLED:
            return {
                ...state,
                isSubmitDisabled: action.payload.value
            }
        case CHANGE_NOTIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case NOTIFICATION_FORM_STORE_TEMPORARY_FILE: {
            return {
                ...state,
                tempFiles: [ ...state.tempFiles, action.payload.value ]
            };
        }
        case NOTIFICATION_FORM_DELETE_TEMPORARY_FILE: {
            return {
                ...state,
                tempFiles: [ ...state.tempFiles.slice(0, action.payload.value), ...state.tempFiles.slice(action.payload.value + 1) ]
            }
        }
        case NOTIFICATION_FILE_DOWNLOAD_FAILURE: {
            return {
                ...state,
                error: 'Nepavyko parsisiųsti failo'
            }
        }
        default:
            return state;
    }
};
