import * as React from "react";
import { Badge } from "react-bootstrap";
import * as PropTypes from "prop-types";
import { FaExclamation, FaQuestion, TiTick } from 'react-icons/all';
import { JOB_ADMIN_STATUS } from "../util/JobStatus";

class JobUserStatusIconBadge extends React.Component {

    render() {
        const { status } = this.props;
        let variant, label;
        switch (JOB_ADMIN_STATUS[status].code) {
            case JOB_ADMIN_STATUS.NOT_APPROVED.code:
                variant = 'secondary';
                label = <FaQuestion/>;
                break;
            case JOB_ADMIN_STATUS.REKYL.code:
                variant = 'warning';
                label = 'Rekyl'
                break;
            case JOB_ADMIN_STATUS.APPROVED.code:
                variant = 'success';
                label = <TiTick />;
                break;
            case JOB_ADMIN_STATUS.REJECTED.code:
                variant = 'danger';
                label = <FaExclamation/>;
                break;
            default:
                variant = 'secondary';
                label = <FaExclamation/>
                break;
        }
        return (
          <Badge pill variant={variant}>{label}</Badge>
        );
    }
}

export default JobUserStatusIconBadge;

JobUserStatusIconBadge.propTypes = {
    status: PropTypes.string.isRequired,
}
