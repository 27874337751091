import React from "react";
import { animatedWrapperVariants, Wrapper } from "./Conversation.style";
import MessageInput from "../input/MessageInput";
import ConversationHeader from "../header/ConversationHeader";
import MessageList from "../messageList/MessageList";


export default () => (
    <Wrapper
        variants={animatedWrapperVariants}
    >
        <ConversationHeader/>
        <MessageList/>
        <MessageInput/>
    </Wrapper>
);