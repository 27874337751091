import {
    CLEAR_CONVERSATION_STATE,
    CONVERSATION_MEMBER_STATUS_UPDATED,
    CONVERSATION_MEMBERS_VIEW_DISPLAYED,
    CONVERSATION_MEMBERS_VIEW_HIDDEN,
    FETCH_CONVERSATION_BEGIN,
    FETCH_CONVERSATION_FAILURE,
    FETCH_CONVERSATION_SUCCESS
} from "./conversationActions";

const initialState = {
    conversation: {
        id: null,
        title: null,
        description: null,
        members: []
    },
    loading: false,
    error: null,
    displayMemberView: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONVERSATION_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_CONVERSATION_SUCCESS:
            return {
                ...state,
                loading: false,
                conversation: action.payload.conversation
            };
        case FETCH_CONVERSATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                conversation: initialState.conversation
            };
        case CLEAR_CONVERSATION_STATE:
            return initialState;
        case CONVERSATION_MEMBERS_VIEW_DISPLAYED:
            return {
                ...state,
                displayMemberView: true
            };
        case CONVERSATION_MEMBERS_VIEW_HIDDEN:
            return {
                ...state,
                displayMemberView: false
            };
        case CONVERSATION_MEMBER_STATUS_UPDATED:
            const { value } = action.payload;
            const updatedMembers = state.conversation.members.map(member => member.userId === value.id
                ? Object.assign({}, member, { status: value.status })
                : member);
            return {
                ...state,
                conversation: {
                    ...state.conversation,
                    members: [ ...updatedMembers ]
                }
            };
        default:
            return state;
    }
};
