import {
    CLEAR_CONVERSATION_MESSAGES_STATE,
    CONVERSATION_MESSAGE_RECEIVED,
    FETCH_CONVERSATION_MESSAGES_BEGIN,
    FETCH_CONVERSATION_MESSAGES_FAILURE,
    FETCH_CONVERSATION_MESSAGES_SUCCESS
} from "./messageActions";
import moment from "moment";

const uniqueById = arr => [ ...new Map(arr.map(item => [ item.id, item ])).values() ];

const messageSorter = (a, b) => moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1;

const initialState = {
    messages: [],
    totalPages: 0,
    totalElements: 0,
    page: 0,
    size: 20,
    loading: false,
    error: null,
    wsMessage: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CONVERSATION_MESSAGES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_CONVERSATION_MESSAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: uniqueById([ ...state.messages, ...action.payload.content ]).sort(messageSorter),
                totalPages: action.payload.totalPages,
                totalElements: action.payload.totalElements,
                page: state.page + 1
            };
        case FETCH_CONVERSATION_MESSAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                messages: []
            };
        case CONVERSATION_MESSAGE_RECEIVED:
            return {
                ...state,
                messages: uniqueById([ ...state.messages, action.payload.value ]).sort(messageSorter),
                wsMessage: action.payload.value
            };
        case CLEAR_CONVERSATION_MESSAGES_STATE: {
            return initialState;
        }
        default:
            return state;
    }
};