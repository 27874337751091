import * as React from 'react';
import CalendarContainer from "./CalendarContainer";
import axios from "axios";
import { handleError } from "../../utilities/ErrorHandler";
import { Button, Container } from "react-bootstrap";
import ResponseContainer from "../shared/containers/ResponseContainer";
import moment from "moment";
import { getFirstCalendarDay, getLastCalendarDay } from "../../utilities/DateHelper";
import { NavLink } from "react-router-dom";
import { getUserRole, isPrivilegedRole } from "../auth/AuthenticationService";
import { connect } from "react-redux";
import { getAuthState } from "../../redux/auth/authSelectors";


class CalendarView extends React.Component {
    constructor(props) {
        super(props);

        const role = getUserRole(props.auth);

        this.state = {
            error: null,
            events: [],
            success: null,
            isLoading: true,
            filter: {
                start: getFirstCalendarDay(),
                end: getLastCalendarDay()
            },
            role
        };

        this.fetchEvents = this.fetchEvents.bind(this);
        this.onError = this.onError.bind(this);
        this.onRangeChange = this.onRangeChange.bind(this);
    }

    componentDidMount() {
        this.fetchEvents();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { filter } = this.state;
        if (prevState.filter !== filter) {
            this.fetchEvents();
        }
    }

    onError(error) {
        this.setState({
            error,
            success: null,
            isLoading: false,
        });
    }

    fetchEvents() {
        const { filter } = this.state;
        axios.post(`${process.env.REACT_APP_API_URL}/events/filter`, filter)
            .then((response) => this.handleResponse(response.data))
            .catch((err) => handleError(err, this.onError));
    }

    handleResponse(events) {
        this.setState({
            events: events.map(this.toEventView),
            isLoading: false
        });
    }

    toEventView(event) {
        return {
            ...event,
            start: moment(event.start).toDate(),
            end: moment(event.end).toDate()
        };
    }

    onRangeChange(filter) {
        this.setState({ filter });
    }

    render() {
        const { events, isLoading, error, success, role } = this.state;

        return (
            <Container fluid>
                <div className="m-2">
                    <h3>Kalendorius</h3>
                    <div className="mb-2">
                        <NavLink to="/vacations/add">
                            <Button variant="primary">Sukurti atostogų prašymą</Button>
                        </NavLink>
                        {isPrivilegedRole(role) && (
                            <React.Fragment>
                                <NavLink to="/vacations">
                                    <Button className="ml-1" variant="info">Atostogų prašymai</Button>
                                </NavLink>
                            </React.Fragment>
                        )}
                    </div>
                    <ResponseContainer error={error} success={success} isLoading={isLoading}/>
                    {!isLoading && (
                        <CalendarContainer
                            onRangeChange={this.onRangeChange}
                            events={events}
                        />
                    )}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: getAuthState(state),
})

export default connect(mapStateToProps)(CalendarView);

