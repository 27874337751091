import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import Router from './Router';
import store from './redux/store';
import * as serviceWorker from './serviceWorker.js';

const Root = () => (
    <Provider store={store}>
        <Router/>
    </Provider>
);

ReactDOM.render(<Root/>, document.getElementById('root'));
// worker setup
serviceWorker.register();