import React, {useState} from 'react';
import useIosInstallPrompt from "./hooks/useIosInstallPrompt";
import useWebInstallPrompt from "./hooks/useWebInstallPrompt";
import useAndroidInstallPrompt from "./hooks/useAndroidInstallPrompt";
import {isOpera, isFirefox, isEdge} from "react-device-detect";
import InstallationGuide from "./guide/InstallationGuide";
import {getInstallationType, InstallationType} from "./InstallationType";
import {Toast} from "react-bootstrap";
import  "./InstallPrompter.scss";
import {installationInstructions} from "./guide/installationInstructions";

const InstallPrompter = () => {
    const [iOsInstallPrompt, handleIOsPromptClosed] = useIosInstallPrompt();
    const [androidInstallPrompt, handleAndroidPromptClosed] = useAndroidInstallPrompt();
    // Android Chrome also uses web install prompt
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();
    const isPwaSupported = iOsInstallPrompt || webInstallPrompt ||
        (androidInstallPrompt && getInstallationType() !== InstallationType.NOT_SUPPORTED);
    const [showPrompt, setShowPrompt] = useState(isPwaSupported);


    if (!isPwaSupported) {
        return null;
    }

    const closeToast = () => {
        if(webInstallPrompt) {
            handleWebInstallDeclined()
        } else if(iOsInstallPrompt) {
            handleIOsPromptClosed()
        } else {
            handleAndroidPromptClosed()
        }
        setShowPrompt(false)
    }

    return (
        <div className={`${!showPrompt && "d-none"} pwa-install__toastStyle`}>
            <Toast show={showPrompt} className={`pwa-install__toastStyle`} onClose={closeToast} >
                <Toast.Header className={"w-100 justify-content-between"} closeButton={true}>
                    <h6>Instaliuoti programėlę</h6>
                </Toast.Header>
                <Toast.Body>
                    {webInstallPrompt &&
                    <InstallationGuide
                        handleAccept={() => {
                            handleWebInstallAccepted()
                            setShowPrompt(false)
                        }}
                        handleDecline={closeToast}
                    />}
                    {iOsInstallPrompt &&
                    <InstallationGuide
                        instructions={installationInstructions.iOS.MobileSafari}
                        handleClose={closeToast}
                    />}
                    {androidInstallPrompt && isOpera &&
                    <InstallationGuide
                        instructions={installationInstructions.Android.Opera}
                        handleClose={closeToast}
                    />}
                    {androidInstallPrompt && isFirefox &&
                    <InstallationGuide
                        instructions={installationInstructions.Android.Firefox}
                        handleClose={closeToast}
                    />}
                    {!webInstallPrompt && androidInstallPrompt && isEdge &&
                    <InstallationGuide
                        instructions={installationInstructions.Android.Edge}
                        handleClose={closeToast}
                    />}
                </Toast.Body>
            </Toast>
        </div>
    );
};

export default InstallPrompter;