import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Wrapper } from "./MessageList.style";
import MessageListItem from "./item/MessageListItem";
import { getMessagePagination, getMessages, getWsMessage } from "../../../../redux/chat/messages/messageSelectors";
import { fetchConversationMessages } from "../../../../redux/chat/messages/messageActions";
import Loader from "../../../shared/util/Loader";
import EndMessage from "./endMessage/EndMessage";
import UserInitialsAvatar from "../members/userInitialsAvatar/UserInitialsAvatar";


export default () => {
    const messages = useSelector(getMessages);
    const wsMessage = useSelector(getWsMessage);
    const { page, totalPages } = useSelector(getMessagePagination);

    const dispatch = useDispatch();

    const wrapper = useRef(document.createElement("div"));
    const el = wrapper.current;

    const hasReachedBottom = (el.scrollHeight - el.clientHeight) === el.scrollTop;

    const scrollToBottom = useCallback(() => {
        return (el.scrollTop = el.scrollHeight - el.clientHeight);
    }, [ el ]);

    useEffect(() => {
        if (wsMessage) {
            scrollToBottom();
        }
    }, [ wsMessage, hasReachedBottom, scrollToBottom ]);

    const renderMessage = message => (
        <MessageListItem
            message={message}
            key={message.id}
            avatar={
                <UserInitialsAvatar
                    size={36}
                    name={message.sender.fullName}
                    userId={message.sender.id.toString()}
                />}
        />
    );

    return (<Wrapper id="scrollableDiv" ref={wrapper}>
        <InfiniteScroll
            dataLength={messages.length}
            next={() => dispatch(fetchConversationMessages())}
            hasMore={page < totalPages}
            scrollableTarget="scrollableDiv"
            inverse={true}
            loader={<Loader isLoading={true}/>}
            endMessage={messages.length > 20 && <EndMessage/>}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}>
            {messages.map(renderMessage)}
        </InfiniteScroll>
    </Wrapper>);
}