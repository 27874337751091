import React from 'react';
import {textFilter} from "react-bootstrap-table2-filter";
import { Button } from 'react-bootstrap';
import { headerFormatter, userCellFormatter } from '../../../../utilities/TableHelper';
import { notificationUserStatusEnum } from '../enums/NotificationUserStatusEnum';
import { notificationStatusEnum } from '../enums/NotificationStatusEnum';
import { OverflowWrapper } from "../NotificationTable.style";
import FileWrapper from "../../../shared/file/FileWrapper";
import UserByRoleBadge from "../../../shared/badge/UserByRoleBadge";

const FILTER_PLACEHOLDER = 'Ieškoti';

export const getColumns = (onChangeNotificationStatus, onConversationClick, onDownloadFile) => [
    {
        text: 'Gavėjas/ai',
        dataField: 'receivers',
        sort: true,
        headerFormatter,
        formatter: (cell) => (
            <OverflowWrapper>
                {cell.length > 0 ? cell.map((user, index) => <UserByRoleBadge key={index} user={user}/>) : "Gavėjai generuojami..."}
            </OverflowWrapper>
        ),
        filter: textFilter({
            placeholder: FILTER_PLACEHOLDER
        }),
        filterValue: cell => cell.length > 0 ? cell.map(user => `${user.name}`).join(' ') : ''
    },
    {
        text: 'Siuntėjas',
        dataField: 'sender',
        sort: true,
        headerFormatter,
        formatter: userCellFormatter,
        filter: textFilter({
            placeholder: FILTER_PLACEHOLDER
        }),
        filterValue: cell => cell.name
    },
    {
        text: 'Pranešimas',
        dataField: 'message',
        sort: true,
        headerFormatter,
        formatter: (cell) => (
            <OverflowWrapper>{cell}</OverflowWrapper>
        ),
    },
    {
        text: 'Pranešimo data',
        dataField: 'createdAt',
        sort: true,
        headerFormatter,
    },
    {
        text: 'Pranešimo statusas',
        dataField: 'userStatus',
        headerFormatter,
        sort: true,
        formatter: (cell, row) => (
            <div className="d-flex justify-content-center">
                {renderNotificationStatusText(
                    cell,
                    onChangeNotificationStatus,
                    row,
                )}
            </div>
        ),
    },
    {
        text: 'Failai',
        dataField: 'files',
        headerFormatter,
        sort: true,
        formatter: (cell, row) => renderFiles(cell, row.id, onDownloadFile),
    },
    {
        text: '',
        dataField: 'conversationId',
        headerFormatter,
        sort: true,
        formatter: (cell, row) => (
            <div className="d-flex justify-content-center">
                {row.conversationId && renderConversationOpener(
                    onConversationClick,
                    row,
                )}
            </div>
        ),
    }
];


const renderFiles = (files, notificationId, onDownloadFile) => (
    <OverflowWrapper>
        {files.map(file => renderFile(file, notificationId, onDownloadFile))}
    </OverflowWrapper>
);

const renderFile = (file, notificationId, onDownloadFile) => (
    <FileWrapper
        key={file.id}
        id={file.id}
        fileName={file.fileName}
        isDownloadAllowed={true}
        onDownload={(id, fileName) =>
            onDownloadFile({
                notificationId,
                id,
                fileName
            })}
        isDeleteAllowed={false}
    />
);

const renderNotificationStatusText = (userNotificationStatus, onChangeNotificationStatus, rowValue) => {
    if (rowValue.status === notificationStatusEnum.SEEN.value) {
        return 'Visi gavėjai perskaitė';
    }
    if (rowValue.isUserSender && !rowValue.isUserReceiver) {
        return "Išsiūsta";
    }
    if (userNotificationStatus === notificationUserStatusEnum.SEEN.value && rowValue.isUserSender) {
        return 'Perskaityta';
    }
    if (userNotificationStatus === notificationUserStatusEnum.NOT_SEEN.value && rowValue.isUserReceiver) {
        return (
            <Button
                variant="outline-primary"
                size="sm"
                onClick={() => onChangeNotificationStatus(rowValue)}
            >
                Žymėti kaip perskaityta
            </Button>
        );
    }
    return 'Perskaityta';
};

const renderConversationOpener = (onConversationClick, row) => {
    return (
        <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => onConversationClick(row)}
        >
            Atsakyti į pranešimą
        </Button>
    );

};
