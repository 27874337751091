import { createSelector } from "reselect";

export const getMessageState = ({ chat }) => chat.messages;

export const getMessages = createSelector([ getMessageState ], ({ messages }) => messages);

export const getWsMessage = createSelector([ getMessageState ], ({ wsMessage }) => wsMessage);

export const getMessagePagination = createSelector([ getMessageState ], ({ page, totalPages }) => ({
    page,
    totalPages
}));

export const getMessageRequestParams = createSelector([ getMessageState ], ({ page, size }) => ({
    page,
    size
}));
