import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import axios from "axios";
import { unauthorizedInterceptor } from "./interceptors";
import rootReducer from "./rootReducer";
import { User } from "./auth/authModel";
import { SET_CURRENT_USER } from "./auth/authReducer";

export const STORAGE_TOKEN_KEY = 'USER_NAME_SESSION_ATTRIBUTE_NAME';

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
);

const token = localStorage.getItem(STORAGE_TOKEN_KEY);
if (token) {
    const user = new User(token);
    if (new Date() > user.tokenExpiration) {
        delete axios.defaults.headers.common.Authorization;
        localStorage.removeItem(STORAGE_TOKEN_KEY);
    } else {
        unauthorizedInterceptor();
        axios.defaults.headers.common.Authorization = token;
        store.dispatch({ type: SET_CURRENT_USER, payload: user });
    }
}

export default store;
