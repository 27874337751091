import * as React from 'react';
import Button from 'react-bootstrap/Button';
import {FaSave} from 'react-icons/fa';

class FormSubmitBtn extends React.Component {
  render() {
    return (
      <div className="d-flex">
        <Button variant="success" type="submit" className="w-100">
          <FaSave />
          {' '}
Saugoti
        </Button>
      </div>
    );
  }
}

export default FormSubmitBtn;
