class CalcHelper {
  numOr0(n) {
    return (isNaN(n) ? 0 : n);
  }

  safeAdd(a, b) {
    return this.numOr0(parseFloat(a)) + this.numOr0(parseFloat(b));
  }

  safeMultiply(a, b) {
    return this.numOr0(parseFloat(a)) * this.numOr0(parseFloat(b));
  }

  calcTotalAmount(row) {
    const sumHalfs = this.safeAdd(row.firstHalf, row.secondHalf);
    const base = this.safeMultiply(sumHalfs, row.salary);

    const deductables = [row.rent, row.executiveLogs, row.travelExpenses, row.penalty, row.advancedPayment];

    const addables = [row.fieldFirstHalf, row.fieldSecondHalf];

    const total = base
      + addables.reduce((a, b) => this.safeAdd(this.numOr0(a), this.numOr0(b)))
      - deductables.reduce((a, b) => this.safeAdd(this.numOr0(a), this.numOr0(b)))
      + row.totalExtra;

    return this.numOr0(total);
  }
}

export default (new CalcHelper());
