import React from 'react';
import { Col, Form } from 'react-bootstrap';
import MiningJobUser from "./MiningJobUser";

export default (
    {
        form,
        onChange,
        onUserRemove,
        selectableUsers,
        selectableUnitMeasures
    }) => {
    const handleInputChange = (event) => onChange(event.target.name)(event, event.target.value);

    const handleUsersChange = index => field => (event, value) => {
        let data = [ ...form.users ];
        data[index][field] = value;
        onChange('users')(null, data);
    }

    return (
        <div>
            <Form.Row className="align-items-center mb-4 mt-4">
                <Col md={4} sm={7} className="mt-2 mb-2">
                    <Form.Label>Viso iškasta*</Form.Label>
                    <Form.Control
                        id="totalMined"
                        required
                        min={form.minMined}
                        type="number"
                        name="totalMined"
                        value={form.totalMined}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col md={4} sm={9} className="mt-2 mb-2">
                    <Form.Label>Mato vnt.*</Form.Label>
                    <Form.Control
                        id="unitMe"
                        required
                        as="select"
                        name="unitMeId"
                        value={form.unitMeId}
                        onChange={handleInputChange}
                    >
                        <option key="default"/>
                        {selectableUnitMeasures.map((unit) =>
                            <option
                                value={unit.id}
                                id={unit.id}
                                key={unit.id}>
                                {unit.name}
                            </option>
                        )}
                    </Form.Control>
                </Col>
            </Form.Row>
            <Form.Row className="align-items-center mb-4 mt-4">
                <Col md={8} sm={8} className="mt-2 mb-2">
                    <Form.Label className="mr-4">Darbuotojai*</Form.Label>
                    {form.users.map((user, index) => (
                        <MiningJobUser
                            key={index}
                            user={user}
                            index={index}
                            onUserRemove={onUserRemove}
                            onChange={handleUsersChange(index)}
                            selectableUsers={selectableUsers}
                        />
                    ))}
                </Col>
            </Form.Row>
        </div>
    );
};