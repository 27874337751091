import {Spinner} from "react-bootstrap";
import * as PropTypes from 'prop-types';
import * as React from "react";

class Loader extends React.Component {

    render() {
        const { isLoading } = this.props;
        return (
            <>
                {isLoading && (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>
                )}
            </>
        );
    }
}

export default Loader;

Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
}