import React from "react";
import { useSelector } from "react-redux";
import { Body, Border, Description, Information, Name, ReturnWrapper, Wrapper } from "./ConversationHeader.style";
import { getCurrentConversation } from "../../../../redux/chat/conversation/conversationSelectors";
import ConversationOccupancy from "../occupancy/ConversationOccupancy";
import ReturnHandler from "../../../shared/buttons/ReturnHandler";

export default () => {
    const conversation = useSelector(getCurrentConversation);
    return (
        <Wrapper>
            <Body>
                <div>
                    <ReturnWrapper>
                        <ReturnHandler className="rounded-circle"/>
                    </ReturnWrapper>
                </div>
                <Information>
                    <Name>{conversation.title}</Name>
                    <Description>{conversation.description}</Description>
                </Information>
                <ConversationOccupancy/>
            </Body>
            <Border/>
        </Wrapper>
    );
};