import React from 'react';
import { Button } from 'react-bootstrap';

class ReportExtraSchema {
  constructor() {
    this.uiSchema = {
      'ui:ArrayFieldTemplate': this.arrayFieldTemplate,
      items: {
        'ui:ObjectFieldTemplate': this.getArrayObjectTemplate,
        comment: {
          'ui:widget': 'textarea',
          'ui:options': {
            rows: 2,
          },
        },
      },
    };
  }

  initSchema() {
    return {
      type: 'array',
      items: {
        type: 'object',
        required: ['operator', 'value'],
        properties: {
          operator: {
            title: 'Ženklas',
            type: 'string',
            enum: ['+', '-'],
            enumNames: ['+', '-'],
          },
          value: {
            title: 'Suma',
            type: 'number',
          },
          comment: {
            title: 'Komentaras',
            type: 'string',
          },
        },
      },
    };
  }

  getArrayObjectTemplate(props) {
    return (
      <div key={props.idSchema.$id}>
        <div className="row">
          {props.description}
          {props.properties.map((element) => (
            <div
              key={element.content.props.idSchema.$id}
              className="col-md-4"
            >
              {element.content}
            </div>
          ))}
        </div>
      </div>
    );
  }

  arrayFieldTemplate(props) {
    return (
      <div className={props.className}>
        {props.items && props.items.map((element) => (
          <React.Fragment key={element.key}>
            <div className="wrapper d-flex">
              {element.children}
              <div className="align-self-center ml-1">
                <Button variant="danger" size="sm" onClick={element.onDropIndexClick(element.index)}>
                  Trinti
                </Button>
              </div>
            </div>
          </React.Fragment>
        ))}

        {props.canAdd && (
          <div className="d-flex justify-content-end right">
            <Button variant="secondary" onClick={props.onAddClick} type="button">
              Pridėti
            </Button>
          </div>
        )}
      </div>
    );
  }
}
export default (new ReportExtraSchema());
