import * as React from "react";
import * as PropTypes from 'prop-types';
import Button from "react-bootstrap/Button";
import { FaDownload, FaTrash } from "react-icons/fa";

export const FileWrapper = ({ fileName, isDownloadAllowed, isDeleteAllowed, onDelete, onDownload, id }) => {
    return (
        <div className="d-flex justify-content-between align-items-center mb-2">
            {fileName}
            <div>
                {isDownloadAllowed && (
                    <Button variant="outline-primary"
                            className="mr-2 ml-2"
                            size="sm"
                            onClick={() => onDownload(id, fileName)}>
                        <FaDownload/>
                    </Button>
                )}
                {isDeleteAllowed && (
                    <Button variant="danger"
                            size="sm"
                            onClick={() => onDelete(id)}>
                        <FaTrash/>
                    </Button>
                )}
            </div>
        </div>
    );
}

export default FileWrapper;

FileWrapper.propTypes = {
    id: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    onDownload: PropTypes.func,
    onDelete: PropTypes.func,
    isDeleteAllowed: PropTypes.bool,
    isDownloadAllowed: PropTypes.bool
}
FileWrapper.defaultProps = {
    onDownload: () => {
    },
    onDelete: () => {
    },
    isDeleteAllowed: true,
    isDownloadAllowed: true
}