class RegisterSchema {
  constructor() {
    this.uiSchema = {
      password: {
        'ui:widget': 'password',
      },
    };
  }

  initSchema() {
    return {
      type: 'object',
      required: ['phone', 'password'],
      title: 'Sveiki atvykę! ',
      description: 'Sugalvokite slaptažodį ir pasitikrinkite telefono numerį.',
      properties: {
        fullName: { type: 'string', title: 'Vardas', readOnly: true },
        email: { type: 'string', title: 'El. paštas', readOnly: true },
        password: { type: 'string', title: 'Slaptažodis' },
        phone: { type: 'string', title: 'Tel nr.' },
      },
    };
  }
}

export default (new RegisterSchema());
