import * as React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import moment from "moment";
import { Container } from 'react-bootstrap';
import ResponseContainer from '../shared/containers/ResponseContainer';
import { handleError } from '../../utilities/ErrorHandler';
import VacationForm, { INITIAL_FORM_STATE } from "./VacationForm";
import { getUserRole, isPrivilegedRole, isTeamLeaderRole, isUserRole, ROLES } from "../auth/AuthenticationService";
import { getAuthState } from "../../redux/auth/authSelectors";

class AddVacation extends React.Component {
    constructor(props) {
        super(props);
        const role = getUserRole(props.auth);

        this.state = {
            error: null,
            loading: true,
            users: [],
            teamLeaders: [],
            admins: [],
            isFormDirty: false,
            form: INITIAL_FORM_STATE,
            role,
            isMyVacation: isUserRole(role)
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onError = this.onError.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onPeriodChange = this.onPeriodChange.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);
        this.fetchUsersByRole = this.fetchUsersByRole.bind(this);
        this.handleMyselfVacation = this.handleMyselfVacation.bind(this);
    }

    componentDidMount() {
        this.fetchUsers().then(() => {
            this.setState({
                loading: false
            })
        });
    }

    fetchUsers() {
        return Promise.all([
            this.fetchUsersByRole(ROLES.ADMIN),
            this.fetchUsersByRole(ROLES.TEAM_LEADER),
            this.fetchUsersByRole(ROLES.USER)
        ]).then(response =>
            this.setState({
                admins: response[0].data.users,
                teamLeaders: response[1].data.users,
                users: response[2].data.users
            }));
    }

    fetchUsersByRole(role) {
        const req = `?role=${role}`
        return axios.get(`${process.env.REACT_APP_API_URL}/user${req}`)
            .catch((err) => handleError(err, this.onError));
    }

    onError(error) {
        this.setState({
            error
        });
    }

    onSubmit(event) {
        event.preventDefault();
        const { form, isMyVacation } = this.state;
        const path = isMyVacation
            ? '/vacations'
            : '/vacations/user';

        return axios.post(`${process.env.REACT_APP_API_URL}${path}`, mapToRequest(form))
            .then(() => this.props.history.push('/calendar'))
            .catch((err) => (handleError(err, this.onError)));
    }

    onChange(value, name) {
        const { form } = this.state;
        this.setState({
            form: { ...form, [name]: value },
            isFormDirty: true
        });
    }

    onPeriodChange(value, name) {
        const { form } = this.state;

        if (name === 'start' && moment(form.end).isBefore(value)) {
            this.setState({
                error: 'Atostogų pradžia negali būti vėliau nei pabaiga.',
                isFormDirty: true
            });
        } else {
            this.setState({
                form: { ...form, [name]: value },
                isFormDirty: true,
                error: null
            });
        }
    }

    handleMyselfVacation() {
        this.setState(prevState => ({
            isMyVacation: !prevState.isMyVacation,
            form: INITIAL_FORM_STATE
        }))
    }

    render() {
        const {
            error, loading, users, admins, teamLeaders, form, role, isMyVacation
        } = this.state;

        return (
            <Container fluid>
                <h2 className="text-center">Atostogų prašymas</h2>
                <ResponseContainer isLoading={loading} error={error}/>
                {isPrivilegedRole(role) && <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" id="myselfVacation"
                           checked={isMyVacation} onChange={this.handleMyselfVacation}/>
                    <label className="custom-control-label" htmlFor="myselfVacation">Ar norite sukurti atostogas
                        sau?</label>
                </div>}
                {!loading && (
                    <VacationForm
                        onSubmit={this.onSubmit}
                        onChange={this.onChange}
                        onPeriodChange={this.onPeriodChange}
                        form={form}
                        responsibleUsers={isTeamLeaderRole(role) ? admins : [ ...admins, ...teamLeaders ]}
                        selectableUsers={isTeamLeaderRole(role) ? users : [ ...teamLeaders, ...users ]}
                        role={role}
                        isMyVacation={isMyVacation}
                    />
                )}
            </Container>
        );
    }
}

const mapToRequest = form => ({
    responsiblePersonId: form.responsiblePerson.length > 0 ? form.responsiblePerson[0].userId : undefined,
    userId: form.user.length > 0 ? form.user[0].userId : undefined,
    start: form.start,
    end: form.end,
    description: form.description,
});

const mapStateToProps = (state) => ({
    auth: getAuthState(state),
})

AddVacation.propTypes = {
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(AddVacation);