import * as React from 'react';
import { Button, Container } from 'react-bootstrap';
import axios from 'axios';
import UsersTable from '../UsersTable';
import ResponseContainer from "../../shared/containers/ResponseContainer";
import { handleError } from '../../../utilities/ErrorHandler';

class AdminUsersHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: null,
      isLoading: true,
    };

    this.handleTableResponse = this.handleTableResponse.bind(this);
    this.handleChangeStatusClick = this.handleChangeStatusClick.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/user/datatable`)
      .then((response) => {
        this.handleTableResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleTableResponse(data) {
    this.setState({
      error: null,
      data,
      isLoading: false,
    });
  }

  onError(error) {
    this.setState({
      error,
      isLoading: false,
    });
  }

  handleChangeStatusClick(id, activated) {
    this.setState({
      isLoading: true,
    })
    axios.post(`${process.env.REACT_APP_API_URL}/user/${id}/status`, {activated})
      .then((response) => this.handleTableResponse(response.data))
      .catch((err) => handleError(err, this.onError));
  }

  render() {
    const { data, error, isLoading } = this.state;
    const { history } = this.props;
    return (
      <Container fluid>
        <div className="m-2">
          <h3>Vartotojai</h3>
          <Button
              onClick={() => history.push('/administration/users/add')}
              className="mb-2"
              variant="primary">
              Pridėti vartotoją
          </Button>
          <ResponseContainer isLoading={isLoading} error={error} />
          { !isLoading && data.length > 0 && !error
            && <UsersTable data={data} history={history} handleChangeStatusClick={this.handleChangeStatusClick} isAdmin />}
        </div>
      </Container>
    );
  }
}
export default AdminUsersHome;
