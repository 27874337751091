const getUniqueColor = (string, colorSet) => {
    const getNumericHash = (string) => {
        let hash = 0;
        if (!string.length) {
            return hash;
        }
        string.split('').forEach((char) => {
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        })
        return hash;
    };
    const modulo = colorSet.length;
    const colorIndex = ((getNumericHash(string) % modulo) + modulo) % modulo;
    return colorSet[colorIndex];
};

export default getUniqueColor;
