import React from "react";
import { About, ActiveDot, Avatar, UserName, UserTitle, Wrapper, } from "./MemberDescription.style";
import UserInitialsAvatar from "../userInitialsAvatar/UserInitialsAvatar";
import { USER_STATUS } from "../../../../../redux/chat/conversation/conversationModel";
import { ROLE_TRANSLATIONS } from "../../../../auth/AuthenticationService";

export default ({ user }) => {
    return (
        <Wrapper>
            <Avatar>
                <UserInitialsAvatar
                    size={36}
                    name={user.fullName}
                    userId={user.id.toString()}
                />
            </Avatar>
            <About>
                <UserName>
                    {`${user.fullName} `}
                    <ActiveDot active={user.status === USER_STATUS.ONLINE}/>
                </UserName>
                <UserTitle>{ROLE_TRANSLATIONS[user.role]}</UserTitle>
            </About>
        </Wrapper>
    );
};