import * as React from 'react';
import { connect } from "react-redux";
import { BrowserRouter as Router, Link, Route, Switch, } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import PropTypes from "prop-types";
import { getUserRole, isAdminRole, isPrivilegedRole } from "../auth/AuthenticationService";
import UnitMeHome from './unitMe/UnitMeHome';
import UserDutiesHome from "./userDuties/UserDutiesHome";
import JobDescriptionHome from './jobDescription/JobDescriptionHome';
import { getAuthState } from "../../redux/auth/authSelectors";

class SettingsHome extends React.Component {

  constructor(props) {
    super(props);
    const role = getUserRole(props.auth);
    this.state = { role };
  }
  render() {
    const { role } = this.state;
    const routes = [
      {
        path: '/administration/settings',
        exact: true,
        main: () => <h2>Įmonės nustatymai (kuriama)</h2>,
      },
      {
        path: '/administration/settings/unit-measurement',
        exact: true,
        main: () => <UnitMeHome />,
      },
      {
        path: '/administration/settings/user-duties',
        exact: true,
        main: () => <UserDutiesHome />,
      },
      {
        path: '/administration/settings/job-descriptions',
        exact: true,
        main: () => <JobDescriptionHome />,
      },
    ];

    return (
      <Router>
        <Container fluid className="d-flex h-100">

          <nav className="col-md-2 d-none d-md-block bg-light sidebar h-100">
            <div className="sidebar-sticky">
              <ul className="nav flex-column">
                  {isPrivilegedRole(role) && (<li className="nav-item">
                        <Link to="/administration/settings/unit-measurement" className="nav-link">Mato vienetų nustatymai</Link>
                    </li>
                  )}
                  {isAdminRole(role) && (<li className="nav-item">
                        <Link to="/administration/settings/user-duties" className="nav-link">Darbuotojų pareigos ir atlyginimai</Link>
                    </li>
                  )}
                  {isPrivilegedRole(role) && (<li className="nav-item">
                        <Link to="/administration/settings/job-descriptions" className="nav-link">Darbų aprašymai</Link>
                    </li>
                  )}
              </ul>
            </div>
          </nav>

          <Container>
            <Switch>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </Switch>
          </Container>
        </Container>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: getAuthState(state),
  };
}

SettingsHome.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SettingsHome);
