import React from 'react';
import PropTypes from 'prop-types';
import DMChatAvatar from "./DMChatAvatar";
import "./DMChatMainHeader.scss"
import {FaArrowLeft, FaPhone} from "react-icons/fa";

const DMChatMainHeader = ({currentSelectedUser, resetChatState}) => {
    return (
        <div className={"dm-chat-main-header"}>
            <div className={"d-flex"}>
                <div onClick={() => resetChatState()} className={"dm-chat-main-header__back dm-chat-main-header__button"}><FaArrowLeft /></div>
                <DMChatAvatar userName={`${currentSelectedUser.name?.trim()} ${currentSelectedUser.surname?.trim()}`} />
                <div>
                    <p className="dm-chat-main-header__name">{currentSelectedUser?.name?.trim()} {currentSelectedUser?.surname?.trim()}</p>
                </div>
            </div>
            {currentSelectedUser?.phoneNr && <div className={"dm-chat-main-header__button-wrapper"}>
                <a href={`tel:${currentSelectedUser?.phoneNr}`} className={"dm-chat-main-header__button"}>
                    <FaPhone />
                </a>
            </div>}

        </div>
    );
};

DMChatMainHeader.propTypes = {
    currentSelectedUser: PropTypes.shape({
        id: PropTypes.number.isRequired,
        latestMessage: PropTypes.string,
        latestMessageCreatedAt: PropTypes.string,
        phoneNr: PropTypes.string,
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        unreadMessagesCount: PropTypes.number
    }),
    resetChatState: PropTypes.func
};

export default DMChatMainHeader;
