import React from 'react';
import StandardJobUser from "./StandardJobUser";

export default (
    {
        form,
        onChange,
        onUserRemove,
        selectableUsers,
        selectableUnitMeasures
    }) => {

    const handleFieldChange = index => field => (event, value) => {
        let data = [ ...form ];
        data[index][field] = value;
        onChange(null, data);
    }

    return (<div>
        {form.map((user, index) => (
            <StandardJobUser
                key={index}
                user={user}
                index={index}
                onChange={handleFieldChange(index)}
                onUserRemove={onUserRemove}
                selectableUsers={selectableUsers}
                selectableUnitMeasures={selectableUnitMeasures}
            />
        ))}
    </div>);
};
