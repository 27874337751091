export const BREAKPOINT_SMALL = 600;
export const BREAKPOINT_LARGE = 1200;

export default {
    breakpoint: {
        mediaQuery: {
            small: `(max-width: ${BREAKPOINT_SMALL}px)`,
            large: `(min-width: ${BREAKPOINT_LARGE}px)`
        }
    }
};
