import styled from 'styled-components';

export const Wrapper = styled.div`
  font-weight: 400;
  border-radius: 50%;
  text-align: center;
  color: white;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: ${props => props.color};
  width: ${props => props.size}px;
  font-size: ${props => Math.round(props.size / 3)}px;
  height: ${props => props.size}px;
  line-height: ${props => props.size}px;
`;
