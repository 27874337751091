import React from 'react';
import * as PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import { FaExclamationTriangle } from 'react-icons/fa';
import TooltipComponent from '../tooltip/TooltipComponent';

export const TriangleAlert = ({
    hint,
  }) => {


  const iconHeight = { maxHeight: "20px" };

  return (
    <div className="float-left" style={iconHeight}>
      <TooltipComponent component={
          <IconContext.Provider value={{ style: { color: "orange", marginRight: "5px" }}}>
            <div>
              <FaExclamationTriangle />
            </div>
          </IconContext.Provider>
        } tooltipText={hint} />
      </div>
  );
}

TriangleAlert.propTypes = {
  hint: PropTypes.string.isRequired,
};