import * as React from 'react';
import {
  Modal, Table,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

class CustomerPersonModal extends React.Component {
  render() {
    const { showModal, toggleModal, modalData } = this.props;
    return (
      <Modal show={showModal} onHide={toggleModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalData.customerName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Vardas</th>
                <th>El. paštas</th>
                <th>Telefono numeris</th>
                <th>Pareigos</th>
              </tr>
            </thead>
            <tbody>
              { modalData.data.map((person) => (
                <tr key={person.id}>
                  <td>{person.name}</td>
                  <td>{person.email}</td>
                  <td>{person.phone}</td>
                  <td>{person.duties}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CustomerPersonModal;

CustomerPersonModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  modalData: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        employees: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          email: PropTypes.string,
          phone: PropTypes.string,
          duties: PropTypes.string,
        })),
      }).isRequired,
    ).isRequired,
  }).isRequired,
};
