import * as React from 'react';
import { connect } from "react-redux";
import { Container } from 'react-bootstrap';
import PropTypes from "prop-types";
import axios from 'axios';
import { Prompt } from 'react-router-dom';
import ReturnHomeHandler from './ReturnHomeHandler';
import ResponseContainer from '../shared/containers/ResponseContainer';
import { handleError } from '../../utilities/ErrorHandler';
import { JOB_FORM_OBJ } from './form/constants';
import JobForm from './form/JobForm';
import { getCommonDescription } from './helpers/helper';
import { toDTO } from "./helpers/jobMapper";
import { getAuthState } from "../../redux/auth/authSelectors";

class AddJob extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            classifiers: {},
            loading: true,
            isSubmitting: false,
            form: {
                ...JOB_FORM_OBJ,
            },
            isFormDirty: false,
            auth: props.auth
        };

        this.onError = this.onError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.onJobSaved = this.onJobSaved.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.markAsDirty = this.markAsDirty.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.getClassifiers = this.getClassifiers.bind(this);
        this.onAddArrayItem = this.onAddArrayItem.bind(this);
        this.onDeleteArrayItem = this.onDeleteArrayItem.bind(this);
        this.handleClassifiersSuccess = this.handleClassifiersSuccess.bind(this);
        this.onDescriptionsChange = this.onDescriptionsChange.bind(this);
    }

    componentDidMount() {
        this.getClassifiers().then(() => this.setState({ loading: false }));
    }

    getClassifiers() {
        return axios.get(`${process.env.REACT_APP_API_URL}/job/form`)
            .then(this.handleClassifiersSuccess)
            .catch((err) => handleError(err, this.onError));
    }

    handleClassifiersSuccess({ data }) {
        this.setState({
            classifiers: data
        });
    }

    onError(error) {
        this.setState({
            error,
            isSubmitting: false
        });
    }

    onSubmit(event) {
        event.preventDefault();
        const { form } = this.state;

        const error = this.validate(form);
        if (!!error) {
            return handleError(error, this.onError);
        }
        const dto = toDTO(form);

        this.setState({
            isSubmitting: true
        });
        axios.post(`${process.env.REACT_APP_API_URL}/job`, dto)
            .then(({ data }) => Promise.all(form.tempFiles.map(file => this.uploadFile(data, file))))
            .then(this.onJobSaved)
            .catch((err) => handleError(err, this.onError));
    }

    validate(form) {
        if (form.responsiblePerson.filter(user => !!user.userId).length === 0) {
            return { message: 'Prašome pasirinkti atsakingą asmenį šiam darbui' };
        }
        if (form.users.filter(({ user }) => user[0] && user[0].userId).length === 0) {
            return { message: 'Prašome pasirinkti bent vieną darbuotoją šiam darbui' };
        }
        return undefined;
    }


    onJobSaved() {
        this.setState({
            isFormDirty: false
        });
        this.props.history.push('/jobs');
    }

    uploadFile(jobId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(`${process.env.REACT_APP_API_URL}/job/${jobId}/file`, formData);
    }

    onDescriptionsChange(descriptions) {
        const { classifiers, form } = this.state;

        const description = getCommonDescription(descriptions, classifiers.descriptions, form.description);
        this.setState({
            form: {
                ...form,
                descriptions,
                description
            }
        }, this.markAsDirty)
    }

    markAsDirty() {
        this.setState({
            isFormDirty: true
        });
    }

    onFieldChange(field) {
        return (event, value) => {
            const { form } = this.state;
            this.setState({
                form: { ...form, [field]: value }
            }, this.markAsDirty);
        }
    }

    onAddArrayItem(field) {
        return value => {
            const { form } = this.state;
            const data = [ ...form[field] ];
            data.push(value);
            this.setState({
                form: { ...form, [field]: data }
            }, this.markAsDirty);
        }
    }

    onDeleteArrayItem(field) {
        return index => {
            const { form } = this.state;
            const data = [ ...form[field] ];
            data.splice(index, 1);
            this.setState({
                form: { ...form, [field]: data }
            }, this.markAsDirty);
        }
    }

    render() {
        const {
            error, form, loading, classifiers, isFormDirty, isSubmitting, auth
        } = this.state;

        return (
            <Container fluid>
                <Prompt
                    when={isFormDirty}
                    message="Yra neišsaugotų pakeitimų. Ar tikrai norite palikti puslapį?"
                />
                <ReturnHomeHandler/>
                <h2 className="text-center">Pridėti darbą</h2>
                <ResponseContainer isLoading={loading} error={error}/>
                {!loading && (
                    <JobForm
                        form={form}
                        auth={auth}
                        classifiers={classifiers}
                        isSubmitting={isSubmitting}
                        onSubmit={this.onSubmit}
                        onChange={this.onFieldChange}
                        onAddArrayItem={this.onAddArrayItem}
                        onDeleteArrayItem={this.onDeleteArrayItem}
                        onUserRemove={this.onDeleteArrayItem('users')}
                        onDescriptionsChange={this.onDescriptionsChange}
                    />
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: getAuthState(state),
});

AddJob.propTypes = {
    auth: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(AddJob);
