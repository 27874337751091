export const validatePhoneNumber = (phoneNumber, errorField) => {
    if (isNaN(phoneNumber)) {
        errorField.addError('Telefono numerį sudaro tik skaitmenys');
    }

    if (phoneNumber.length !== 11) {
        errorField.addError('Telefono numerio ilgis yra 11 skaitmenų');
    }

    return errorField;
}