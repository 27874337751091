import { UPDATE_SELECTED_OFF_DUTY_USERS_DATE } from "./offDutyActions";

const initialState = {
    selectedDate: new Date()
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case UPDATE_SELECTED_OFF_DUTY_USERS_DATE:
            return {
                ...state,
                selectedDate: action.payload.value
            };
        default:
            return state;
    }
};
