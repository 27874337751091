import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  font-size: 12px;
  margin-left: 20px;
  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    flex-direction: column-reverse;
    min-width: 50px;
    margin-left: 25px;
  }
`;

export const OccupancyNumber = styled.section`
  margin-right: 10px;
  white-space: nowrap;
  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    margin-right: 0;
    margin-top: 2px;
  }
  cursor: pointer;
  em {
    font-style: normal;
    font-weight: 500;
  }
`;

export const IconWrapper = styled.span`
  cursor: pointer;
  white-space: nowrap;
`;
