import axios from 'axios';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER } from "./authReducer";
import { STORAGE_TOKEN_KEY } from "../store";
import { User } from "./authModel";

export function loginAction(data) {
    return (dispatch) => axios.post(`${process.env.REACT_APP_API_URL}/login`, data).then((response) => {
        const { authorization } = response.headers;
        localStorage.setItem(STORAGE_TOKEN_KEY, authorization);
        dispatch(setCurrentUser(new User(authorization)));
    });
}

export function logoutAction() {
    return (dispatch) => {
        localStorage.removeItem(STORAGE_TOKEN_KEY);
        dispatch(clearCurrentUser());
    };
}

export const setCurrentUser = (user) => {
    return {
        type: SET_CURRENT_USER,
        payload: user,
    };
}

export const clearCurrentUser = () => {
    return {
        type: CLEAR_CURRENT_USER
    };
}