import React from 'react';
import PropTypes from 'prop-types';
import "./DMChatAvatar.scss"

const DMChatAvatar = ({userName}) => {

    const getInitials = (name) => {
        const fullName = name.split(' ');
        const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
        return initials.toUpperCase();
    }

    return (
        <div className={"dm-chat-avatar"}>
            <span className={"dm-chat-avatar__initials"}>{getInitials(userName)}</span>
        </div>
    );
};

DMChatAvatar.propTypes = {
    userName: PropTypes.string.isRequired,
};

export default DMChatAvatar;
