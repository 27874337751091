import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { userCellFormatter } from "../../../utilities/TableHelper";

export default ({ rows }) => {
    const getColumns = () => [
        {
            text: 'Vardas',
            dataField: 'name',
            sort: true,
            filter: textFilter({
                placeholder: 'Ieškoti',
            }),
            formatter: (cell, row) => userCellFormatter(row)
        },
        {
            text: 'Tel. Nr.',
            dataField: 'phone',
            sort: true
        },
        {
            text: 'El. paštas',
            dataField: 'email',
            sort: true
        },
        {
            text: 'Pareigos',
            dataField: 'duty',
            sort: true
        }
    ];

    return (
        <BootstrapTable
            keyField="id"
            data={rows}
            columns={getColumns()}
            bootstrap4
            pagination={paginationFactory()}
            filter={filterFactory()}
        />);
}