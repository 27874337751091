import React, { forwardRef } from "react";
import moment from "moment";
import { Avatar, Body, Header, SenderName, TimeSent, Wrapper } from "./MessageListItem.style";
import TextMessageDisplay from "./display/TextMessageDisplay";

const timeFormatter = date => moment(date).format(timeFormat(date));

const timeFormat = date => isToday(date)
    ? "HH:mm"
    : "MM-DD HH:mm";

const isToday = date => moment(date).isSame(new Date(), 'd');

export default forwardRef(({ message, avatar }, ref) => (
    <Wrapper>
        <Avatar>{avatar}</Avatar>
        <Body ref={ref}>
            <Header>
                <SenderName>{message.sender.fullName}</SenderName>
                <TimeSent>
                    {(timeFormatter(message.createdAt))}
                </TimeSent>
            </Header>
            <TextMessageDisplay message={message}/>
        </Body>
    </Wrapper>
));