import React from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';


class SingleFieldModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      value: '',
    });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { value } = this.state;
    onSubmit(value);
  }

  render() {
    const {
      show, onClose, title, label,
    } = this.props;

    const { value } = this.state;
    return (
      <Modal show={show} onHide={() => onClose()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Form.Control type="string" value={value} onChange={this.handleChange} required />
            </Form.Group>
            <Button type="submit" variant="success">Saugoti</Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

SingleFieldModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SingleFieldModal;
