import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { loginAction } from '../../redux/auth/authActions';
import './LoginComponent.css';
import './LoginComponent.scss';
import { isUserLoggedIn } from './AuthenticationService';
import { handleError } from '../../utilities/ErrorHandler';
import ResponseContainer from '../shared/containers/ResponseContainer';
import InstallPrompter from "../install/InstallPrompter";
import {Helmet} from "react-helmet";

class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      error: '',
      success: '',
      isLoading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    if (isUserLoggedIn()) {
      history.push('/jobs');
    }
  }

  onError(error) {
    this.setState({
      error,
      isLoading: false,
      success: '',
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;

    this.setState({
      isLoading: true,
    });
    // eslint-disable-next-line no-shadow
    const { loginAction } = this.props;
    loginAction({ username, password }).then(() => {
      this.setState({
        success: 'OK',
      });
      // we need to reload the page to reload router
      window.location.reload();
    }).catch((err) => {
      const error = {
          ...err,
          response: {
            ...err.response,
            data: {
              ...err.response.data,
              error: 'Neteisingi prisijungimo duomenys.'
            }
          }
        };
      handleError(error, this.onError);
    });
  }

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
    );
  }

  render() {
    const {
      error, success, username, password, isLoading,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover" />
        </Helmet>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="main text-center h-100">
            <form className="form-signin" onSubmit={(e) => this.onSubmit(e)}>
              <div className={"pwa-install__position"}>
                <InstallPrompter />
              </div>
              <h1 className="h3 mb-3 font-weight-normal">Prisijunkite</h1>

              <ResponseContainer isLoading={isLoading} error={error} success={success} />

              <label htmlFor="inputEmail" className="sr-only">El. paštas</label>
              <input type="email" name="username" value={username} onChange={this.handleChange} className="form-control" autoComplete="email" placeholder="El. paštas" required autoFocus />
              <label htmlFor="inputPassword" className="sr-only">Slaptažodis</label>
              <input type="password" name="password" value={password} onChange={this.handleChange} className="form-control" autoComplete="current-password" placeholder="Slaptažodis" required />
              <div className="pass-reset-link"><a href="/reset-pass">Pamiršau slaptažodį</a></div>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Prisijungti
              </Button>
              <p className="mt-2 mb-3 text-muted">
                &copy;
                {new Date().getFullYear()}
              </p>
            </form>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(null, { loginAction })(LoginComponent);

LoginComponent.propTypes = {
  history: PropTypes.object.isRequired,
  loginAction: PropTypes.func.isRequired,
};
