import useInstallReminder from "./useInstallReminder";
import {isIOS} from "react-device-detect";

const iosInstallPromptedAt = 'iosInstallPromptedAt';

const useIosInstallPrompt = () => {
    const [userShouldBeRemindedToInstall, handleUserInteractedWithPrompt] = useInstallReminder(iosInstallPromptedAt);

    return [isIOS && userShouldBeRemindedToInstall, handleUserInteractedWithPrompt];
};
export default useIosInstallPrompt;