import DatePicker from 'react-datepicker';
import lt from 'date-fns/locale/lt';
import * as React from 'react';
export function datePickerWidget(props, period, onChange, highlightDates) {
  return (
        <DatePicker
            onChange={(date) => onChange(date)}
            dateFormat="yyyy-MM-dd"
            value={period}
            required={props.required}
            locale={lt}
            highlightDates={highlightDates}
        />
  );
}

export function checkboxWidget(props, value = false, onChange) {
  return <div>
      <label htmlFor={props.id} className={"mr-2"}>{props.label}</label>
      <input type="checkbox" onChange={(e)=> onChange(e.currentTarget.checked)} defaultChecked={value} id={props.id} />
  </div>
}

export function customSelectComponent(props) {
    return (
        <select>
            {props.value.map((item, index) => (
                <option key={index} id="custom-select">
                    {item}
                </option>
            ))}
        </select>
    );
};
