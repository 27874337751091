import axios from "axios";

export function unauthorizedInterceptor() {
    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 403) {
            window.location.href = '/logout';
        }
        return Promise.reject(error);
    });
}