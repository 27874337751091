import * as React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import ConfirmDialog from '../shared/modals/ConfirmDialog';
import { headerFormatter } from '../../utilities/TableHelper';
import '../shared/css/big-table.css';

class CustomerTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: null,
    };
  }

  componentDidMount() {
    const { customers } = this.props;
    this.formatRows(customers);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { customers } = this.props;
    if (prevProps.customers !== customers) {
      this.formatRows(customers);
    }
  }

  getColumns() {
    const { handleDelete, handleContactPersonClick } = this.props;
    return [
      {
        text: 'Klientas',
        dataField: 'name',
        sort: true,
        headerFormatter,
        filter: textFilter({
          placeholder: 'Ieškoti',
        }),
        formatter: (cell, row) => (
          <NavLink to={`/administration/customer/${row.id}`}><Button variant="outline-primary" size="sm">{row.name}</Button></NavLink>
        ),
      },
      {
        text: 'El. paštas',
        dataField: 'email',
        sort: true,
        headerFormatter,
        filter: textFilter({
          placeholder: 'Ieškoti',
        }),
      },
      {
        text: 'Kodas',
        dataField: 'code',
        sort: true,
        headerFormatter,
        filter: textFilter({
          placeholder: 'Ieškoti',
        }),
      },
      {
        text: 'PVM mokėtojo kodas',
        dataField: 'vat',
        sort: true,
        headerFormatter,
        filter: textFilter({
          placeholder: 'Ieškoti',
        }),
      },
      {
        text: 'Adresas',
        dataField: 'address',
        sort: true,
        headerFormatter,
        filter: textFilter({
          placeholder: 'Ieškoti',
        }),
      },
      {
        text: 'Tel. nr',
        dataField: 'phone',
        sort: true,
        headerFormatter,
      },
      {
        text: 'Tipas',
        dataField: 'privateCustomer',
        sort: true,
        headerFormatter,
        formatter: (cell) => {
          if (cell) {
            return 'Privatus asmuo';
          }
          return 'Juridinis asmuo';
        },
      },
      {
        text: '',
        dataField: 'customerPersons',
        sort: true,
        headerFormatter,
        formatter: (cell, row) => {
          const modalData = {
            customerName: row.name,
            data: cell,
          };
          return (
            <Button variant="info" size="sm" onClick={() => handleContactPersonClick(modalData)} disabled={cell.length === 0}>Žiūrėti kontaktinius asmenis</Button>
          );
        },
      },
      {
        text: '',
        dataField: 'id',
        sort: false,
        headerFormatter,
        formatter: (cell, row) => (
          <Button
            variant="danger"
            size="sm"
            onClick={
              () => ConfirmDialog({ text: `Ar tikrai norite ištrinti ${row.name}?`, onConfirm: () => handleDelete(row.id) })
            }
          >
            Trinti
          </Button>
        ),
      },
    ];
  }

  formatRows(customers) {
    const rows = customers.map((row) => ({
      id: row.id,
      name: row.name,
      email: row.email,
      vat: row.vat,
      code: row.code,
      address: row.address,
      phone: row.phone,
      privateCustomer: row.privateCustomer,
      customerPersons: row.customerPersons,
    }));

    this.setState({
      rows,
    });
  }

  render() {
    const { rows } = this.state;
    return (
      <>
        { rows
        && (
          <BootstrapTable
            keyField="id"
            data={rows}
            columns={this.getColumns()}
            filter={filterFactory()}
            classes="big-table"
            bootstrap4
            striped
            hover
            condensed
          />
        )}
      </>
    );
  }
}

export default CustomerTable;

CustomerTable.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string.isRequired,
    vat: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    privateCustomer: PropTypes.bool.isRequired,
    customerPersons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string,
        phone: PropTypes.string,
        duties: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired).isRequired,
  handleContactPersonClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
