import {useEffect, useRef} from "react";

export const usePolling = (callback, delay) => {
    const savedCallback = useRef();

    // remember latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback])

    // set up interval
    useEffect(() => {
        const tick = () => savedCallback.current();
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [callback, delay])
}

export const STANDART_POLLING_TIME = {
    TEN_SECONDS: 10000
}