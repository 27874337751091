import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalFooter, Form,
} from 'react-bootstrap';

const JobEmployeesRejectModal = ({ showModal, closeModalHandler, confirmModalHandler, handleChangeRejectReason }) => (
  <Modal show={showModal} onHide={closeModalHandler}>
    <Modal.Header closeButton>
      <Modal.Title>Atmetimo priežastis</Modal.Title>
    </Modal.Header>
    <Form onSubmit={confirmModalHandler}>
      <Modal.Body>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Control onChange={handleChangeRejectReason} as="textarea" name="rejectReason" rows="5" />
        </Form.Group>
      </Modal.Body>
      <ModalFooter>
        <Button variant="secondary" onClick={closeModalHandler}>
          Uždaryti
            </Button>
        <Button variant="danger" type="submit">
          Atmesti
            </Button>
      </ModalFooter>
    </Form>
  </Modal>
);

JobEmployeesRejectModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  confirmModalHandler: PropTypes.func.isRequired,
  handleChangeRejectReason: PropTypes.func.isRequired,
};

export default JobEmployeesRejectModal;
