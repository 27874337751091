import * as React from 'react';
import axios from 'axios';
import { Alert, Button } from 'react-bootstrap';
import UserDutiesTable from './UserDutiesTable';
import SimpleFieldModal from '../../shared/modals/SimpleFieldModal';
import { handleError } from '../../../utilities/ErrorHandler';

class UserDutiesHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      data: null,
      showModal: false,
      created: false,
    };

    this.fetchAllUserDuties = this.fetchAllUserDuties.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleAddDuty = this.handleAddDuty.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAllDutiesResponse = this.handleAllDutiesResponse.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this.fetchAllUserDuties();
  }

  onError(error) {
    this.setState({
      error,
      created: false,
    });
  }

  fetchAllUserDuties() {
    axios.get(`${process.env.REACT_APP_API_URL}/user-duty`)
      .then((response) => {
        this.handleAllDutiesResponse(response.data)
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  toggleModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      created: false,
    }));
  }

  handleAllDutiesResponse(data) {
    this.setState({
      data,
    });
  }

  handleAddDuty(fieldsValues) {
    this.toggleModal();
    axios.post(`${process.env.REACT_APP_API_URL}/user-duty`,
      { duty: fieldsValues[0],
        salary: fieldsValues[1],
        maxWorkHours: fieldsValues[2]
      })
      .then(() => {
        this.setState({
          created: true,
        });
        this.fetchAllUserDuties();
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleDelete(id) {
    axios.delete(`${process.env.REACT_APP_API_URL}/user-duty/${id}`)
        .then((response) => {
          this.handleAllDutiesResponse(response.data)
        })
        .catch((err) => {
          handleError(err, this.onError);
        })
  }

  render() {
    const {
      error, data, showModal, created,
    } = this.state;
    return (
      <>
        <div className="d-flex justify-content-between my-2">
          <Button variant="success" onClick={this.toggleModal}>Pridėti naujas pareigas</Button>
          <h2>Darbuotojai ir jų pareigos</h2>
        </div>
        <SimpleFieldModal
          show={showModal}
          onClose={this.toggleModal}
          onSubmit={this.handleAddDuty}
          modalTitle="Naujos darbuotojų pareigos"
          fieldsLabels={["Pareigų pavadinimas","Atlyginimas","Maksimalios darbo valandos"]}
        />
        { created && <Alert variant="success">Pareigos sėkmingai išsaugotos</Alert> }
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}
        { data && <UserDutiesTable data={data} handleDelete={this.handleDelete} /> }
      </>
    );
  }
}

export default UserDutiesHome;
