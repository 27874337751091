import React from 'react';
import * as PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipComponent = ({ component, tooltipText, showTooltip, placement }) => (
  showTooltip
    ? (
      <OverlayTrigger
        placement={placement}
        delay={{ show: 100, hide: 200 }}
        overlay={(
          <Tooltip id="tooltip">
            {tooltipText}
          </Tooltip>
        )}
      >
        <div className="d-flex">
          {component}
        </div>
      </OverlayTrigger>
    ) : (component)
);

TooltipComponent.propTypes = {
  component: PropTypes.node.isRequired,
  tooltipText: PropTypes.string,
  showTooltip: PropTypes.bool,
  placement: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
};

TooltipComponent.defaultProps = {
  tooltipText: '',
  showTooltip: true,
  placement: 'top',
};

export default TooltipComponent;
