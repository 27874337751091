import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaUsers } from "react-icons/all";
import { IconWrapper, OccupancyNumber, Wrapper } from "./ConversationOccupancy.style";
import {
    getCurrentConversationOccupancy,
    getMemberListDisplayState
} from "../../../../redux/chat/conversation/conversationSelectors";
import {
    conversationMembersViewDisplayed,
    conversationMembersViewHidden
} from "../../../../redux/chat/conversation/conversationActions";

export default () => {
    const { joinedCount, presentCount } = useSelector(getCurrentConversationOccupancy);
    const isDisplayed = useSelector(getMemberListDisplayState);
    const dispatch = useDispatch();

    return (
        <Wrapper
            onClick={() => {
                isDisplayed
                    ? dispatch(conversationMembersViewHidden())
                    : dispatch(conversationMembersViewDisplayed());
            }}
        >
            <OccupancyNumber>
                {`${presentCount} | ${joinedCount}`}
            </OccupancyNumber>
            <IconWrapper>
                <FaUsers/> { !isDisplayed && 'Vartotojai'}
            </IconWrapper>
        </Wrapper>
    );
};