import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Col, Form } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import UploadFile from "../../shared/file/UploadFile";
import TemporaryFile from "../../shared/file/TemporaryFile";
import { RECEIVER_GROUPS } from "../../../redux/notification/notificationModel";
import SearchableUserSelector from "../../shared/select/user/SearchableUserSelector";

export default (
    {
        isSubmitDisabled,
        receivers,
        receiverGroups,
        message,
        onChangeReceivers,
        onChangeReceiverGroups,
        onChangeMessage,
        receiverOptions,
        onSendNotification,
        onUploadFile,
        onDeleteTempFile,
        tempFiles
    }) => (
    <Form onSubmit={onSendNotification}>
        <Form.Group className="mt-4">
            <Form.Row className="align-items-center mt-4 mb-4">
                <Col sm={12} md={9} lg={9}>
                    <Form.Label>Gavėjas</Form.Label>
                    <SearchableUserSelector
                        id="notification-receivers-select"
                        labelKey="name"
                        multiple
                        onChange={onChangeReceivers}
                        options={receiverOptions}
                        placeholder="Pasirinkite pranešimo gavėjus..."
                        selected={receivers}
                    />
                </Col>
                <Col sm={12} md={3} lg={3}>
                    <Form.Label>Gavėjų grupės</Form.Label>
                    <Typeahead
                        id="notification-groups-select"
                        labelKey="label"
                        multiple
                        onChange={onChangeReceiverGroups}
                        options={RECEIVER_GROUPS}
                        placeholder="Pasirinkite pranešimo gavėjų grupės..."
                        selected={receiverGroups}
                    />
                </Col>
            </Form.Row>
            <Form.Row className="align-items-center mt-4 mb-4">
                <Col sm={12} md={9} lg={9}>
                    <Form.Label>Pranešimo tekstas</Form.Label>
                    <Form.Control
                        onChange={onChangeMessage}
                        value={message}
                        as="textarea"
                        rows="5"
                        maxLength={1000}/>
                </Col>
                <Col sm={12} md={3} lg={3} className="align-self-baseline">
                    <Form.Label>Failai</Form.Label>
                    <UploadFile onUploadFile={onUploadFile}/>
                    <div
                        className="align-items-center mt-2">
                        {tempFiles.length > 0 && tempFiles.map((file, index) => (
                            <TemporaryFile key={index} file={file} id={index} onDelete={onDeleteTempFile}/>
                        ))}
                    </div>
                </Col>
            </Form.Row>
            <div className="m-4 d-flex justify-content-center align-items-center">
                <Button
                    disabled={isSubmitDisabled}
                    className="col-lg-2"
                    variant="success"
                    type="submit">
                    Siųsti
                </Button>
            </div>
        </Form.Group>
    </Form>
);
