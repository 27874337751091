export const NAVIGATION_DISPLAYED = 'NAVIGATION_DISPLAYED';
export const NAVIGATION_HIDDEN = 'NAVIGATION_HIDDEN';

export const navigationDisplayed = () => ({
  type: NAVIGATION_DISPLAYED
});

export const navigationHidden = () => ({
  type: NAVIGATION_HIDDEN
});
