import React from "react";
import PropTypes from "prop-types";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";

import { formatRows, getColumns } from "./constants";

const style = {
    navButton: {
        'whiteSpace': 'nowrap',
        'overflow': 'hidden',
        'textOverflow': 'ellipsis',
        'width': '100%'
    }
};

const JobByDateTable = ({ data, modalHandler, changeJobStatus, role, onTableChange }) => (
    <BootstrapTable
        keyField="id"
        data={formatRows(data)}
        remote={{ filter: true }}
        noDataIndication="Pasirinktam periodui darbų nėra. Bandykite pakeisti periodą."
        columns={getColumns(modalHandler, changeJobStatus, style, role)}
        filter={filterFactory()}
        onTableChange={onTableChange}
        bootstrap4
        striped
        hover
        condensed
    />
);

JobByDateTable.propTypes = {
    modalHandler: PropTypes.func.isRequired,
    changeJobStatus: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired
};

export default JobByDateTable;
