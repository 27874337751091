import axios from "axios";
import { sendSocketMessage } from "../../socket/socketActions";
import { handleError } from "../../../utilities/ErrorHandler";
import { getCurrentConversationId } from "../conversation/conversationSelectors";
import { getMessageRequestParams } from "./messageSelectors";

export const FETCH_CONVERSATION_MESSAGES_BEGIN = 'FETCH_CONVERSATION_MESSAGES_BEGIN';
export const FETCH_CONVERSATION_MESSAGES_SUCCESS = 'FETCH_CONVERSATION_MESSAGES_SUCCESS';
export const FETCH_CONVERSATION_MESSAGES_FAILURE = 'FETCH_CONVERSATION_MESSAGES_FAILURE';

export const CLEAR_CONVERSATION_MESSAGES_STATE = 'CLEAR_CONVERSATION_MESSAGES_STATE';

export const CONVERSATION_MESSAGE_RECEIVED = 'CONVERSATION_MESSAGE_RECEIVED';

export const conversationMessageReceived = (value) => ({
    type: CONVERSATION_MESSAGE_RECEIVED,
    payload: {
        value
    }
});

export const fetchConversationMessagesBegin = () => ({
    type: FETCH_CONVERSATION_MESSAGES_BEGIN
});

export const fetchConversationMessagesSuccess = ({ content, totalPages, totalElements }) => ({
    type: FETCH_CONVERSATION_MESSAGES_SUCCESS,
    payload: { content, totalPages, totalElements }
});

export const fetchConversationMessagesFailure = error => ({
    type: FETCH_CONVERSATION_MESSAGES_FAILURE,
    payload: { error }
});

export const clearConversationMessagesState = () => ({
    type: CLEAR_CONVERSATION_MESSAGES_STATE,
});

export const sendMessage = (message) => {
    return (dispatch, getState) => {
        const conversationId = getCurrentConversationId(getState());
        const topic = `/conversations/${conversationId}/messages:send`;
        return dispatch(sendSocketMessage(topic, message));
    };
};

export const fetchConversationMessages = () => {
    return (dispatch, getState) => {
        const state = getState();
        const conversationId = getCurrentConversationId(state);
        const params = getMessageRequestParams(state);

        dispatch(fetchConversationMessagesBegin());
        return axios.get(`${process.env.REACT_APP_API_URL}/conversations/${conversationId}/messages`, { params: params })
            .then(({ data }) => dispatch(fetchConversationMessagesSuccess(data)))
            .catch(err => dispatch(fetchConversationMessagesFailure(handleError(err))));
    };
}



