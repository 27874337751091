import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Spinner} from "react-bootstrap";
import {useDropzone} from "react-dropzone";
import {ACCEPTED_FILES, MAX_FILE_SIZE, resolveFileUploadError} from "./dropzoneRestrictions";
import {DEFAULT_PAGINATION} from "./DMChat";
import {FaTimes} from "react-icons/all";

export const COMMON_FILE_TYPES = {
    PDF: "application/pdf",
    IMAGE: "image",
    VIDEO: "video",
}

const DMChatMainDialogFileModal = ({showModal, setShowModal, files, setFiles, sendMessage}) => {
    const [dropzoneError, setDropzoneError] = useState("")
    const [videoPreview, setVideoPreview] = useState(null)
    const [fileUploadLoading, setFileUploadLoading] = useState(false)

    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        maxSize: MAX_FILE_SIZE,
        accept: ACCEPTED_FILES,

        onDrop: () => setDropzoneError(""),

        onDropAccepted: acceptedFiles => {
            setFileUploadLoading(true);
            if(acceptedFiles[0].type.includes(COMMON_FILE_TYPES.VIDEO)) {
                let reader = new FileReader();

                reader.onload = e => {
                    let blobData = e.target.result;
                    setVideoPreview(blobData)
                };
                if(acceptedFiles[0]) reader.readAsDataURL(acceptedFiles[0])
            }
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setFileUploadLoading(false)
        },

        onDropRejected: rejection => {
            setFileUploadLoading(false)
            setVideoPreview(null)
            resolveFileUploadError(rejection[0]?.errors[0]?.code, setDropzoneError)
        }
    });

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
        // eslint-disable-next-line
    }, []);

    const resolveWhatPreviewToShow = (type) => {
        if(type === COMMON_FILE_TYPES.VIDEO) {
            return thumbsVideo
        }
        if(type.includes(COMMON_FILE_TYPES.IMAGE)) {
            return thumbsImage
        }
        return thumbsPdf
    }

    const thumbsImage = files.map(file => (
        <div key={file.name} className={"dm-chat-main-dialog__thumb-wrapper"}>
            <img
                src={file.preview}
                alt={"File dropzone area previewing selected file"}
                className={"dm-chat-main-dialog__thumb-file"}
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
            />
        </div>
    ));

    const thumbsVideo = files.map(file => {
        return <div className={"dm-chat-main-dialog__thumb-wrapper"}>
            <video style={{ width: '100%', maxHeight: '55vh', backgroundColor: '#000' }}
                   playsInline className={"dm-chat-main-dialog__thumb-file"}
                   autoPlay controls
            >
                {videoPreview && (<>
                    <source src={videoPreview} type="video/mp4" />
                    <source src={videoPreview} type="video/quicktime" />
                    <a href={file?.preview} target="_blank" rel="noopener noreferrer">{file.name}</a>
                    </>
                )}
            </video>
        </div>
    })

    const thumbsPdf = files.map(file => {
        return <object data={file?.preview} type={"application/pdf"} width={"100%"} height={"100%"}>
            <a href={file?.preview} target="_blank" rel="noopener noreferrer">{file.name}</a>
        </object>
    })

    const handleCancel = () => {
        setShowModal(false);
        setDropzoneError("")
        clearPreviewAndFiles()
    }

    const clearPreviewAndFiles = () => {
        setFiles([])
        setVideoPreview(null)
    }

    const sendFileMessage = () => {
        if(files.length > 0) {
            const file = files[0]
            sendMessage("", file, DEFAULT_PAGINATION.pageNr, DEFAULT_PAGINATION.size)
                .then(handleCancel)
        }
    }

    const isSubmitButtonDisabled = () => {
        return !(files.length > 0)
    }

    const modalFileBody = <>{files && files.length > 0 ? <>
            <div className={"dm-chat-main-dialog__preview-delete"}>
                <button onClick={clearPreviewAndFiles}>
                    <FaTimes size={"25px"} color={"#dc3545"} />
                </button>
            </div>
            {resolveWhatPreviewToShow(files[0].type)}
        </>
        : <div className={"dm-chat-main-dialog__dropzone-wrapper"}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {dropzoneError ? <p>{dropzoneError}</p>
                    :
                    <p>Paspauskite čia arba su pelyte užveskite faila, kad įkelti</p>}
            </div>
        </div>}</>

    const resolveModalBodyState = () => {
        return fileUploadLoading ? <Spinner variant={"primary"} animation={"border"} /> : modalFileBody
    }

    return (
        <Modal show={showModal} onHide={handleCancel} className={"dm-chat-main-dialog__modalContent"}>
            <Modal.Header className={"dm-chat-main-dialog__modalHeader"} closeButton>
                <Modal.Title>Įkelti failą</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"dm-chat-main-dialog__modalBody"}>
                {resolveModalBodyState()}
            </Modal.Body>
            <Modal.Footer className={"dm-chat-main-dialog__modalFooter"}>
                <Button className={"dm-chat-main-dialog__cancelButton"} onClick={handleCancel}>Atšaukti</Button>
                <Button className={"dm-chat-main-dialog__submitButton"} disabled={isSubmitButtonDisabled()} onClick={sendFileMessage}>Pateikti</Button>
            </Modal.Footer>
        </Modal>
    );
};

DMChatMainDialogFileModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    files: PropTypes.array,
    setFiles: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
};

export default DMChatMainDialogFileModal;
