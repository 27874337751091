import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SockJsClient from 'react-stomp';
import { getToken } from "../../auth/AuthenticationService";
import { getSocketMessage, getSocketTopics } from "../../../redux/socket/socketSelectors";
import { onSocketConnected, onSocketDisconnected, onSocketMessageReceived } from "../../../redux/socket/socketActions";

export default () => {
    const dispatch = useDispatch();
    const topics = useSelector(getSocketTopics);
    const message = useSelector(getSocketMessage);
    const ref = useRef();

    useEffect(() => {
        if (ref.current.state.connected && message.content) {
            ref.current.sendMessage(`/app/${message.topic}`, JSON.stringify(message.content));
        }
    }, [ message ]);

    const token = getToken();

    return (token &&
        <SockJsClient
            url={`${process.env.REACT_APP_API_URL}/ws?access_token=${token}`}
            topics={topics}
            onConnect={() => dispatch(onSocketConnected())}
            onDisconnect={() => dispatch(onSocketDisconnected())}
            onMessage={(message) => dispatch(onSocketMessageReceived(message))}
            ref={ref}
        />);
};
