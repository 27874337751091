import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { headerFormatter, userCellFormatter } from '../../utilities/TableHelper';
import ConfirmDialog from '../shared/modals/ConfirmDialog';
import { getRoles, isAdminRole, isUserRole } from '../auth/AuthenticationService';
import { formatPeriodUrl } from '../../utilities/DateHelper';
import FlippingBtn from '../shared/buttons/FlippingBtn';
import JobUserStatusIconBadge from './shared/JobUserStatusIconBadge';
import JobUserTlStatusIconBadge from './shared/JobUserTlStatusIconBadge';
import TooltipComponent from '../shared/tooltip/TooltipComponent';
import { JOB_ADMIN_STATUS, JOB_RESPONSIBLE_PERSON_STATUS } from "./util/JobStatus";
import { TriangleAlert } from '../shared/icons/TriangleAlert';
import { hints } from '../shared/constants/texts';
import JobOvertimeBadge from "./shared/JobOvertimeBadge";

class JobsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };
  }

  componentDidMount() {
    const { jobs } = this.props;
    this.formatRows(jobs);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { jobs, scrollPosition } = this.props;
    window.scrollTo(0, scrollPosition);
    if (prevProps.jobs !== jobs) {
      this.formatRows(jobs);
    }
  }

  getColumns() {
    const {
      changeStatus, handleDelete, handleModalClick, role, period,
    } = this.props;
    const navButtonStyle = {
        'whiteSpace': 'nowrap',
        'overflow': 'hidden',
        'textOverflow': 'ellipsis',
        'width': '100%'
    };

    return [
      {
        text: 'Statusas',
        dataField: 'completed',
        sort: true,
        hidden: !isAdminRole(role),
        headerFormatter,
        formatter: (cell, row) => (
          <FlippingBtn
            notClickedLabel="Neatliktas"
            clickValue={row.id}
            onClickCallBack={changeStatus}
            clicked={cell}
            clickedLabel="Atliktas"
          />
        ),
      },
      {
        text: 'Darbas',
        dataField: 'name',
        sort: true,
        headerFormatter,
        formatter: (cell, row, index, extraData) => this.props.isSubordinateUsersBeingDisplayed ? (
            <Button variant="outline-primary" size="sm" style={navButtonStyle} disabled={true}>
              {cell || "Nepatikslinta"}
            </Button>
        ) : (
          <NavLink to={`/jobs/${row.id}${extraData}`}>
            <Button variant="outline-primary" size="sm" style={navButtonStyle}>{cell || "Nepatikslinta"}</Button>
          </NavLink>
        ),
        formatExtraData: formatPeriodUrl(period),
        filter: textFilter({
          placeholder: 'Ieškoti',
        }),
      },
      {
        text: 'Projekto Nr',
        dataField: 'projectId',
        sort: true,
        headerFormatter,
        formatter: (cell, row, index, extraData) => this.props.isSubordinateUsersBeingDisplayed ? (
            <Button variant="outline-primary" size="sm" style={navButtonStyle} disabled={true}>{cell || "Nepatikslinta"}</Button>
        ) : (
            <NavLink to={`/jobs/${row.id}${extraData}`}>
              <Button variant="outline-primary" size="sm" style={navButtonStyle}>{cell || "Nepatikslinta"}</Button>
            </NavLink>
        ),
        formatExtraData: formatPeriodUrl(period),
        filter: textFilter({
          placeholder: 'Ieškoti',
        }),
      },
      {
        text: 'Data',
        dataField: 'date',
        sort: true,
        headerFormatter,
      },
      {
        text: 'Darbą sukūrė',
        dataField: 'createdBy',
        sort: true,
        headerFormatter,
        formatter: userCellFormatter
      },
      {
        text: 'Atsakingas asmuo',
        dataField: 'responsiblePerson',
        sort: true,
        headerFormatter,
        formatter: userCellFormatter
      },
      {
        text: 'Statusas (Vadovo / Atsakingo asmens)',
        dataField: 'status',
        headerFormatter,
        formatter: (cell, row) => {
          const isRejectedByResponsiblePerson = row.responsiblePersonStatus === JOB_RESPONSIBLE_PERSON_STATUS.REJECTED.code
              && row.latestResponsiblePersonStatus;
          const jobNote = isRejectedByResponsiblePerson ?
              row.latestResponsiblePersonStatus.note :
              JOB_RESPONSIBLE_PERSON_STATUS[row.responsiblePersonStatus].hint;
            return (
              <div className="d-flex justify-content-center">
                {isAdminRole(role) && row.updatedNotApprovedStatus && (
                  <TriangleAlert hint={hints.teamLeadApprovedRejected} />)
                }
                {row.hasOvertime && <JobOvertimeBadge />}
                <TooltipComponent component={<JobUserStatusIconBadge status={cell} />} tooltipText={JOB_ADMIN_STATUS[cell].hint} />
                <TooltipComponent component={<JobUserTlStatusIconBadge status={row.responsiblePersonStatus} />} tooltipText={jobNote ? jobNote : "Statuso priežastis nepatikslinta"} />
              </div>
            );
          }
      },
      {
        text: '',
        dataField: 'modalId',
        sort: false,
        headerFormatter,
        formatter: (cell) => (
          <div className="d-flex justify-content-center">
            <Button variant="info" size="sm" onClick={() => handleModalClick(cell)}>Žiūrėti darbą</Button>
          </div>
        ),
      },
      {
        text: '',
        dataField: 'id',
        sort: false,
        hidden: isUserRole(role),
        headerFormatter,
        formatter: (cell, row) => (
          <div className="d-flex justify-content-center">
            <Button
              variant="danger"
              size="sm"
              onClick={
                () => ConfirmDialog({ text: 'Ar tikrai norite ištrinti?', onConfirm: () => handleDelete(row.id) })
              }
            >
              Trinti
            </Button>
          </div>
        ),
      },
    ];
  }

  formatRows(jobs) {
    const rows = jobs.map((row) => ({
      id: row.id,
      modalId: row.id,
      name: row.name,
      date: row.date,
      completed: row.completed,
      createdBy: row.createdBy,
      responsiblePerson: row.responsiblePerson,
      status: row.jobStatus.status,
      responsiblePersonStatus: row.jobStatus.responsiblePersonStatus,
      latestResponsiblePersonStatus: row.latestResponsiblePersonStatus,
      updatedNotApprovedStatus: row.updatedNotApprovedStatus,
      hasOvertime: row.hasOvertime,
      projectId: row.projectId,
    }));

    this.setState({
      rows,
    });
  }

  render() {
    const { rows } = this.state;

    return (
        <BootstrapTable
            keyField="id"
            data={rows}
            remote={{ filter: true }}
            noDataIndication="Pasirinktam periodui darbų nėra. Bandykite pakeisti periodą."
            columns={this.getColumns()}
            filter={filterFactory()}
            onTableChange={this.props.onTableChange}
            bootstrap4
            striped
            hover
            condensed
        />
    );
  }
}

const mapStateToProps = ({ job }) => ({
  scrollPosition: job.jobPageScrollY,
})

export default connect(mapStateToProps)(JobsTable);

JobsTable.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
    createdBy: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
    jobUsers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        fullName: PropTypes.string.isRequired,
      }).isRequired,
      job: PropTypes.shape({
        date: PropTypes.string.isRequired,
      }).isRequired,
      hours: PropTypes.number.isRequired,
      unitMe: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired),
  }).isRequired).isRequired,
  changeStatus: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleModalClick: PropTypes.func.isRequired,
  period: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  role: PropTypes.oneOf(getRoles()).isRequired,
  onTableChange: PropTypes.func.isRequired
};
