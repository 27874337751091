import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Container } from 'react-bootstrap';
import NotificationForm from './form/NotificationForm';
import NotificationTable from './notificationTable/NotificationTable';
import ResponseContainer from '../shared/containers/ResponseContainer';
import {
    getIsLoadingNotifications,
    getIsShowAllNotifications,
    getNotificationError,
    getNotificationMessage,
    getNotificationReceiverGroups,
    getNotifications,
    getNotificationSelectUsers,
    getNotificationsReceivers,
    getNotificationSuccess,
    getNotificationTemporaryFiles,
    getUnreadNotificationCount,
    isNotificationFormSubmitDisabled
} from "../../redux/notification/notificationSelectors";
import {
    changeNotificationStatus,
    clearNotificationsState,
    downloadNotificationFile,
    fetchNotifications,
    fetchNotificationUsers,
    fetchUnreadNotificationCount,
    notificationFormDeleteTemporaryFile,
    notificationFormStoreTemporaryFile,
    sendNotification,
    toggleShowAllNotifications,
    updateNotificationFormMessage,
    updateNotificationFormReceiverGroups,
    updateNotificationFormReceivers,
    updateNotificationFormSubmitDisabled
} from "../../redux/notification/notificationActions";

const hintText = `Išsiųstą pranešimą gauna visi nurodyti gavėjai ir jį mato žemiau esančioje lentelėje šiame puslapyje. Spaudžiant “Atsakyti į pranešimą” 
                  atidaromas susirašinėjimo langas, kuriame galima atsakyti į pranešimą. Atsakymą gauna visi pranešimo gavėjai.`;

export default ({ history }) => {
    const dispatch = useDispatch();

    const isShowAllNotifications = useSelector(getIsShowAllNotifications);
    const selectUsers = useSelector(getNotificationSelectUsers);
    const notifications = useSelector(getNotifications);

    const receivers = useSelector(getNotificationsReceivers);
    const receiverGroups = useSelector(getNotificationReceiverGroups);
    const message = useSelector(getNotificationMessage);
    const tempFiles = useSelector(getNotificationTemporaryFiles);
    const unreadNotificationCount = useSelector(getUnreadNotificationCount);
    const isSubmitDisabled = useSelector(isNotificationFormSubmitDisabled);

    const isLoading = useSelector(getIsLoadingNotifications);
    const error = useSelector(getNotificationError);
    const success = useSelector(getNotificationSuccess);

    useEffect(() => {
        dispatch(clearNotificationsState());
        dispatch(fetchNotifications());
        dispatch(fetchNotificationUsers());
        dispatch(fetchUnreadNotificationCount());
    }, [ dispatch, unreadNotificationCount ]);

    useEffect(() => {
        dispatch(updateNotificationFormSubmitDisabled(!(message.length && (receivers.length || receiverGroups.length))));
    }, [ message, receivers, receiverGroups, dispatch ]);

    const onChangeReceivers = value => dispatch(updateNotificationFormReceivers(value));
    const onChangeReceiverGroups = value => dispatch(updateNotificationFormReceiverGroups(value));
    const onChangeMessage = event => dispatch(updateNotificationFormMessage(event.target.value));

    const onSendNotification = event => {
        event.preventDefault();
        dispatch(sendNotification());
    };

    const onShowAllNotifications = () => {
        dispatch(toggleShowAllNotifications());
        dispatch(fetchNotifications());
    };

    const onChangeNotificationStatus = ({ id }) => dispatch(changeNotificationStatus(id));

    const onUploadFile = file => dispatch(notificationFormStoreTemporaryFile(file));
    const onDeleteTempFile = id => dispatch(notificationFormDeleteTemporaryFile(id));
    const onDownloadFile = file => dispatch(downloadNotificationFile(file));

    return (
        <Container fluid>
            <h3>Pranešimai</h3>
            <div className="d-flex flex-row justify-content-center">
                <div className="alert alert-info">
                    {hintText}
                </div>
            </div>
            <ResponseContainer error={error} success={success} isLoading={isLoading} />
            {!isLoading && (
                <>
                    <NotificationForm
                        isSubmitDisabled={isSubmitDisabled}
                        receivers={receivers}
                        message={message}
                        receiverOptions={selectUsers}
                        receiverGroups={receiverGroups}
                        onChangeReceivers={onChangeReceivers}
                        onChangeReceiverGroups={onChangeReceiverGroups}
                        onChangeMessage={onChangeMessage}
                        onSendNotification={onSendNotification}
                        onUploadFile={onUploadFile}
                        onDeleteTempFile={onDeleteTempFile}
                        tempFiles={tempFiles}
                    />
                    <NotificationTable
                        onShowAllNotifications={onShowAllNotifications}
                        onChangeNotificationStatus={onChangeNotificationStatus}
                        onConversationClick={({ conversationId }) => history.push(`/conversations/${conversationId}`)}
                        isShowAllNotifications={isShowAllNotifications}
                        notifications={notifications}
                        onDownloadFile={onDownloadFile}
                    />
                </>
            )}
        </Container>
    );
};