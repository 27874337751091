import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import SearchableUserSelector from "../../../shared/select/user/SearchableUserSelector";
import { formatHoursValue } from "../../helpers/helper";

export default (
    {
        user,
        index,
        onChange,
        onUserRemove,
        selectableUsers,
        selectableUnitMeasures,
    }) => {
    const handleInputChange = (event) => onChange(event.target.name)(event, event.target.value);
    const handleHourChange = (event) => onChange(event.target.name)(event, formatHoursValue(event.target.value));

    return (
        <Form.Row className="align-items-center mb-4 mt-4">
            <Col md={4} sm="auto" className="mt-2 mb-2">
                <Form.Label>Darbuotojas*</Form.Label>
                <SearchableUserSelector
                    id={`user-${index}`}
                    key={user.id}
                    labelKey="name"
                    name="user"
                    onChange={value => onChange('user')(undefined, value)}
                    options={selectableUsers}
                    selected={user.user}
                    inputProps={{ required: true }}
                    disabled={user.readOnly}
                />
            </Col>
            <Col md={4} sm="auto" className="mt-2 mb-2">
                <Form.Label>Mato vnt.*</Form.Label>
                <Form.Control
                    id={`unitMeId-${index}`}
                    required
                    as="select"
                    name="unitMeId"
                    value={user.unitMeId}
                    onChange={handleInputChange}
                    disabled={user.readOnly}
                >
                    <option key="default"/>
                    {selectableUnitMeasures.map((unit) =>
                        <option
                            value={unit.id}
                            id={unit.id}
                            key={unit.id}>
                            {unit.name}
                        </option>
                    )}
                </Form.Control>
            </Col>
            <Col md={3} sm="auto" className="mt-2 mb-2">
                <Form.Label>Valandos*</Form.Label>
                <Form.Control
                    id={`hours-${index}`}
                    required
                    type="text"
                    name="hours"
                    value={user.hours}
                    onChange={handleHourChange}
                    disabled={user.readOnly}
                />
            </Col>
            <Col style={{ marginTop: '32px' }}>
                <Button
                    id={`remove-button-${index}`}
                    variant="danger"
                    onClick={() => onUserRemove(index)}
                    disabled={user.readOnly}>
                    Trinti
                </Button>
            </Col>
        </Form.Row>
    );
};