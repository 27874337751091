import * as React from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import UsersTable from './UsersTable';
import ResponseContainer from "../shared/containers/ResponseContainer";
import { handleError } from '../../utilities/ErrorHandler';

class UsersHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      error: null,
      isLoading: true,
    };

    this.handleTableResponse = this.handleTableResponse.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this.getAllUsers();
  }

  onError(error) {
    this.setState({
      error,
      isLoading: false,
    });
  }

  getAllUsers() {
    axios.get(`${process.env.REACT_APP_API_URL}/user/datatable`)
      .then((response) => {
        this.handleTableResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleTableResponse(data) {
    this.setState({
      error: null,
      data,
      isLoading: false,
    });
  }

  render() {
    const { data, error, isLoading } = this.state;
    const { history } = this.props;
    return (
      <Container fluid>
        <div className="m-2">
          <h3>Vartotojai</h3>
          <ResponseContainer isLoading={isLoading} error={error} />
          { !isLoading &&data.length > 0 && !error
            && <UsersTable data={data} history={history} isAdmin={false} />}
        </div>
      </Container>
    );
  }
}
export default UsersHome;
