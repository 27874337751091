import getUserColorByRole from "../../../../utilities/getUserColorByRole";
import * as React from "react";

export default ({ option }) => (
    <option
        style={{ color: getUserColorByRole(option.role) }}
        key={option.userId}
        value={option.userId}>
        {option.name}
    </option>
);