import * as React from "react";
import { withRouter } from "react-router-dom";
import GoBackBtn from "./GoBackBtn";

export default withRouter(({ history, className }) => (
    <GoBackBtn
        label="Grįžti"
        className={className}
        onClick={history.goBack}/>
));
