import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Container } from "react-bootstrap";
import debounce from "lodash.debounce";
import DatePicker from "react-datepicker";
import lt from "date-fns/locale/lt";

import StatList from "../../../shared/containers/StatList";
import ResponseContainer from "../../../shared/containers/ResponseContainer";
import ReturnHomeHandler from "../../ReturnHomeHandler";
import JobEmployeesModal from "../../modals/JobEmployeesModal";
import { getUserRole } from "../../../auth/AuthenticationService";
import JobsByDateTable from "./JobsByDateTable";
import JobEmployeesRejectModal from '../../modals/JobEmployeesRejectModal';
import { handleError } from '../../../../utilities/ErrorHandler';
import { JOB_ADMIN_STATUS } from '../../util/JobStatus'
import { getStatsMap } from '../../helpers/helper';
import { getAuthState } from "../../../../redux/auth/authSelectors";
import { FILTER_DEBOUNCE_TIME, toFilterRequest } from "../../../shared/table/filter";


class JobsByDateHome extends React.PureComponent {
    constructor(props) {
        super(props);
        const role = getUserRole(props.auth);

        this.state = {
            error: null,
            jobs: [],
            success: null,
            stats: new Map(),
            showModal: false,
            selectedDate: new Date(),
            selectedJobId: null,
            isLoading: true,
            role,
            showRejectModal: false,
            rejectReason: '',
            modalJobId: '',
            filterParams: []
        };

        this.fetchAllJobs = this.fetchAllJobs.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.handleJobsByDateResponse = this.handleJobsByDateResponse.bind(this);
        this.changeJobStatus = this.changeJobStatus.bind(this);
        this.modalHandler = this.modalHandler.bind(this);
        this.onError = this.onError.bind(this);
        this.toggleJobEmployeesModal = this.toggleJobEmployeesModal.bind(this);
        this.rejectModalHandler = this.rejectModalHandler.bind(this);
        this.handleChangeRejectReason = this.handleChangeRejectReason.bind(this);
        this.confirmModalHandler = this.confirmModalHandler.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    componentDidMount() {
        this.onDateChange(new Date());
    }

    onDateChange(date) {
        this.setState({
            selectedDate: date,
        }, this.fetchAllJobs);
    }

    fetchAllJobs() {
        const { selectedDate, filterParams } = this.state;
        const request = Object.assign({}, { date: selectedDate }, ...filterParams);
        return axios.post(
            `${process.env.REACT_APP_API_URL}/job/date`, request)
            .then(({ data }) => this.handleJobsByDateResponse(data))
            .catch((err) => handleError(err, this.onError));
    }

    handleJobsByDateResponse({ jobs, totalUnitCount, myTotalHours, myWorkedDays }) {
        const { stats } = this.state;
        this.setState({
            jobs: jobs.length > 0 ? jobs : [],
            isLoading: false,
            stats: jobs.length > 0 ? getStatsMap(stats, totalUnitCount, myTotalHours, myWorkedDays) : new Map()
        });
    }

    modalHandler(jobId) {
        this.toggleJobEmployeesModal();
        this.setState({ selectedJobId: jobId });
    }

    toggleJobEmployeesModal() {
        this.setState((prevState) => ({
            showModal: !prevState.showModal
        }), this.fetchAllJobs);
    }

    changeJobStatus(status, { id }) {
        if (status === JOB_ADMIN_STATUS.REJECTED.code) {
            this.setState({ modalJobId: id });
            this.rejectModalHandler();
        } else {
            this.changeStatus(id, { status })
        }
    }

    confirmModalHandler(event) {
        event.preventDefault();
        const { modalJobId, rejectReason } = this.state;
        const request = {
            status: JOB_ADMIN_STATUS.REJECTED.code,
            rejectReason,
        };
        this.changeStatus(modalJobId, request);
        this.setState((prevState) => ({
            showRejectModal: !prevState.showRejectModal,
        }));
    }

    changeStatus(jobId, request) {
        axios.put(`${process.env.REACT_APP_API_URL}/job/${jobId}/status`, request)
            .then(() => this.fetchAllJobs())
            .catch((err) => handleError(err, this.onError));
    }

    onError(error) {
        this.setState({
            error,
            success: null,
            isLoading: false,
        });
    }

    rejectModalHandler() {
        this.setState((prevState) => ({
            showRejectModal: !prevState.showRejectModal,
        }));
    }

    handleChangeRejectReason(event) {
        this.setState({ rejectReason: event.target.value });
    }

    onTableChange(type, { filters }) {
        const fetch = debounce(() => {
            const filterParams = Object.keys(filters).map(key => toFilterRequest(key, filters[key]));
            this.setState({
                filterParams
            }, this.fetchAllJobs);
        }, FILTER_DEBOUNCE_TIME);
        fetch();
    }

    render() {
        const {
            error,
            jobs,
            success,
            selectedDate,
            stats,
            showModal,
            selectedJobId,
            role,
            isLoading,
            showRejectModal,
        } = this.state;

        return (
            <Container fluid>
                <div className="m-2">
                    <h3>Darbuotojų darbai pagal datą</h3>
                    <div className="d-flex flex-row justify-content-left">
                        <div className="mb-2">
                            <ReturnHomeHandler/>
                        </div>
                        <div className="mb-2  ml-5">
                            <p className="mb-0">Data</p>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => this.onDateChange(date)}
                                dateFormat="yyyy-MM-dd"
                                locale={lt}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                        <StatList elements={stats}/>
                    </div>
                    <div className="pt-2">
                        <ResponseContainer
                            error={error}
                            success={success}
                            isLoading={isLoading}
                        />
                        {!isLoading && jobs.length > 0 && (
                            <JobsByDateTable
                                data={jobs}
                                modalHandler={this.modalHandler}
                                changeJobStatus={this.changeJobStatus}
                                onTableChange={this.onTableChange}
                                selectedDate={selectedDate}
                                role={role}
                            />
                        )}
                    </div>
                </div>
                <JobEmployeesModal
                    toggleJobEmployeesModal={this.toggleJobEmployeesModal}
                    showModal={showModal}
                    jobId={selectedJobId}
                    role={role}
                />
                <JobEmployeesRejectModal
                    showModal={showRejectModal}
                    closeModalHandler={this.rejectModalHandler}
                    confirmModalHandler={this.confirmModalHandler}
                    handleChangeRejectReason={this.handleChangeRejectReason}
                />
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: getAuthState(state),
    };
}

JobsByDateHome.propTypes = {
    auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(JobsByDateHome);
