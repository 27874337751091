import React, { useEffect, useRef } from "react";
import { Container, TextArea, Wrapper } from "./TextMessageEditor.style";

const autoExpand = (el) => {
    setTimeout(function () {
        el.style.cssText = "height:auto; padding:0";
        el.style.cssText = "height:" + el.scrollHeight + "px";
    }, 0);
};

const newTextDraft = (draft, newText) => {
    return draft.text === newText ? draft : {
        text: newText
    };
};

export default (
    {
        message,
        sendDraft,
        updateDraft
    }) => {
    const text = message.text;
    const textareaRef = useRef(document.createElement("textarea"));

    const textChanged = (e) => {
        updateDraft(newTextDraft(message, e.target.value));
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            const draft = newTextDraft(message, text);
            if (draft.text !== '') {
                sendDraft(draft);
            }
            e.preventDefault();
        }
        autoExpand(e.target);
    };

    useEffect(() => {
        autoExpand(textareaRef.current);
    }, [ textareaRef, text ]);

    return (
        <Wrapper>
            <Container>
                <TextArea
                    ref={textareaRef}
                    rows={1}
                    maxLength={1000}
                    value={text}
                    onChange={textChanged}
                    onKeyPress={handleKeyPress}
                    placeholder="Įveskite žinutę"
                />
            </Container>
        </Wrapper>
    );
};