import {
    SOCKET_CONNECTED,
    SOCKET_DISCONNECTED,
    SOCKET_MESSAGE_SENT,
    SOCKET_TOPIC_DISCARDED,
    SOCKET_TOPIC_UPDATED
} from "./socketActions";

const initialState = {
    isConnected: false,
    message: {
        topic: undefined,
        content: undefined
    },
    topics: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SOCKET_CONNECTED:
            return {
                ...state,
                isConnected: true
            }
        case SOCKET_DISCONNECTED:
            return initialState;
        case SOCKET_TOPIC_UPDATED: {
            return {
                ...state,
                topics: [ ...state.topics, ...[ action.payload.value ] ]
            };
        }
        case SOCKET_TOPIC_DISCARDED: {
            const topics = [...state.topics];
            const index = topics.indexOf(action.payload.value);
            if (!index) {
                topics.splice(index, 1);
            }
            return {
                ...state,
                topics
            };
        }
        case SOCKET_MESSAGE_SENT:
            return {
                ...state,
                message: action.payload.value
            }
        default:
            return state;
    }
};