import * as React from 'react';
import axios from 'axios';
import { Container, } from 'react-bootstrap';
import HistoryTable from './HistoryTable';
import ResponseContainer from "../shared/containers/ResponseContainer";
import { handleError } from '../../utilities/ErrorHandler';

class HistoryHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      records: null,
      isLoading: true,
    };

    this.fetchRecords = this.fetchRecords.bind(this);
  }

  componentDidMount() {
    this.fetchRecords();
  }

  onError(error) {
    this.setState({
      error,
      isLoading: false,
    });
  }

  fetchRecords() {
    axios.get(`${process.env.REACT_APP_API_URL}/history`)
      .then(({ data }) => this.handleRecordsResponse(data))
      .catch((err) => handleError(err, this.onError));
  }

  handleRecordsResponse(data) {
    this.setState({
      records: data,
      error: null,
      isLoading: false,
    });
  }

  render() {
    const { records, error, isLoading } = this.state;
    return (
      <Container fluid>
        <div className="m-2">
          <h3>Istorija</h3>
          <ResponseContainer isLoading={isLoading} error={error} />
          {records && !isLoading && <HistoryTable records={records} />}
        </div>
      </Container>
    );
  }
}

export default HistoryHome;
