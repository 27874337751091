import * as React from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import JobDescriptionTable from './JobDescriptionTable';
import SingleFieldModal from '../../shared/modals/SingleFieldModal';
import ResponseContainer from '../../shared/containers/ResponseContainer';
import { handleError } from '../../../utilities/ErrorHandler';

class JobDescriptionHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      jobDescs: null,
      showModal: false,
      success: null,
      isLoading: false,
    };

    this.fetchAllJobDescs = this.fetchAllJobDescs.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleAddJobDesc = this.handleAddJobDesc.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this.fetchAllJobDescs();
  }

  onError(error) {
    this.setState({
      error,
      success: null,
      isLoading: false,
    });
  }

  fetchAllJobDescs() {
    axios.get(`${process.env.REACT_APP_API_URL}/job-description`)
      .then((response) => {
        this.handleAllJobDescsResponse(response.data)
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  toggleModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      success: null,
      isLoading: !prevState.isLoading,
    }));
  }

  handleAllJobDescsResponse(jobDescs) {
    this.setState({
      jobDescs: jobDescs.jobDescriptions,
    });
  }

  handleAddJobDesc(jobDesc) {
    this.toggleModal();
    axios.post(`${process.env.REACT_APP_API_URL}/job-description`, { name: jobDesc })
      .then(() => {
        this.setState({
          isLoading: false,
          success: 'Išsaugota sėkmingai.',
        });
        this.fetchAllJobDescs();
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleDelete(id) {
    this.setState({ isLoading: true, });

    axios.delete(`${process.env.REACT_APP_API_URL}/job-description/${id}`)
        .then((response) => {
          this.setState({
            isLoading: false,
            success: 'Ištrinta sėkmingai.',
          });
          this.handleAllJobDescsResponse(response.data)
        })
        .catch((err) => {
          handleError(err, this.onError);
        });
  }

  render() {
    const {
      error, jobDescs, showModal, success, isLoading,
    } = this.state;
    return (
      <>
        <div className="d-flex justify-content-between my-2">
          <Button variant="success" onClick={this.toggleModal}>Pridėti naują darbo aprašymą</Button>
          <h2>Darbo aprašymas</h2>
        </div>
        <SingleFieldModal
          show={showModal}
          onClose={this.toggleModal}
          onSubmit={this.handleAddJobDesc}
          title="Pridėti naują darbo aprašymą"
          label="Darbo aprašymai"
        />

        <ResponseContainer isLoading={isLoading} error={error} success={success} />

        { jobDescs && <JobDescriptionTable data={jobDescs} handleDelete={this.handleDelete} /> }
      </>
    );
  }
}

export default JobDescriptionHome;
