import React from "react";
import getUniqueColor from "../../../../../utilities/getUniqueColor";
import { Wrapper } from "./UserInitialsAvatar.style";

const colorSet = [
    "#FFAB91",
    "#80DEEA",
    "#EF9A9A",
    "#CE93D8",
    "#AED581",
    "#9FA7DF",
    "#BCAAA4",
    "#FFE082",
    "#F48FB1",
    "#DCE775"
];

export default ({
                    name,
                    userId,
                    size
                }) => {
    const initials = name.match(/\b\w/g) || [];
    const uniqueColor = getUniqueColor(userId, colorSet);
    return (
        <Wrapper size={size} color={uniqueColor}>
            {initials}
        </Wrapper>
    );
};