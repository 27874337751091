import { createSelector } from "reselect";
import { isOnline } from "./conversationModel";

const getCurrentConversationState = ({ chat }) => chat.conversation;

export const getCurrentConversation = createSelector([ getCurrentConversationState ],
    ({ conversation }) => conversation);

export const getCurrentConversationIsLoading = createSelector([ getCurrentConversationState ],
    ({ loading }) => loading);

export const getCurrentConversationError = createSelector([ getCurrentConversationState ],
    ({ error }) => error);

export const getCurrentConversationId = createSelector([ getCurrentConversationState ],
    ({ conversation }) => conversation.id);

export const getMemberListDisplayState = createSelector([ getCurrentConversationState ],
    ({ displayMemberView }) => displayMemberView);

export const getCurrentConversationMembers = createSelector([ getCurrentConversation ],
    ({ members }) => members);

export const getCurrentConversationOccupancy = createSelector([ getCurrentConversation ],
    ({ members }) => ({
        joinedCount: members.length,
        presentCount: members.filter(isOnline).length
    }));
