import {browserName, osName} from "react-device-detect";

export const InstallationType = {
    NOT_SUPPORTED: 'Not supported',
    MANUAL: 'Manual install',
    AUTO: 'Auto install',
}

const NOT_SUPPORTED = InstallationType.NOT_SUPPORTED  // PWA is not supported
const MANUAL = InstallationType.MANUAL                // PWA installation needs guided instructions
const AUTO = InstallationType.AUTO                    // PWA can be installed from within the app

const supportedInstallTypeByPlatform = {
    "Mac OS": {
        Chrome:         AUTO,
        EdgeChromium:   AUTO,

        Opera:          NOT_SUPPORTED,
        Firefox:        NOT_SUPPORTED,
        Safari:         NOT_SUPPORTED,
    },
    "Windows": {
        Chrome:         AUTO,
        EdgeChromium:   AUTO,

        Opera:          NOT_SUPPORTED,
        Firefox:        NOT_SUPPORTED,
    },
    "iOS": {
        MobileSafari:   MANUAL,

        MobileChrome:   NOT_SUPPORTED,
        Opera:          NOT_SUPPORTED,
        Firefox:        NOT_SUPPORTED,
        EdgeChromium:   NOT_SUPPORTED,
    },
    "Android": {
        Chrome:         AUTO,

        Opera:          MANUAL,
        Firefox:        MANUAL,
        Edge:           MANUAL,
    }
}

export const getInstallationType = () => {
    const supportedBrowsers = supportedInstallTypeByPlatform[osName]
    if (!!supportedBrowsers) {
        return supportedBrowsers[browserName]
    } else return undefined;
}