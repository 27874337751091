import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 12px 90px 12px 24px;

  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    padding: 4px 16px;
  }

  :hover {
    background-color: #eaeef3;
  }

  :first-child {
    margin-top: auto;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const Header = styled.div`
  margin: 5px 0;
`;

export const Avatar = styled.div`
  width: 36px;
  height: 36px;
`;

export const SenderName = styled.span`
  color: #585858;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
`;

export const TimeSent = styled.span`
  font-size: 12px;
  font-weight: 300;
  margin-left: 10px;
`;
