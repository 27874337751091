import { get } from "lodash";

export function handleError(err, handler) {
    let error = errorResolver(err);
    if (handler) {
        handler(error);
    } else {
        return error;
    }
}

const errorResolver = (err) => {
    const errors = get(err, 'response.data.errors', []);
    if (errors.length > 0) {
        return errors[0].defaultMessage || errors[0];
    } else if (err?.response?.data?.message) {
        return err.response.data.message;
    } else if (err?.response?.data?.error) {
        return err.response.data.error;
    } else if (err.message) {
        return err.message;
    }
    return "Klaidos priezastis nepatikslinta";
}

export const isJsonParsable = string => {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
}

export const handleBlobTypeErrorMessage = async (error) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function() {
            if (isJsonParsable(this.result)) {
                resolve(JSON.parse(this.result));
            } else {
                reject("unable to download");
            }
        };
        reader.readAsText(error?.response?.data);
    });
}
