import React, { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import ResponseContainer from '../shared/containers/ResponseContainer';
import './LoginComponent.css';
import { handleError } from '../../utilities/ErrorHandler';

class ChangeForgottenPassComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPassword1: '',
            newPassword2: '',
            token: props.match.params.token,
            error: '',
            success: '',
            isLoading: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onError = this.onError.bind(this);
    }

      onSubmit(e) {
        e.preventDefault();
        const { newPassword1, newPassword2, token } = this.state;

        this.setState({
          isLoading: true,
        });

        const data = { newPassword1, newPassword2, token };

        axios.post(`${process.env.REACT_APP_API_URL}/user/change-forgotten-pass`, data)
          .then((response) => {
            this.setState({
              success: response.data.message,
              error: '',
              isLoading: false,
              newPassword1: '',
              newPassword2: '',
            });
          })
          .catch((err) => {
            handleError(err, this.onError);
          });
      }

      handleChange(event) {
          this.setState(
            {
              [event.target.name]: event.target.value,
            },
          );
        }

      onError(error) {
        this.setState({
          error,
          isLoading: false,
          success: '',
        });
      }


    render() {
    const { isLoading, newPassword1, newPassword2, error, success } = this.state;
        return (
            <React.Fragment>
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <div className="main text-center">
                    <form className="form-reset-pass" onSubmit={(e) => this.onSubmit(e)}>
                      <ResponseContainer isLoading={isLoading} error={error} success={success} />

                      <label htmlFor="inputNewPassword1" className="sr-only">Naujas slaptažodis</label>
                      <input type="password" name="newPassword1" value={newPassword1} onChange={this.handleChange} className="form-control" placeholder="Naujas slaptažodis" required autoFocus />
                      <label htmlFor="inputEmail" className="sr-only">Pakartokite naują slaptažodį</label>
                      <input type="password" name="newPassword2" value={newPassword2} onChange={this.handleChange} className="form-control" placeholder="Pakartokite slaptažodį" required autoFocus />
                        <Button className="mt-2" variant="primary" type="submit" disabled={isLoading}>
                        { isLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        Atnaujinti
                      </Button>
                      <div><a href="/login">Į prisijungimo langą</a></div>
                      <p className="mt-2 mb-3 text-muted">
                        &copy;
                        {new Date().getFullYear()}
                      </p>
                    </form>
                  </div>
                )}
            </React.Fragment>
        );
    }
}

export default ChangeForgottenPassComponent;