import React, {Component} from 'react'
import {connect} from 'react-redux'

class NotFoundComponent extends Component {
    render() {
        const errorTemplate = {
            padding: "40px 15px",
            display: "inline"
        };
        const errorActions = {
            marginTop: "15px",
            marginBottom: "15px"
        };

        return (
            <div className="main text-center" style={errorTemplate}>
                <h1 style={{fontSize:"60pt"}}>Oops!</h1>
                <h2 className="h3 mb-3 font-weight-normal" style={errorActions}>404 The requested page wasn't
                    found</h2>
                <div>
                    <a href="/login" className="btn btn-primary btn-lg">Take Me Home</a>
                </div>
            </div>
        );
    }
}

export default connect()(NotFoundComponent)