import * as React from 'react';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import lt from 'date-fns/locale/lt';
import * as PropTypes from 'prop-types';

class PeriodHandler extends React.Component {
  constructor(props) {
    super(props);

    this.handleStartOfYearPeriodClick = this.handleStartOfYearPeriodClick.bind(this);
    this.handleEndOfYearPeriodClick = this.handleEndOfYearPeriodClick.bind(this);
    this.handleStartOfMonthPeriodClick = this.handleStartOfMonthPeriodClick.bind(this);
    this.handleEndOfMonthPeriodClick = this.handleEndOfMonthPeriodClick.bind(this);
  }


  handleStartOfYearPeriodClick() {
    const { onPeriodChange } = this.props;
    const startOfYear = new Date(new Date().getFullYear(), 0, 1);
    onPeriodChange(startOfYear, 0);
  }

  handleEndOfYearPeriodClick() {
    const { onPeriodChange } = this.props;
    const endOfYear = new Date(new Date().getFullYear(), 11, 31);
    onPeriodChange(endOfYear, 1);
  }

  handleStartOfMonthPeriodClick() {
    const { onPeriodChange } = this.props;
    const date = new Date();
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    onPeriodChange(startOfMonth, 0);
  }

  handleEndOfMonthPeriodClick() {
    const { onPeriodChange } = this.props;
    const date = new Date();
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    onPeriodChange(endOfMonth, 1);
  }

  render() {
    const { onPeriodChange, period } = this.props;
    return (
      <div className="m-auto">
        <div className="d-flex align-items-baseline">
          <Button variant="secondary" className="m-1" onClick={this.handleStartOfYearPeriodClick}>Nuo metų pradžios</Button>
          <Button variant="secondary" className="m-1" onClick={this.handleStartOfMonthPeriodClick}>Nuo mėnesio pradžios</Button>
          <DatePicker
            selected={period[0]}
            onChange={(date) => onPeriodChange(date, 0)}
            selectsStart
            startDate={period[0]}
            endDate={period[1]}
            dateFormat="yyyy-MM-dd"
            locale={lt}
          />
          <DatePicker
            selected={period[1]}
            onChange={(date) => onPeriodChange(date, 1)}
            selectsEnd
            startDate={period[0]}
            endDate={period[1]}
            minDate={period[0]}
            dateFormat="yyyy-MM-dd"
            locale={lt}
          />
          <Button variant="secondary" className="m-1" onClick={this.handleEndOfMonthPeriodClick}>Iki mėnesio pabaigos</Button>
          <Button variant="secondary" className="m-1" onClick={this.handleEndOfYearPeriodClick}>Iki metų pabaigos</Button>
        </div>
      </div>
    );
  }
}

export default PeriodHandler;

PeriodHandler.propTypes = {
  onPeriodChange: PropTypes.func.isRequired,
  period: PropTypes.array.isRequired,
};
