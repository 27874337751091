import axios from "axios";
import { fetchConversationMessages } from "../messages/messageActions";
import { handleError } from "../../../utilities/ErrorHandler";

export const FETCH_CONVERSATION_BEGIN = 'FETCH_CONVERSATION_BEGIN';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_FAILURE = 'FETCH_CONVERSATION_FAILURE';

export const CLEAR_CONVERSATION_STATE = 'CLEAR_CONVERSATION_STATE';

export const CONVERSATION_MEMBER_STATUS_UPDATED = 'CONVERSATION_MEMBER_STATUS_UPDATED';

export const CONVERSATION_MEMBERS_VIEW_HIDDEN = 'CONVERSATION_MEMBERS_VIEW_HIDDEN';
export const CONVERSATION_MEMBERS_VIEW_DISPLAYED = 'CONVERSATION_MEMBERS_VIEW_DISPLAYED';

export const conversationMemberStatusUpdated = (value) => ({
    type: CONVERSATION_MEMBER_STATUS_UPDATED,
    payload: {
        value
    }
});

export const fetchConversationBegin = () => ({
    type: FETCH_CONVERSATION_BEGIN
});

export const fetchConversationSuccess = conversation => ({
    type: FETCH_CONVERSATION_SUCCESS,
    payload: { conversation }
});

export const fetchConversationFailure = error => ({
    type: FETCH_CONVERSATION_FAILURE,
    payload: { error }
});

export const clearConversationState = () => ({
    type: CLEAR_CONVERSATION_STATE
});

export const conversationMembersViewHidden = () => ({
    type: CONVERSATION_MEMBERS_VIEW_HIDDEN
});

export const conversationMembersViewDisplayed = () => ({
    type: CONVERSATION_MEMBERS_VIEW_DISPLAYED
});

export const fetchConversation = conversationId => {
    return (dispatch) => {
        dispatch(fetchConversationBegin());
        return axios.get(`${process.env.REACT_APP_API_URL}/conversations/${conversationId}`)
            .then(({ data }) => dispatch(fetchConversationSuccess(data)))
            .then(() => dispatch(fetchConversationMessages()))
            .catch(err => dispatch(fetchConversationFailure(handleError(err))));
    };
}


