import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Col, Form } from 'react-bootstrap';
import lt from 'date-fns/locale/lt';
import DatePicker from "react-datepicker";
import moment from "moment";
import '../../schemas/DatePickerTemplate.css';
import { getRoles, isAdminRole, isPrivilegedRole } from "../auth/AuthenticationService";
import SearchableUserSelector from "../shared/select/user/SearchableUserSelector";

export const INITIAL_FORM_STATE = {
    user: [],
    responsiblePerson: [],
    start: moment().toDate(),
    end: moment().add(7, 'days').toDate(),
    description: ''
}

const VacationForm = ({
                          onSubmit,
                          onChange,
                          onPeriodChange,
                          form,
                          responsibleUsers,
                          selectableUsers,
                          role,
                          isMyVacation
                      }) =>
    (
        <fieldset>
            <Form onSubmit={onSubmit} disabled={true}>
                <Form.Row className="align-items-center mt-4 mb-4">
                    {isPrivilegedRole(role) && !isMyVacation &&
                    (<Col md={4} sm={12} lg={4} className="mt-2 mb-2">
                            <Form.Label>Darbuotojas*</Form.Label>
                            <SearchableUserSelector
                                id="user"
                                name="user"
                                labelKey="name"
                                onChange={(value) => onChange(value, 'user')}
                                options={selectableUsers}
                                selected={form.user}
                                inputProps={{ required: true }}
                            />
                        </Col>
                    )}
                    {!isAdminRole(role) && isMyVacation && (
                        <Col md={4} sm={12} lg={4} className="mt-2 mb-2">
                            <Form.Label>Atsakingas asmuo*</Form.Label>
                            <SearchableUserSelector
                                id="responsiblePerson"
                                name="responsiblePerson"
                                labelKey="name"
                                onChange={(value) => onChange(value, 'responsiblePerson')}
                                options={responsibleUsers}
                                selected={form.responsiblePerson}
                                inputProps={{ required: true }}
                            />
                        </Col>
                    )}
                    <Col md={3} sm={12} lg={3} className="mt-2 mb-2 datepicker-wrapper">
                        <Form.Label>Nuo*</Form.Label>
                        <DatePicker
                            className="block"
                            dateFormat="yyyy-MM-dd"
                            required
                            selected={form.start}
                            minDate={moment().toDate()}
                            locale={lt}
                            onChange={value => onPeriodChange(value, 'start')}
                        />
                    </Col>
                    <Col md={3} sm={12} lg={3} className="mt-2 mb-2 datepicker-wrapper">
                        <Form.Label>Iki*</Form.Label>
                        <DatePicker
                            className="block"
                            dateFormat="yyyy-MM-dd"
                            required
                            selected={form.end}
                            minDate={moment(form.start).toDate()}
                            locale={lt}
                            onChange={value => onPeriodChange(value, 'end')}
                        />
                    </Col>
                    <Col md={4} sm={12} lg={4} className="mt-2 mb-2">
                        <Form.Label>Pastaba</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="3"
                            name="description"
                            selected={form.description}
                            onChange={value => onPeriodChange(value.target.value, 'description')}
                        />
                    </Col>
                </Form.Row>
                <div className="d-flex justify-content-end m-1">
                    <Button type="submit" variant="success">Saugoti</Button>
                </div>
            </Form>
        </fieldset>
    );

VacationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onPeriodChange: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    admins: PropTypes.array.isRequired,
    teamLeaders: PropTypes.array.isRequired,
    role: PropTypes.oneOf(getRoles()).isRequired,
    handleMyselfVacation: PropTypes.func.isRequired,
    isMyVacation: PropTypes.bool.isRequired,
};

export default VacationForm;
