import * as React from 'react';
import axios from 'axios';
import {
  Alert, Button, Container,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import CompaniesTable from './CompaniesTable';
import { handleError } from '../../utilities/ErrorHandler';

class CompanyHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      error: null,
    };

    this.handleCompanyResponse = this.handleCompanyResponse.bind(this);
    this.fetchAllCompanies = this.fetchAllCompanies.bind(this);
    this.onError = this.onError.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.fetchAllCompanies();
  }


  onError(error) {
    this.setState({
      error,
    });
  }

  fetchAllCompanies() {
    axios.get(`${process.env.REACT_APP_API_URL}/company`)
      .then((response) => {
        this.handleCompanyResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleCompanyResponse(data) {
    this.setState({
      companies: data.companies,
      error: null,
    });
  }

  handleDelete(id) {
    axios.delete(`${process.env.REACT_APP_API_URL}/company/${id}`)
      .then((response) => {
        this.handleCompanyResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  render() {
    const { companies, error } = this.state;
    return (
      <Container fluid>
        <h3>Mūsų įmonės</h3>
        <div className="d-flex flex-row">
          <NavLink to="/administration/company/add">
            <Button className="mb-2" variant="primary">Nauja įmonė</Button>
          </NavLink>
        </div>
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}
        {companies && companies.length === 0
        && (
        <Alert variant="info">
          Nėra sukurtų įmonių.
        </Alert>
        )}
        { companies && companies.length > 0
        && (
        <CompaniesTable
          companies={companies}
          handleDelete={this.handleDelete}
        />
        )}
      </Container>
    );
  }
}

export default CompanyHome;
