import React from 'react';
import { Badge } from 'react-bootstrap';
import { AiOutlineHistory } from 'react-icons/ai';
import TooltipComponent from '../../shared/tooltip/TooltipComponent';

const tooltipText = 'Darbe yra darbuotojų/as, kurie dirbo viršvalandžius ir jų/o valandos nėra patvirtintos!';

const JobOvertimeBadge = () => (
    <TooltipComponent component={<Badge pill variant="danger"><AiOutlineHistory/></Badge>} tooltipText={tooltipText}/>
);

export default JobOvertimeBadge;