export const FileUploadError = {
    FileInvalidType: 'file-invalid-type',
    FileTooLarge: 'file-too-large',
    FileTooSmall: 'file-too-small',
    TooManyFiles: 'too-many-files',
};

export const ACCEPTED_FILES = "image/*,video/*,application/pdf";
export const MAX_FILE_LENGTH = 1;
export const MAX_FILE_SIZE = 100000000;

export const resolveFileUploadError = (error, setError) => {
    if(error === FileUploadError.FileInvalidType) setError("Pasirinktas failo tipas yra negalimas")
    if(error === FileUploadError.FileTooLarge) setError("Failas negali būti didesnis nei 10MB")
    if(error === FileUploadError.TooManyFiles) setError("Galima kelti tik vieną failą")
}