import * as React from 'react';
import axios from 'axios';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import FormSubmitBtn from '../shared/util/FormSubmitBtn';
import ProfileSchema from '../../schemas/ProfileSchema';
import { handleError } from '../../utilities/ErrorHandler';

class ProfileHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValues: null,
      error: null,
      response: null,
      phone: null,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchProfile = this.fetchProfile.bind(this);
    this.onError = this.onError.bind(this);
  }


  componentDidMount() {
    this.fetchProfile();
  }

  onError(error) {
    this.setState({
      error,
      response: null,
    });
  }

  onSubmit() {
    const { formValues } = this.state;
    axios.put(`${process.env.REACT_APP_API_URL}/profile`, formValues)
      .then((response) => {
        this.setState({
          response: response.data.message,
        });
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  fetchProfile() {
    axios.get(`${process.env.REACT_APP_API_URL}/profile`)
      .then((response) => {
        this.setState({
          phone: response.data.phone,
        });
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleFormChange(e) {
    this.setState({
      formValues: e.formData,
    });
  }

  render() {
    const {
      response, error, formValues, phone,
    } = this.state;
    return (
      <Container>
        <Row className="d-flex justify-content-center">
          <Col md={6}>
            <h2 className="text-center">Profilio nustatymai</h2>
            { response && <Alert variant="success">{response}</Alert> }
            { error && <Alert variant="danger">{error}</Alert> }
            { phone
            && (
              <Form
                schema={ProfileSchema.initSchema(phone)}
                formData={formValues}
                onChange={this.handleFormChange}
                onSubmit={this.onSubmit}
                uiSchema={ProfileSchema.uiSchema}
              >
                <FormSubmitBtn />
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ProfileHome;
