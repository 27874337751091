import React from 'react';

import StandardJob from './standard/StandardJob';
import MiningJob from './mining/MiningJob';
import { JOB_TYPE } from './constants';

export default (
    {
        form,
        jobType,
        onChange,
        onUserRemove,
        onAddArrayItem,
        selectableUsers,
        selectableUnitMeasures
    }) => {
    switch (jobType) {
        case JOB_TYPE.MINING.value:
            return <MiningJob
                form={form}
                onChange={onChange}
                onUserRemove={onUserRemove}
                selectableUsers={selectableUsers}
                selectableUnitMeasures={selectableUnitMeasures}
            />;
        case JOB_TYPE.STANDARD.value:
        default:
            return <StandardJob
                form={form.users}
                onChange={onChange('users')}
                onUserRemove={onUserRemove}
                onAddArrayItem={onAddArrayItem}
                selectableUsers={selectableUsers}
                selectableUnitMeasures={selectableUnitMeasures}
            />;
    }
};