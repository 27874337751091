import * as React from "react";
import { Button } from "react-bootstrap";
import getUserButtonVariantByRole from "../../../utilities/getUserButtonVariantByRole";

export default ({ label, role, onClick, disabled }) => (
    <Button
        disabled={disabled}
        size="sm"
        variant={getUserButtonVariantByRole(role)}
        onClick={onClick}
    >{label}</Button>
);