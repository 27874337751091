import * as React from 'react';
import PropTypes from 'prop-types';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { dateFormatter, headerFormatter, userCellFormatter } from '../../utilities/TableHelper';
import VacationStatusBadge from "./VacationStatusBadge";
import { getVacationStatuses, VACATION_STATUS } from "./vacationStatus";
import { Button, ButtonGroup } from "react-bootstrap";
import { getRoles, isPrivilegedRole } from "../auth/AuthenticationService";

class VacationsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
        };

        this.getColumns = this.getColumns.bind(this);
        this.getStatusChangeColumn = this.getStatusChangeColumn.bind(this);
    }

    componentDidMount() {
        const { rows } = this.props;
        this.formatRows(rows);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { rows } = this.props;
        if (prevProps.rows !== rows) {
            this.formatRows(rows);
        }
    }

    getColumns() {
        const { role } = this.props;

        let columns = [
            {
                text: 'Darbuotojas',
                dataField: 'user',
                sort: true,
                filter: textFilter({
                    placeholder: 'Ieškoti',
                }),
                formatter: userCellFormatter
            },
            {
                text: 'Vadovas',
                dataField: 'responsiblePerson',
                sort: true,
                filter: textFilter({
                    placeholder: 'Ieškoti',
                }),
                formatter: userCellFormatter
            },
            {
                text: 'Nuo',
                dataField: 'start',
                sort: true,
                headerFormatter,
                formatter: dateFormatter
            },
            {
                text: 'Iki',
                dataField: 'end',
                sort: true,
                headerFormatter,
                formatter: dateFormatter
            },
            {
                text: "Statusas",
                dataField: "status",
                sort: true,
                headerFormatter,
                formatter: (cell) =>
                    (
                        <div className="d-flex justify-content-center">
                            <VacationStatusBadge status={cell}/>
                        </div>
                    ),
            },
            {
                text: 'Pastaba',
                dataField: 'description',
                sort: true,
                headerFormatter,
            }
        ];

        if (isPrivilegedRole(role)) {
            columns.push(this.getStatusChangeColumn());
        }
        return columns;
    }

    getStatusChangeColumn() {
        const { changeStatus } = this.props;

        return {
            text: "Keisti statusą",
            dataField: "changeStatus",
            sort: true,
            headerFormatter,
            headerStyle: () => {
                return { width: "25%" };
            },
            formatter: (cell, row) => {
                return row.status === VACATION_STATUS.PENDING ? (
                    <ButtonGroup toggle className="d-flex justify-content-center">
                        <Button
                            className="ml-1 mr-1"
                            variant="danger"
                            size="sm"
                            onClick={() => changeStatus(row.id, 'reject')}
                        >
                            Atmesti
                        </Button>
                        <Button
                            className="ml-1 mr-1"
                            variant="success"
                            size="sm"
                            onClick={() => changeStatus(row.id, 'approve')}
                        >
                            Patvirtinti
                        </Button>
                    </ButtonGroup>
                ) : <span/>;
            },
        };
    }

    formatRows(entities) {
        const rows = entities.map((row) => ({
            id: row.id,
            modalId: row.id,
            name: row.name,
            start: row.start,
            end: row.end,
            responsiblePerson: row.responsiblePerson,
            user: row.user,
            description: row.description,
            status: row.status
        }));

        this.setState({
            rows
        });
    }

    render() {
        const { rows } = this.state;

        return (
            <BootstrapTable
                keyField="id"
                data={rows}
                columns={this.getColumns()}
                filter={filterFactory()}
                bootstrap4
                striped
                hover
                condensed
            />);
    }
}

export default VacationsTable;

VacationsTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        responsiblePerson: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        status: PropTypes.oneOf(getVacationStatuses()).isRequired
    }).isRequired).isRequired,
    changeStatus: PropTypes.func.isRequired,
    role: PropTypes.oneOf(getRoles()).isRequired
};
