import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {connect} from "react-redux";
import {getAuthState} from "../../redux/auth/authSelectors";
import "./DMChatMainDialogMessage.scss"
import {FaRegFile} from "react-icons/all";

const DMChatMainDialogMessage = (props) => {
    const {idx, chatDTO, auth, downloadFile} = props;
    const [isMessageTimeDisplayed, setIsMessageTimeDisplayed] = useState(false);

    const handleMessageClick = () => {
        setIsMessageTimeDisplayed(!isMessageTimeDisplayed)
    }

    const downloadFileWithoutPropagation = (e, dmId, fileId) => {
        e.stopPropagation()
        downloadFile(dmId, fileId)
    }

    return (
        <div key={idx} className={`mb-1`} onClick={handleMessageClick}>
            <div className={`dm-chat-main-dialog__message-block ${chatDTO.receiverUserId === auth?.user?.userId ? "receiving" : "sending"}`}>
                {chatDTO.file ?
                    <span>
                        <div className={`dm-chat-main-dialog-message__file ${chatDTO.receiverUserId === auth?.user?.userId ? "receiving" : "sending"}`} onClick={(e) => downloadFileWithoutPropagation(e, chatDTO.id, chatDTO.file.id)}>
                            {chatDTO.file.fileName} <FaRegFile />
                        </div>
                    </span>
                    :
                    <span className={"dm-chat-main-dialog-message__text"}>{chatDTO.message}</span>
                }
            </div>
            <span className={`d-flex w-100 dm-chat-main-dialog-message__time ${isMessageTimeDisplayed ? "dm-chat-main-dialog-message__opacity-1" : "dm-chat-main-dialog-message__opacity-0"}
            ${chatDTO.receiverUserId === auth?.user?.userId ? "justify-content-start" : "justify-content-end"}`}>
                {moment(chatDTO.createdAt).format("YYYY-MM-DD HH:mm")}
            </span>
        </div>
    );
};

DMChatMainDialogMessage.propTypes = {
    idx: PropTypes.number,
    chatDTO: PropTypes.shape({
        message: PropTypes.string,
        senderUserId: PropTypes.number.isRequired,
        receiverUserId: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        file: PropTypes.shape({
            createdAt: PropTypes.string.isRequired,
            createdBy: PropTypes.object.isRequired,
            fileName: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired
        })
    }),
    auth: PropTypes.object.isRequired,
    downloadFile: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        auth: getAuthState(state),
    };
}

export default connect(mapStateToProps)(DMChatMainDialogMessage);
