import * as React from 'react';
import PropTypes from 'prop-types';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import FlippingBtn from "../../shared/buttons/FlippingBtn";
import { userCellFormatter } from "../../../utilities/TableHelper";


class JobReportsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: null,
    };
  }

  componentDidMount() {
    const { reports } = this.props;
    this.formatRows(reports);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { reports } = this.props;
    if (prevProps.reports !== reports) {
      this.formatRows(reports);
    }
  }

  getColumns() {
    return [
      {
        text: 'Diena',
        dataField: 'period',
        sort: true
      },
      {
        text: 'Dirbo darbuotojų',
        dataField: 'totalEmployees',
        sort: true
      },
      {
        text: 'Viso kiekis',
        dataField: 'totalQuantity',
        sort: true
      },
      {
        text: 'Dirbta darbų',
        dataField: 'totalJobs',
        sort: true
      }
    ];
  }

  formatRows(reports) {
    const { handleStatusChange } = this.props;
    const rows = reports.map((row) => ({
      period: row.period,
      employees: row.employees,
      totalEmployees: row.totalEmployees,
      totalQuantity: row.totalQuantity,
      totalJobs: row.totalJobs,
    }));

    const expandRow = {
      renderer: row => (
            <table className="table table-striped table-hover table-bordered table-sm pl-5">
              <thead>
              <tr>
                <th>Data</th>
                <th>Darbas</th>
                <th>Atsakingas asmuo</th>
                <th>Darbuotojas</th>
                <th>Kiekis</th>
                <th>Mato vienetas</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {row.employees.map((e) => (
                  <tr key={e.jobUserId}>
                    <td>{e.period}</td>
                    <td>{e.jobName}</td>
                    <td>{userCellFormatter(e.responsiblePerson)}</td>
                    <td>{userCellFormatter(e.user)}</td>
                    <td>{e.quantity}</td>
                    <td>{e.unit}</td>
                    <td><FlippingBtn
                        onClickCallBack={handleStatusChange}
                        clickValue={e.jobUserId}
                        clickedLabel="Atsakingas asmuo Patvirtino"
                        notClickedLabel="Atsakingas asmuo Nepatvirtino"
                        clicked={e.approved}
                        successColor={'success'}
                        />
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
      ),
      showExpandColumn: true,
      onlyOneExpanding: true,
      parentClassName: 'bg-primary text-light',
    };

    this.setState({
      rows,
      expandRow,
    });
  }

  render() {
    const { rows, expandRow } = this.state;
    return (
      <>
        { rows
          && (
            <BootstrapTable
              keyField="period"
              data={rows}
              expandRow={expandRow}
              columns={this.getColumns()}
              filter={filterFactory()}
              bootstrap4
              striped
              hover
              condensed
            />
          )}
      </>
    );
  }
}

export default JobReportsTable;

JobReportsTable.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape({
    period: PropTypes.string.isRequired,
    employees: PropTypes.arrayOf(PropTypes.shape({
      jobName: PropTypes.string.isRequired,
      responsiblePerson: PropTypes.object.isRequired,
      jobUserId: PropTypes.number.isRequired,
      user: PropTypes.object.isRequired,
      period: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
      approved: PropTypes.bool.isRequired,
    })),
    totalEmployees: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    totalJobs: PropTypes.number.isRequired,
  })).isRequired,
  handleStatusChange: PropTypes.func.isRequired,
};
