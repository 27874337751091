import * as React from 'react';
import axios from 'axios';
import { Alert, Button } from 'react-bootstrap';
import UnitMeTable from './UnitMeTable';
import SingleFieldModal from '../../shared/modals/SingleFieldModal';
import { handleError } from '../../../utilities/ErrorHandler';

class UnitMeHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      units: null,
      showModal: false,
      created: false,
    };

    this.fetchAllUnits = this.fetchAllUnits.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleAddUnit = this.handleAddUnit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this.fetchAllUnits();
  }

  onError(error) {
    this.setState({
      error,
      created: false,
    });
  }

  fetchAllUnits() {
    axios.get(`${process.env.REACT_APP_API_URL}/unit-me`)
      .then((response) => {
        this.handleAllUnitsResponse(response.data)
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  toggleModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      created: false,
    }));
  }

  handleAllUnitsResponse(units) {
    this.setState({
      units,
    });
  }

  handleAddUnit(unit) {
    this.toggleModal();
    axios.post(`${process.env.REACT_APP_API_URL}/unit-me`, { name: unit })
      .then(() => {
        this.setState({
          created: true,
        });
        this.fetchAllUnits();
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleDelete(id) {
    axios.delete(`${process.env.REACT_APP_API_URL}/unit-me/${id}`)
        .then((response) => {
          this.handleAllUnitsResponse(response.data)
        })
        .catch((err) => {
          handleError(err, this.onError);
        });
  }

  render() {
    const {
      error, units, showModal, created,
    } = this.state;
    return (
      <>
        <div className="d-flex justify-content-between my-2">
          <Button variant="success" onClick={this.toggleModal}>Pridėti naują mato vienetą</Button>
          <h2>Įmonės mato vienetai</h2>
        </div>
        <SingleFieldModal
          show={showModal}
          onClose={this.toggleModal}
          onSubmit={this.handleAddUnit}
          title="Pridėti naują mato vienetą"
          label="Mato vienetas"
        />
        { created && <Alert variant="success">Mato vienetas sėkmingai sukurtas</Alert> }
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}
        { units && <UnitMeTable data={units} handleDelete={this.handleDelete} /> }
      </>
    );
  }
}

export default UnitMeHome;
