import { createSelector } from "reselect";

const getNotificationState = (state) => state.notification;

export const getIsShowAllNotifications = createSelector([ getNotificationState ], ({ isShowAllNotifications }) => isShowAllNotifications);

export const getNotificationSelectUsers = createSelector([ getNotificationState ], ({ users }) => users);

export const getNotifications = createSelector([ getNotificationState ], ({ notifications }) => notifications);

export const getNotificationMessage = createSelector([ getNotificationState ], ({ message }) => message);

export const getNotificationTemporaryFiles = createSelector([ getNotificationState ], ({ tempFiles }) => tempFiles);

export const getNotificationsReceivers = createSelector([ getNotificationState ], ({ receivers }) => receivers);

export const getNotificationReceiverGroups = createSelector([ getNotificationState ], ({ receiverGroups }) => receiverGroups);

export const getIsLoadingNotifications = createSelector([ getNotificationState ], ({ loading }) => loading);

export const isNotificationFormSubmitDisabled = createSelector([ getNotificationState ], ({ isSubmitDisabled }) => isSubmitDisabled);

export const getNotificationError = createSelector([ getNotificationState ], ({ error }) => error);

export const getNotificationSuccess = createSelector([ getNotificationState ], ({ success }) => success);

export const getUnreadNotificationCount = createSelector([ getNotificationState ], ({ unreadNotificationCount }) => unreadNotificationCount);

export const getNotificationFormValues = createSelector([ getNotificationState ], ({ receivers, message, receiverGroups }) => ({
    receivers: receivers.map(receiver => receiver.userId),
    receiverGroups: receiverGroups.map(group => group.key),
    message
}));
