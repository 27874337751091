import React from 'react';
import { Button } from 'react-bootstrap';

class CompanySchema {
  constructor() {
    this.uiSchema = {
      'ui:ObjectFieldTemplate': (props) => this.getObjectTemplate(props),
      phone: {
        'ui:emptyValue': '',
      },
      companyIbans: {
        'ui:ArrayFieldTemplate': this.arrayFieldTemplate,
      },
    };
  }

  initSchema() {
    return {
      type: 'object',
      required: ['name', 'vat', 'code', 'address', 'seriesName', 'seriesStart'],
      properties: {
        name: { type: 'string', title: 'Pavadinimas' },
        vat: { type: 'string', title: 'PVM mokėtojo kodas' },
        code: { type: 'string', title: 'Kodas' },
        address: { type: 'string', title: 'Adresas' },
        seriesName: { type: 'string', title: 'Sask. serijos pavadinimas' },
        seriesStart: { type: 'number', title: 'Sask. serijos pradžia' },
        phone: { type: 'string', title: 'Telefono numeris', default: '' },
        companyIbans: {
          type: 'array',
          minItems: 1,
          items: {
            type: 'object',
            required: ['iban'],
            properties: {
              iban: {
                title: 'Saskaita',
                type: 'string',
              },
            },
          },
        },
      },
    };
  }

  getObjectTemplate(props) {
    return (
      <div key={props.idSchema.$id} className="col-md-12">
        <div className="row">
          {props.description}
          {props.properties.map((element) => (
            <div
              key={element.content.props.idSchema.$id}
              className="col-md-6"
            >
              {element.content}
            </div>
          ))}
        </div>
      </div>
    );
  }

  arrayFieldTemplate(props) {
    return (
      <div className={props.className}>
        {props.items && props.items.map((element) => (
          <React.Fragment key={element.key}>
            <div className="wrapper d-flex">
              {element.children}
              <div className="align-self-center mt-2 ml-1">
                <Button variant="danger" size="sm" onClick={element.onDropIndexClick(element.index)}>
                  Trinti
                </Button>
              </div>
            </div>
          </React.Fragment>
        ))}

        {props.canAdd && (
          <div className="row d-flex justify-content-end right">
            <Button variant="success" onClick={props.onAddClick} className="m-2" type="button">
              Pridėti
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default (new CompanySchema());
