import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import {debounce} from "lodash/function";
import {FaSearch} from "react-icons/all";

const DebouncedInputText = ({defaultValue, label, placeholder, type, onChange}) => {
    const handleChange = (e) => {
        e.persist()
        debounceFn(e.target.value)
    }

    const debounceFn = useCallback(debounce(onChange, 300), [onChange]);

    return (
            <form className="d-flex flex-column w-100 mb-2" onSubmit={(e) => {
                e.preventDefault()
                handleChange(e)
            }}>
                {label && <label htmlFor="input-field" className={"font-weight-bold text-white"}>{label}</label>}
                <div className={"d-flex align-items-center justify-content-start bg-white rounded px-3 py-2"}>
                    <FaSearch color={'#6C757D'}/>
                    <input
                        className="form-control shadow-none border-0 rounded w-100 p-3"
                        type={type}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        onChange={handleChange}
                    />
                </div>
            </form>
    );
};

DebouncedInputText.propTypes = {
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default DebouncedInputText;
