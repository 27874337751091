import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';
import { headerFormatter } from '../../../utilities/TableHelper';
import {Button} from "react-bootstrap";
import ConfirmDialog from "../../shared/modals/ConfirmDialog";

class UserDutiesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: null,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.formatRows(data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.formatRows(data);
    }
  }

  getColumns() {
    const { handleDelete } = this.props;
    return [
      {
        text: 'Pareigos',
        dataField: 'duty',
        sort: true,
        headerFormatter,
      },
      {
        text: 'Atlyginimas',
        dataField: 'salary',
        sort: true,
        headerFormatter,
      },
      {
        text: 'Maksimalios darbo valandos',
        dataField: 'maxWorkHours',
        sort: true,
        headerFormatter,
      },
      {
        text: '',
        dataField: 'id',
        sort: true,
        headerFormatter,
        formatter: (cell, row) => (
            <Button
                variant="danger"
                size="sm"
                onClick={
                  () => ConfirmDialog({ text: `Ar tikrai norite ištrinti ${row.duty}?`, onConfirm: () => handleDelete(row.id) })
                }
            >
              Trinti
            </Button>
        ),
      },
    ];
  }

  formatRows(data) {
    const rows = data.map((row) => ({
      id: row.id,
      duty: row.duty,
      salary: row.salary,
      maxWorkHours: row.maxWorkHours,
    }));

    this.setState({
      rows,
    });
  }

  render() {
    const { rows } = this.state;

    return (
      <>
        { rows && (
          <BootstrapTable
            keyField="id"
            data={rows}
            columns={this.getColumns()}
            bootstrap4
            hover
          />
        )}
      </>
    );
  }
}

export default UserDutiesTable;

UserDutiesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    duty: PropTypes.string.isRequired,
    salary: PropTypes.number.isRequired,
    maxWorkHours: PropTypes.number,
  })).isRequired,
  handleDelete: PropTypes.func.isRequired,
};
