import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import SearchableUserSelector from "../../../shared/select/user/SearchableUserSelector";

export default (
    {
        user,
        index,
        onChange,
        onUserRemove,
        selectableUsers
    }) => (
    <Form.Row className="align-items-center mb-4 mt-4">
        <Col md={4} sm="auto" className="mt-2 mb-2">
            <Form.Label>Darbuotojas*</Form.Label>
            <SearchableUserSelector
                id={`userId-${index}`}
                key={user.id}
                labelKey="name"
                name="user"
                onChange={value => onChange('user')(undefined, value)}
                options={selectableUsers}
                selected={user.user}
                inputProps={{ required: true }}
                disabled={user.readOnly}
            />
        </Col>
        <Col style={{ marginTop: '32px' }}>
            <Button
                id={`remove-button-${index}`}
                variant="danger"
                onClick={() => onUserRemove(index)}
                disabled={user.readOnly}>
                Trinti
            </Button>
        </Col>
    </Form.Row>
);