import * as React from 'react';
import Form from 'react-jsonschema-form';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import FormSubmitBtn from '../shared/util/FormSubmitBtn';
import RegisterSchema from '../../schemas/RegisterSchema';
import { handleError } from '../../utilities/ErrorHandler';

class RegisterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValues: null,
      error: null,
    };

    this.onError = this.onError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;

    this.setState({
      token: params.token,
    });

    axios.get(`${process.env.REACT_APP_API_URL}/user/verify/${params.token}`)
      .then((response) => {
        this.setState({
          formValues: response.data,
        });
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  onError(error) {
    this.setState({
      error,
    });
  }

  onSubmit() {
    const { formValues, token } = this.state;
    Object.assign(formValues, { ...formValues, token });

    axios.post(`${process.env.REACT_APP_API_URL}/user/register`, formValues)
      .then(() => {
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        this.props.history.push('/login');
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }


  handleFormChange(e) {
    this.setState({
      formValues: e.formData,
    });
  }

  render() {
    const { formValues, error } = this.state;
    return (
      <div className="d-flex justify-content-center">
        <div className="col-md-6">
          {error && (
            <Alert variant="danger" className="my-2">
              {error}
            </Alert>
          )}
          <Form
            schema={RegisterSchema.initSchema()}
            formData={formValues}
            onSubmit={this.onSubmit}
            onChange={this.handleFormChange}
            uiSchema={RegisterSchema.uiSchema}
          >
            <FormSubmitBtn />
          </Form>
        </div>
      </div>
    );
  }
}

export default RegisterComponent;
