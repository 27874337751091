import {
    FETCH_DM_UNREAD_MESSAGES_COUNT_FAILURE,
    FETCH_DM_UNREAD_MESSAGES_COUNT_SUCCESS
} from "./dmActions";

const initialState = {
    loading: null,
    success: null,
    error: null,
    unreadDMNotificationCount: 0
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_DM_UNREAD_MESSAGES_COUNT_SUCCESS:
            return {
                ...state,
                unreadDMNotificationCount: action.payload.value,
                loading: false
            };
        case FETCH_DM_UNREAD_MESSAGES_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.value
            };
        default:
            return state;
    }
};