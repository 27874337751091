import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Col, Container } from 'react-bootstrap';
import axios from 'axios';
import FileSaver from 'file-saver';
import ReturnHomeHandler from './ReturnHomeHandler';
import ResponseContainer from '../shared/containers/ResponseContainer';
import { handleError } from '../../utilities/ErrorHandler';
import { getUserRole, isPrivilegedRole, isUserRole } from '../auth/AuthenticationService';

import { JOB_FORM_OBJ } from './form/constants';
import JobForm from './form/JobForm';
import { getCommonDescription } from './helpers/helper';
import { getAuthState } from "../../redux/auth/authSelectors";
import { toDTO, toFormView } from "./helpers/jobMapper";

class EditJob extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            error: null,
            success: null,
            loading: true,
            isSubmitting: false,
            classifiers: {},
            form: {
                ...JOB_FORM_OBJ,
            },
            isFormDirty: false,
            isFormReadOnly: false,
            auth: props.auth,
            isEditMode: true
        };

        this.getJob = this.getJob.bind(this);
        this.onError = this.onError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.markAsDirty = this.markAsDirty.bind(this);
        this.onUserRemove = this.onUserRemove.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);
        this.onUserDeleted = this.onUserDeleted.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onDownloadFile = this.onDownloadFile.bind(this);
        this.onAddArrayItem = this.onAddArrayItem.bind(this);
        this.onDeleteArrayItem = this.onDeleteArrayItem.bind(this);
        this.handleGetJobSuccess = this.handleGetJobSuccess.bind(this);
        this.onDescriptionsChange = this.onDescriptionsChange.bind(this);
        this.handleGetClassifiersSuccess = this.handleGetClassifiersSuccess.bind(this);
    }

    componentDidMount() {
        Promise.all([
            this.getClassifiers(),
            this.getJob(),
        ]).then(() => this.setState({ loading: false }));
    }

    getClassifiers() {
        return axios.get(`${process.env.REACT_APP_API_URL}/job/form`)
            .then(this.handleGetClassifiersSuccess)
            .catch((err) => handleError(err, this.onError));
    }

    getJob() {
        const { id } = this.state;
        return axios.get(`${process.env.REACT_APP_API_URL}/job/${id}`)
            .then(this.handleGetJobSuccess)
            .catch((err) => handleError(err, this.onError));
    }

    handleGetJobSuccess({ data }) {
        const { role } = this.state;
        this.setState({
            form: toFormView(data),
            isFormReadOnly: isUserRole(role) && data.users.every(({ readOnly }) => readOnly)
        });
    }

    handleGetClassifiersSuccess({ data }) {
        this.setState({
            classifiers: data
        });
    }

    onError(error) {
        this.setState({
            error,
            success: null,
            isSubmitting: false
        });
    }

    onSubmit(event) {
        event.preventDefault();
        const { id, form } = this.state;

        const error = this.validate(form);
        if (!!error) {
            return handleError(error, this.onError);
        }
        const request = toDTO(form);

        this.setState({
            isSubmitting: true
        });
        axios.put(`${process.env.REACT_APP_API_URL}/job/${id}`, request)
            .then(({ data }) => Promise.all(form.tempFiles.map(file => this.uploadFile(data, file))))
            .then(this.getJob)
            .then(() => this.handleUpdateSuccess())
            .catch((err) => handleError(err, this.onError));
    }

    validate(form) {
        if (form.responsiblePerson.filter(user => !!user.userId).length === 0) {
            return { message: 'Prašome pasirinkti atsakingą asmenį šiam darbui' };
        }
        return undefined;
    }

    uploadFile(jobId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(`${process.env.REACT_APP_API_URL}/job/${jobId}/file`, formData);
    }

    handleUpdateSuccess() {
        this.setState({
            isSubmitting: false,
            success: 'Darbas sėkmingai atnaujintas.',
            isFormDirty: false,
            error: null
        });
    }

    onUserRemove(index) {
        const { form } = this.state;
        const user = form.users[index];

        if (user && user.id) {
            axios.delete(`${process.env.REACT_APP_API_URL}/job/user/${user.id}`)
                .then(this.onUserDeleted)
                .catch((err) => handleError(err, this.onError));
        }
        this.onDeleteArrayItem('users')(index);
    }

    onUserDeleted() {
        this.setState({
            success: 'Vartotojas ištrintas sėkmingai.'
        });
    }

    onDownloadFile(fileId, fileName) {
        const { id } = this.state;

        axios.get(`${process.env.REACT_APP_API_URL}/job/${id}/file/${fileId}`, { responseType: 'blob' })
            .then((response) => FileSaver.saveAs(response.data, fileName))
            .catch((err) => handleError(err, this.onError));
    }

    onDeleteFile(fileId) {
        const { id, form } = this.state;
        const updatedFiles = form.files.filter((file) => file.id !== fileId);

        this.setState({ isSubmitting: true });
        axios.delete(`${process.env.REACT_APP_API_URL}/job/${id}/file/${fileId}`)
            .then(() => this.onFileDeleted(form, updatedFiles))
            .catch((err) => handleError(err, this.onError));
    }

    onFileDeleted(form, files) {
        this.setState({
            success: 'Failas ištrintas sėkmingai.',
            isSubmitting: false,
            form: { ...form, files }
        });
    }

    onDescriptionsChange(descriptions) {
        const { classifiers, form } = this.state;

        const description = getCommonDescription(descriptions, classifiers.descriptions, form.description);
        this.setState({
            form: {
                ...form,
                descriptions,
                description
            }
        }, this.markAsDirty)
    }

    markAsDirty() {
        this.setState({
            isFormDirty: true
        });
    }

    onFieldChange(field) {
        return (event, value) => {
            const { form } = this.state;
            this.setState({
                form: { ...form, [field]: value }
            }, this.markAsDirty);
        }
    }

    onAddArrayItem(field) {
        return value => {
            const { form } = this.state;
            const data = [ ...form[field] ];
            data.push(value);
            this.setState({
                form: { ...form, [field]: data }
            }, this.markAsDirty);
        }
    }

    onDeleteArrayItem(field) {
        return index => {
            const { form } = this.state;
            const data = [ ...form[field] ];
            data.splice(index, 1);
            this.setState({
                form: { ...form, [field]: data }
            }, this.markAsDirty);
        }
    }

    render() {
        const {
            error, success, loading, form, isSubmitting, auth,
            classifiers, isFormReadOnly, isFormDirty, isEditMode,
        } = this.state;

        return (
            <Container fluid>
                <Prompt
                    when={isFormDirty}
                    message="Yra neišsaugotų pakeitimų. Ar tikrai norite palikti puslapį?"
                />
                <ReturnHomeHandler/>
                <h2 className="text-center">Koreguoti darbą</h2>
                <Col md={12}>
                    <ResponseContainer isLoading={loading} success={success} error={error}/>
                </Col>
                {!loading && (
                    <JobForm
                        form={form}
                        auth={auth}
                        classifiers={classifiers}
                        isSubmitting={isSubmitting}
                        isFormReadOnly={isFormReadOnly}
                        isEditMode={isEditMode}
                        onSubmit={this.onSubmit}
                        onChange={this.onFieldChange}
                        onAddArrayItem={this.onAddArrayItem}
                        onDeleteArrayItem={this.onDeleteArrayItem}
                        onUserRemove={this.onUserRemove}
                        onDownloadFile={this.onDownloadFile}
                        onDeleteFile={this.onDeleteFile}
                        onDescriptionsChange={this.onDescriptionsChange}
                        isFileDeleteAllowed={isPrivilegedRole(getUserRole(auth))}
                    />
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: getAuthState(state),
});

EditJob.propTypes = {
    auth: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default connect(mapStateToProps)(EditJob);
