import { Button } from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { FaArrowLeft } from 'react-icons/fa';

class GoBackBtn extends React.Component {
    render() {
        const { label, onClick, className } = this.props;
        return (
            <Button type="button" variant="info" className={`m-2 ${className}`} onClick={onClick}>
        <span>
            <FaArrowLeft/>{` ${label}`}
        </span>
            </Button>
        );
    }
}

export default GoBackBtn;

GoBackBtn.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    className: PropTypes.string
};

GoBackBtn.defaultProps = {
    className: ''
}

GoBackBtn.defaultProps = {
    onClick: () => {
    },
};
