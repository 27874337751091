import {Alert} from "react-bootstrap";
import Loader from "../util/Loader";
import * as React from "react";
import * as PropTypes from "prop-types";

class ResponseContainer extends React.Component {
    render() {
        const { error, success, isLoading } = this.props;
        return (
            <>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert variant="success">
                        {success}
                    </Alert>
                )}
                <Loader isLoading={isLoading} />
            </>
        );
    }
}

export default ResponseContainer;

ResponseContainer.propTypes = {
    error: PropTypes.string,
    success: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
}

ResponseContainer.defaultProps = {
    error: null,
    success: null,
}