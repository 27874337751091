import * as React from 'react';
import axios from 'axios';
import {
  Alert, Button, Container,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import CustomerTable from './CustomerTable';
import CustomerPersonModal from './modals/CustomerPersonModal';
import { handleError } from '../../utilities/ErrorHandler';

class CustomerHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      error: null,
      showModal: false,
      modalData: null,
    };

    this.handleCustomerResponse = this.handleCustomerResponse.bind(this);
    this.fetchAllCustomers = this.fetchAllCustomers.bind(this);
    this.onError = this.onError.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.fetchAllCustomers();
  }


  onError(error) {
    this.setState({
      error,
    });
  }

  fetchAllCustomers() {
    axios.get(`${process.env.REACT_APP_API_URL}/customer`)
      .then((response) => {
        this.handleCustomerResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleCustomerResponse(data) {
    this.setState({
      customers: data.customers,
      error: null,
    });
  }

  handleDelete(id) {
    axios.delete(`${process.env.REACT_APP_API_URL}/customer/${id}`)
      .then((response) => {
        this.handleCustomerResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  toggleModal(modalData) {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      modalData,
    }));
  }

  render() {
    const {
      customers, error, showModal, modalData,
    } = this.state;
    return (
      <Container fluid>
        <h3>Mūsų klientai</h3>
        <div className="d-flex flex-row">
          <NavLink to="/administration/customer/add">
            <Button className="mb-2" variant="primary">Naujas klientas</Button>
          </NavLink>
        </div>
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}
        {customers && customers.length === 0
        && (
        <Alert variant="info">
          Nėra sukurtų klientų.
        </Alert>
        )}
        { customers && customers.length > 0
        && (
          <CustomerTable
            customers={customers}
            handleContactPersonClick={this.toggleModal}
            handleDelete={this.handleDelete}
          />
        )}
        {modalData && <CustomerPersonModal toggleModal={this.toggleModal} showModal={showModal} modalData={modalData} />}
      </Container>
    );
  }
}

export default CustomerHome;
