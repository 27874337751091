import queryString from 'query-string';
import moment from "moment";

//  formats date to yyyy-mm-dd
export function formatStandartDate(date) {
  return new Date(date).toLocaleDateString('lt-LT');
}

// adding days to today date
export function getDateAfterDays(days) {
  const today = new Date();
  today.setDate(today.getDate() + days);
  return today;
}

// takes period array [fromDate, toDate] and formats url filtering string
export function formatPeriodUrl(period) {
  const from = formatStandartDate(period[0]);
  const to = formatStandartDate(period[1]);
  return `?periodFrom=${from}&periodTo=${to}`;
}

// returns period array from location url
export function getPeriodFromLocation(location) {
  const { search } = location;
  const period = queryString.parse(search);
  const periodFrom = new Date(period.periodFrom);
  const periodTo = new Date(period.periodTo);

  return isDateValid(periodFrom) && isDateValid(periodTo) ? [ periodFrom, periodTo ] : [];
}

export function isDateValid(d) {
  return (Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d.getTime()));
}

export function getFirstOfThisMonth() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getFirstCalendarDay() {
 return moment(new Date()).subtract(1, 'months').startOf('week').toDate();
}

export function getLastCalendarDay() {
    return moment(new Date()).add(1, 'months').endOf('week').toDate();
}
