import React from "react";
import {DOTS, usePagination} from "./usePagination";
import {Pagination} from "react-bootstrap";

const CustomPagination = props => {
    const {
        onPageChange,
        totalPageCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;
    const paginationRange = usePagination({
        currentPage,
        totalPageCount,
        siblingCount,
        pageSize
    });

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };
    let lastPage = paginationRange[paginationRange?.length - 1];
    return (
        <Pagination>
            <ul style={{ listStyle: 'none', display: 'flex' }}>
                <Pagination.Prev
                    disabled={currentPage === 0}
                    onClick={onPrevious}
                />
                {paginationRange.map((pageNumber, index) => {
                    if(pageNumber === DOTS) {
                        return <Pagination.Ellipsis key={index} />
                    }
                    return (
                        <Pagination.Item
                            key={index}
                            active={pageNumber === currentPage}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber + 1}
                        </Pagination.Item>
                    );
                })}
                <Pagination.Next
                    disabled={currentPage === lastPage}
                    onClick={onNext}
                />
            </ul>
        </Pagination>
    );
};

export default CustomPagination;