import React from 'react';
import { Button } from 'react-bootstrap';

class CustomerSchema {
  constructor() {
    this.uiSchema = {
      'ui:ObjectFieldTemplate': (props) => this.getObjectTemplate(props),
      email: {
        'ui:emptyValue': '',
      },
      phone: {
        'ui:emptyValue': '',
      },
      customerPersons: {
        'ui:ArrayFieldTemplate': this.arrayFieldTemplate,
        items: {
          'ui:ObjectFieldTemplate': this.getArrayObjectTemplate,
          email: {
            'ui:emptyValue': '',
          },
          phone: {
            'ui:emptyValue': '',
          },
          duties: {
            'ui:emptyValue': '',
          },
        },
      },
    };
  }

  initSchema() {
    return {
      type: 'object',
      required: ['name', 'email', 'phone', 'address', 'code', 'vat'],
      properties: {
        name: { type: 'string', title: 'Pavadinimas' },
        email: { type: 'string', title: 'El. paštas', default: '' },
        phone: { type: 'string', title: 'Telefono numeris', default: '' },
        address: { type: 'string', title: 'Adresas' },
        code: { type: 'string', title: 'Kodas / asm. kodas' },
        vat: { type: 'string', title: 'PVM mokėtojo kodas' },
        privateCustomer: { type: 'boolean', title: 'Privatus klientas' },
        customerPersons: {
          type: 'array',
          title: 'Kontaktiniai asmenys',
          items: {
            type: 'object',
            required: ['name'],
            properties: {
              name: { title: 'Vardas', type: 'string' },
              email: { title: 'El. paštas', type: 'string', default: '' },
              phone: { title: 'Telefono numeris', type: 'string', default: '' },
              duties: { title: 'Pareigos', type: 'string', default: '' },
            },
          },
        },
      },
    };
  }

  getObjectTemplate(props) {
    return (
      <div key={props.idSchema.$id} className="col-md-12">
        <div className="row">
          {props.description}
          {props.properties.map((element) => {
            const biggerCols = ['customerPersons'];
            return (
              <div
                key={element.content.props.idSchema.$id}
                className={biggerCols.includes(element.name) ? 'col-md-12' : 'col-md-6'}
              >
                {element.content}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  arrayFieldTemplate(props) {
    return (
      <div className={props.className}>
        {props.items && props.items.map((element) => (
          <React.Fragment key={element.key}>
            <div className="wrapper d-flex">
              {element.children}
              <div className="align-self-center mt-2 ml-1">
                <Button variant="danger" size="sm" onClick={element.onDropIndexClick(element.index)}>
                  Trinti
                </Button>
              </div>
            </div>
          </React.Fragment>
        ))}

        {props.canAdd && (
          <div className="row d-flex justify-content-end right">
            <Button variant="success" onClick={props.onAddClick} className="m-2" type="button">
              Pridėti kontaktinį asmenį
            </Button>
          </div>
        )}
      </div>
    );
  }

  getArrayObjectTemplate(props) {
    return (
      <div key={props.idSchema.$id}>
        <div className="row">
          {props.description}
          {props.properties.map((element) => (
            <div
              key={element.content.props.idSchema.$id}
              className="col-md-3"
            >
              {element.content}
            </div>
          ))}
        </div>
      </div>
    );
  }

}

export default (new CustomerSchema());
