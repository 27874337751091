import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import chatReducer from "./chat/chatReducer";
import socketReducer from "./socket/socketReducer";
import jobReducer from "./job/jobReducer";
import navigationReducer from "./navigation/navigationReducer";
import notificationReducer from "./notification/notificationReducer";
import usersReducer from "./users/usersReducer";
import dmReducer from "./dm/dmReducer";

export default combineReducers({
    auth: authReducer,
    job: jobReducer,
    chat: chatReducer,
    navigation: navigationReducer,
    socket: socketReducer,
    notification: notificationReducer,
    users: usersReducer,
    dmNotifications: dmReducer
});