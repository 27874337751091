import React from "react";
import {Button} from "react-bootstrap";
import "./InstallationGuide.scss"
import * as PropTypes from "prop-types";

const InstallationGuide = ({instructions, handleClose, handleAccept, handleDecline}) => {
    return (
        <div className={`PwaInstallGuide`}>
            <div className={"my-1"}>
                {instructions}
                <span className={"mb-1"}>{` ${instructions ? "Ji" : "Programa"} beveik nenaudoja įrenginio atminties ir suteikia greitą prieigą sugryžti į programėlę.`}</span>
            </div>
            {
                instructions
                    ? <Button className={'mt3'} size="sm" variant="primary" onClick={handleClose}
                    >Supratau!</Button>
                    : (
                        <>
                            <Button className={`mr-1`} size="sm" variant="primary" onClick={handleAccept}
                            >Instaliuoti</Button>
                        </>
                    )
            }
        </div>
    )
}

export default InstallationGuide;

InstallationGuide.propTypes = {
    instructions: PropTypes.elementType,
    handleClose: PropTypes.func,
    handleAccept: PropTypes.func,
    handleDecline: PropTypes.func,
}

InstallationGuide.defaultProps = {
    instructions: undefined,
    handleClose: () => {
    },
    handleAccept: () => {
    },
    handleDecline: () => {
    },
}