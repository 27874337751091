import * as React from 'react';
import { Alert, Container } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import axios from 'axios';
import FormSubmitBtn from '../shared/util/FormSubmitBtn';
import CompanySchema from '../../schemas/CompanySchema';
import { handleError } from '../../utilities/ErrorHandler';

class EditCompany extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValues: null,
      error: null,
      id: null,
      success: false,
    };


    this.handleFormChange = this.handleFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    this.fetchCompany(params.id);
  }

  fetchCompany(id) {
    this.setState({
      id,
    });

    axios.get(`${process.env.REACT_APP_API_URL}/company/${id}`)
      .then((response) => {
        this.handleCompanyResponse(response.data, false);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleCompanyResponse(data, success) {
    this.setState({
      formValues: data,
      success,
      error: null,
    });
  }

  onError(error) {
    this.setState({
      error,
    });
  }

  onSubmit() {
    const { formValues, id } = this.state;
    Object.assign(formValues, { ...formValues, id });
    axios.put(`${process.env.REACT_APP_API_URL}/company`, formValues)
      .then((response) => {
        this.handleCompanyResponse(response.data, true);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleFormChange(e) {
    this.setState({
      formValues: e.formData,
    });
  }

  render() {
    const { formValues, error, success } = this.state;
    return (
      <Container>
        <h2 className="text-center">Redaguoti įmonę</h2>
        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}
        {success
        && (
          <Alert variant="success">
            Įmonė sėkmingai atnaujinta.
          </Alert>
        )}
        { formValues && (
        <Form
          schema={CompanySchema.initSchema()}
          formData={formValues}
          onChange={this.handleFormChange}
          onSubmit={this.onSubmit}
          uiSchema={CompanySchema.uiSchema}
        >
          <FormSubmitBtn />
        </Form>
        )}
      </Container>
    );
  }
}

export default EditCompany;
