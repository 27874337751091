import moment from 'moment';

export const JOB_TYPE = {
  STANDARD: { value: 'STANDARD', name: 'Standartiniai darbai' },
  MINING: { value: 'MINING', name: 'Kasimo darbai' },
};

export const NEW_USER = {
  user: [],
  unitMeId: undefined,
  hours: '0',
  readOnly: false
};

export const JOB_FORM_OBJ = ({
  name: '',
  date: moment().format('YYYY-MM-DD'),
  descriptions: [],
  description: '',
  note: '',
  responsiblePersonNote: '',
  adminNote: '',
  responsiblePerson: [],
  jobType: JOB_TYPE.STANDARD.value,
  totalJobHours: {
    jobHours: '',
    jobPeriodHours: '',
  },
  users: [],
  totalMined: undefined,
  unitMeId: undefined,
  files: [],
  tempFiles: [],
  projectId: undefined
});