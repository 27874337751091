import * as React from 'react';
import {Button, Container} from 'react-bootstrap';
import axios from 'axios';
import 'react-datepicker/dist/react-datepicker.css';
import ReportsTable from './ReportsTable';
import { formatStandartDate } from '../../utilities/DateHelper';
import ReportExtraModal from './modals/ReportExtraModal';
import MonthPicker from "../shared/date/MonthPicker";
import ResponseContainer from "../shared/containers/ResponseContainer";
import { handleError } from '../../utilities/ErrorHandler';
import { Typeahead } from "react-bootstrap-typeahead";
import { getSelectableRoles } from "../auth/AuthenticationService";

class ReportsHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            roles: [],
            data: [],
            error: null,
            showExtraModal: false,
            modalData: null,
            selectableRoles: getSelectableRoles(),
            isLoading: true
        };

        this.getReports = this.getReports.bind(this);
        this.handleExtrasChange = this.handleExtrasChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onError = this.onError.bind(this);
        this.handleModalSave = this.handleModalSave.bind(this);
        this.handleCellSave = this.handleCellSave.bind(this);
        this.setReportsDate = this.setReportsDate.bind(this);
        this.onRolesChange = this.onRolesChange.bind(this);
        this.handleDeleteCurrentPeriod = this.handleDeleteCurrentPeriod.bind(this);
    }

    componentDidMount() {
        this.getReports();
    }

    getReports() {
        const { date, roles } = this.state;

        const filter = {
            date: formatStandartDate(date),
            roles: roles.map(role => role.value)
        }
        axios.post(`${process.env.REACT_APP_API_URL}/reports/search`, filter)
            .then(({ data }) => this.handleReportsResponse(data))
            .catch((err) => handleError(err, this.onError));
    }

    setReportsDate(date) {
        this.setState({
            date,
            isLoading: true
        }, this.getReports);
    }

    onRolesChange(roles) {
        this.setState({
            roles,
            isLoading: true
        }, this.getReports);
    }


    handleReportsResponse(data) {
        this.setState({
            data,
            error: null,
            isLoading: false
        });
    }

    handleExtrasChange(reportId) {
        axios.get(`${process.env.REACT_APP_API_URL}/reports/extra/${reportId}`)
            .then(({ data }) => this.handleReportExtraResponse(data, reportId))
            .catch((err) => handleError(err, this.onError));
        this.toggleModal();
    }

    handleReportExtraResponse(data, reportId) {
        this.setState({
            modalData: data.reportExtras,
            reportId,
        });
    }

    onError(error) {
        this.setState({
            error,
            isLoading: false,
        });
    }

    toggleModal() {
        this.setState((prevState) => ({
            showExtraModal: !prevState.showExtraModal,
        }));
    }

    handleModalSave(values) {
        const reportsExtra = {
            reportExtras: values,
        };

        const { reportId } = this.state;

        axios.post(`${process.env.REACT_APP_API_URL}/reports/extra/${reportId}`, reportsExtra)
            .then(() => {
                this.toggleModal();
                this.getReports();
            })
            .catch((err) => handleError(err, this.onError));
    }

    handleCellSave(row) {
        axios.post(`${process.env.REACT_APP_API_URL}/reports`, row)
            .then(this.getReports)
            .catch((err) => handleError(err, this.onError));
    }

    handleDeleteCurrentPeriod(date) {
        axios.post(`${process.env.REACT_APP_API_URL}/reports/delete`, { date })
            .then(this.getReports)
            .catch((err) => handleError(err, this.onError));
    }

    render() {
        const {
            data, date, showExtraModal,
            modalData, error, isLoading,
            roles, selectableRoles
        } = this.state;

        return (
            <Container fluid>
                <div className="m-2">
                    <h3>Ataskaitos</h3>
                    <div className="d-flex justify-content-between mb-2">
                        <MonthPicker date={date} onChange={this.setReportsDate}/>
                        <div className="d-flex align-items-center">
                            <Typeahead
                                id="role-selector"
                                labelKey="name"
                                onChange={(value) => this.onRolesChange(value)}
                                options={selectableRoles}
                                multiple={true}
                                placeholder="Rolės"
                                selected={roles}
                            />
                            <Button className="ml-1"
                                    variant="danger"
                                    type="button"
                                    onClick={() => this.handleDeleteCurrentPeriod(date)}

                            >Trinti šį mėn</Button>
                        </div>

                    </div>
                    <ResponseContainer isLoading={isLoading} error={error}/>
                    <ReportExtraModal
                        handleModalClose={this.toggleModal}
                        handleModalSave={this.handleModalSave}
                        showModal={showExtraModal}
                        modalData={modalData}
                    />
                    {!isLoading && data && data.length > 0
                    && <ReportsTable
                        data={data}
                        period={date}
                        handleExtrasChange={this.handleExtrasChange}
                        handleCellSave={this.handleCellSave}
                    />}
                </div>
            </Container>
        );
    }
}

export default ReportsHome;
