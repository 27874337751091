export const JOB_ADMIN_STATUS = {
  NOT_APPROVED: { code: "NOT_APPROVED", name: "Nepatvirtinta", hint: "Nepatvirtinta" },
  REKYL: { code: "REKYL", name: "REKYL", hint: "Visi darbuotojai išsaugoti REKYL sistemoje" },
  APPROVED: { code: "APPROVED", name: "Patvirtinta", hint: "Visi darbuotojai patvirtinti administracijos" },
  REJECTED: { code: "REJECTED", name: "Atmesta", hint: "Atmesta" },
};

export const JOB_RESPONSIBLE_PERSON_STATUS = {
  NOT_APPROVED: { code: "NOT_APPROVED", name: "Nepatvirtinta", hint: "Nepatvirtinta" },
  APPROVED: { code: "APPROVED", name: "Patvirtinta", hint: "Visi darbuotojai patvirtinti atsakingo darbuotojo" },
  REJECTED: { code: "REJECTED", name: "Atmesta", hint: "Atmesta" },
};
