import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "./Chat.style";
import { ThemeProvider } from "styled-components";
import chatTheme from "../../chatTheme";
import Conversation from "../conversation/Conversation";
import { clearConversationState, fetchConversation } from "../../../../redux/chat/conversation/conversationActions";
import ConversationMembers from "../members/ConversationMembers";
import { clearConversationMessagesState } from "../../../../redux/chat/messages/messageActions";
import { addSocketTopic, discardSocketTopic } from "../../../../redux/socket/socketActions";
import { navigationDisplayed, navigationHidden } from "../../../../redux/navigation/navigationActions";
import ResponseContainer from "../../../shared/containers/ResponseContainer";
import {
    getCurrentConversationError,
    getCurrentConversationIsLoading
} from "../../../../redux/chat/conversation/conversationSelectors";

export default ({ match }) => {
    const conversationId = match.params.id;
    const dispatch = useDispatch();
    const topic = `/conversations/${conversationId}/messages:receive`;

    const isLoading = useSelector(getCurrentConversationIsLoading);
    const error = useSelector(getCurrentConversationError);

    useEffect(() => {
        dispatch(clearConversationState());
        dispatch(discardSocketTopic(topic));
        dispatch(clearConversationMessagesState());
    }, [ dispatch, topic ]);

    useEffect(() => {
        dispatch(addSocketTopic(topic));
        dispatch(fetchConversation(conversationId));
        dispatch(navigationHidden());
    }, [ conversationId, dispatch, topic ]);

    useEffect(() => () => {
        dispatch(navigationDisplayed());
    }, [ conversationId, dispatch, topic ]);

    return (
        <ThemeProvider theme={chatTheme}>
            <ResponseContainer error={error} isLoading={isLoading} />
            <Wrapper>
                <Conversation/>
                <ConversationMembers/>
            </Wrapper>
        </ThemeProvider>
    );
};