import styled from 'styled-components';
import { motion } from "framer-motion";

export const Wrapper = styled(motion.section)`
  border-left: 1px solid #d0dae5;
  height: 100%;
  display: none;
  flex-direction: column;
  max-width: 290px;
  width: 100%;
  background-color: #f0f3f7;
  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    margin-left: 0;
    max-width: none;
    position: fixed;
    z-index: 300;
  };
`;

export const Title = styled.div`
  display: flex;
`;

export const Header = styled.div`
  color: #585858;
  font-weight: 300;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px;
`;

export const CloseIcon = styled.span`
  cursor: pointer;
  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    display: none;
  }
`;

export const ScrollableView = styled.span`
  overflow-y: scroll;
`;

export const BackIconWrapper = styled.div`
  cursor: pointer;
  display: none;
  @media ${props => props.theme.breakpoint.mediaQuery.small} {
    display: flex;
    margin-right: 25px;
  }
`;

export const getAnimatedWrapperVariants = () => ({
    open: {
        width: "100%",
        display: "flex"
    },
    closed: {
        width: "0",
        transitionEnd: {
            display: "none"
        }
    }
});