import React from "react"
import "./GuideIcons.scss"
import kebabMenu from "../../../assets/img/icon-kebab-menu.png"
import kebabMenuHorizontal from "../../../assets/img/icon-kebab-menu-horizontal.png"
import iOsQuickActionShare from "../../../assets/img/icon-ios-quick-action-share.png"
import androidOperaHomeScreen from "../../../assets/img/icon-android-opera-home-screen.png"
import androidFirefoxInstall from "../../../assets/img/icon-android-firefox-install.png"
import androidEdgeAddToPhone from "../../../assets/img/icon-android-edge-add-to-phone.png"

export const IconKebabMenu = () => <img className={`guideIcon verticalIcon`} src={kebabMenu} alt={"Kebab menu"}/>
export const IconKebabHorizontalMenu = () => <img className={`guideIcon`} src={kebabMenuHorizontal} alt={"Kebab menu"}/>
export const IconIOsQuickActionShare = () => <img className={`guideIcon`} src={iOsQuickActionShare} alt={"Quick actions share"}/>
export const IconAndroidOperaHomeScreen = () => <img className={`guideIcon`} src={androidOperaHomeScreen} alt={"Home screen"}/>
export const IconAndroidFirefoxInstall = () => <img className={`guideIcon`} src={androidFirefoxInstall} alt={"Install"}/>
export const IconAndroidEdgeAddToPhone = () => <img className={`guideIcon`} src={androidEdgeAddToPhone} alt={"Add to phone"}/>