import React from 'react';
import getDatePickerTemplate from './Templates';
import { validatePhoneNumber } from "../utilities/validation/PhoneNumberValidator";

class UserSchema {
  constructor() {
    this.uiSchema = {
      'ui:ObjectFieldTemplate': (props) => this.getObjectTemplate(props),
      email: {
        'ui:emptyValue': '',
      },
      managerCheckbox: {
        "ui:widget": "CheckboxWidget",
        classNames: 'edit-user-checkbox',
      },
      managerSelect: {
        "ui:widget": "CustomSelect",
      },
      employmentDate: {
        'ui:widget': 'employmentDatePicker',
        classNames: 'datepicker-wrapper',
        'ui:FieldTemplate': (props) => getDatePickerTemplate(props),
      },
      firedDate: {
        'ui:emptyValue': '',
        'ui:widget': 'firedDatePicker',
        classNames: 'datepicker-wrapper',
        'ui:FieldTemplate': (props) => getDatePickerTemplate(props),
      },
      rentStartDate: {
        'ui:widget': 'rentStartDatePicker',
        classNames: 'datepicker-wrapper',
        'ui:FieldTemplate': (props) => getDatePickerTemplate(props),
      },
    };
  }

  initSchema(backendData) {
    return {
      type: 'object',
      required: ['name', 'surname', 'salary', 'rent', 'role', 'phone', 'duties', 'employmentDate', 'rentStartDate'],
      properties: {
        name: { type: 'string', title: 'Vardas' },
        surname: { type: 'string', title: 'Pavardė' },
        email: { type: 'string', title: 'El. paštas', default: '' },
        salary: { type: 'number', title: 'Atlyginimas' },
        rent: { type: 'number', title: 'Nuoma' },
        duties: {
          type: 'number', title: 'Pareigos', enumNames: backendData.duties.values, enum: backendData.duties.ids,
        },
        role: {
          type: 'string', title: 'Rolė', enumNames: ['Vartotojas', 'Team Leader', 'Administratorius'], enum: ['USER', 'TEAM_LEADER', 'ADMIN'], default: 'USER',
        },
        phone: { type: 'string', title: 'Tel nr.' },
        employmentDate: {
          type: 'string', title: 'Įdarbinimo data',
        },
        rentStartDate: {
          type: 'string', title: 'Nuomos pradžia',
        },
        firedDate: {
          type: 'string', title: 'Atleidimo data',
        },
        // manager: {
        //   type: 'boolean', title: 'Ar vartotojas turi pavaldinių?', default: false
        // },
      },
    };
  }

  validate(form, errors) {
    validatePhoneNumber(form.phone, errors.phone);
    return errors;
  }

  getObjectTemplate(props) {
    const smallerCols = ['employmentDate', 'firedDate', 'rentStartDate'];
    return (
      <div key={props.idSchema.$id} className="col-md-12">
        <div className="row">
          {props.description}
          {props.properties.map((element) => (
            <div
              key={element.content.props.idSchema.$id}
              className={smallerCols.includes(element.name) ? 'col-md-2' : 'col-md-6'}
            >
              {element.content}
            </div>
          ))}
        </div>
      </div>
    );
  }

  formatBackendData(data) {
    const dutiesIds = data.possibleDuties.map((d) => (d.id));
    const dutiesValues = data.possibleDuties.map((d) => (d.duty));

    return {
      duties: {
        ids: dutiesIds,
        values: dutiesValues,
        all: data.possibleDuties,
      },
      manager: data.manager,
    };
  }
}
export default (new UserSchema());
