import * as React from 'react';
import * as PropTypes from 'prop-types';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ConfirmDialog from '../shared/modals/ConfirmDialog';
import SelectUserButton from "../shared/buttons/SelectUserButton";

const { SearchBar } = Search;

const FILTER_PLACEHOLDER = 'Ieškoti';
const UNTOUCHABLE_USERS_EMAILS = ['info@uabmogis.com'];

class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: null,
      columns: [
        {
          text: 'Vardas',
          dataField: 'name',
          sort: true,
          formatter: (cell, row) => (
                  <SelectUserButton
                      role={row.role}
                      label={cell}
                      disabled={!props.isAdmin}
                      onClick={() => this.handleNameClick(row.id)}/>
              ),
          filter: textFilter({
            placeholder: FILTER_PLACEHOLDER,
          }),
        },
        {
          text: 'El. paštas',
          dataField: 'email',
          sort: true,
          filter: textFilter({
            placeholder: FILTER_PLACEHOLDER,
          }),
        },
        {
          text: 'Tel. Nr.',
          dataField: 'phone',
          sort: true,
          filter: textFilter({
            placeholder: FILTER_PLACEHOLDER,
          }),
        },
        {
          text: 'Atlyginimas',
          dataField: 'salary',
          sort: true,
          filter: textFilter({
            placeholder: FILTER_PLACEHOLDER,
          }),
        },
        {
          text: 'Nuoma',
          dataField: 'rent',
          sort: true,
          filter: textFilter({
            placeholder: FILTER_PLACEHOLDER,
          }),
        },
        {
          text: '',
          dataField: 'id',
          formatter: (cell, row) => {
            if (!UNTOUCHABLE_USERS_EMAILS.includes(row.email) && props.isAdmin) {
              return (
                <Button
                  variant={row.activated ? 'danger' : 'success'}
                  size="sm"
                  onClick={
                  () => ConfirmDialog(
                    {
                      text: `Ar tikrai norite ${row.activated ? 'atleisti' : 'priimti atgal'} ${row.name}?`,
                      onConfirm: () => this.handleChangeStatusClick(row.id, !row.activated),
                    },
                  )
                }
                >
                {row.activated ? 'Atleisti' : 'Priimti atgal'}
                </Button>
              );
            }
            return '';
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.formatRows();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.formatRows();
    }
  }


  handleChangeStatusClick(id, activated) {
    const { handleChangeStatusClick } = this.props;
    handleChangeStatusClick(id, activated);
  }

  handleNameClick(id) {
    const { history } = this.props;
    history.push('/administration/users/edit', id);
  }

  formatRows() {
    const { data } = this.props;
    const rows = data.map((row) => ({
      id: row.id,
      name: row.name,
      role: row.role,
      email: row.email,
      salary: row.salary,
      rent: row.rent,
      phone: row.phone,
      activated: row.activated
    }));

    this.setState({
      rows,
    });
  }


  render() {
    const { rows, columns } = this.state;
    return (
      <>
        {rows && (
          <ToolkitProvider
            striped
            hover
            keyField="id"
            data={rows}
            columns={columns}
            bootstrap4
            search
          >
            { (props) => (
              <div className="d-flex flex-column">
                <SearchBar {...props.searchProps} />
                <BootstrapTable
                  pagination={paginationFactory()}
                  filter={filterFactory()}
                  {...props.baseProps}
                  bootstrap4
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </>
    );
  }
}

export default UsersTable;

UsersTable.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    salary: PropTypes.number.isRequired,
    rent: PropTypes.number.isRequired,
    phone: PropTypes.string.isRequired,
    activated: PropTypes.bool.isRequired
  })).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  handleChangeStatusClick: PropTypes.func,
};

UsersTable.defaultProps = {
  handleChangeStatusClick: null,
};
