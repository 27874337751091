import React from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Button, ButtonGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import JobUserStatusBadge from "../../shared/JobUserStatusBadge";
import { headerFormatter, userCellFormatter } from "../../../../utilities/TableHelper";
import { JOB_ADMIN_STATUS, JOB_RESPONSIBLE_PERSON_STATUS } from "../../util/JobStatus";
import { isAdminRole } from "../../../auth/AuthenticationService";

export const getColumns = (modalHandler, changeJobStatus, style, role) => [
  {
    text: "Darbas",
    dataField: "name",
    sort: true,
    headerFormatter,
    formatter: (cell, row, index) => (
        <NavLink to={`/jobs/${row.id}`}>
          <Button variant="outline-primary" style={style.navButton} size="sm">{cell}</Button>
        </NavLink>
    ),
    filter: textFilter({
      placeholder: "Ieškoti",
    }),
  },
  {
    text: "Darbą sukūrė",
    dataField: "createdBy",
    sort: true,
    headerFormatter,
    formatter: userCellFormatter
  },
  {
    text: "Atsakingas asmuo",
    dataField: "responsiblePerson",
    sort: true,
    headerFormatter,
    filter: textFilter({
      placeholder: "Ieškoti",
    }),
    formatter: userCellFormatter
  },
  {
    text: "Statusas",
    dataField: "status",
    sort: true,
    headerFormatter,
    formatter: (cell) => (
      <div className="d-flex justify-content-center">
        <JobUserStatusBadge status={cell} />
      </div>
    ),
  },
  {
    text: "Atsakingo asmens Statusas",
    dataField: "responsiblePersonStatus",
    sort: true,
    headerFormatter,
    formatter: (cell) => (
      <div className="d-flex justify-content-center">
        <JobUserStatusBadge status={cell} />
      </div>
    ),
  },
  {
    text: "Viso vienetų",
    dataField: "totalUnitsPerUsers",
    sort: true,
    headerFormatter,
    tooltipText: "Viso vienetų - nepriklausomai nuo darbuotojų darbo vienetų",
  },
  {
    text: "Viso darbuotojų",
    dataField: "jobUsersCount",
    sort: true,
    headerFormatter,
  },
  {
    text: "Darbuotojai",
    dataField: "id",
    sort: true,
    headerFormatter,
    formatter: (cell) => (
      <div className="d-flex justify-content-center">
        <Button variant="info" size="sm" onClick={() => modalHandler(cell)}>
          Rodyti darbuotojus
        </Button>
      </div>
    ),
  },
  {
    text: "Keisti statusą",
    dataField: "changeStatus",
    sort: true,
    headerFormatter,
    headerStyle: () => {
      return { width: "25%" };
    },
    formatter: (cell, row) => {
      const buttons = isAdminRole(role) ? adminStatusButtons : responsiblePersonStatusButtons;
      return (
          <ButtonGroup toggle className="d-flex justify-content-center">
            {buttons.map((item) => (
                <Button
                    key={item.id}
                    className="ml-1 mr-1"
                    variant={item.variant}
                    size="sm"
                    onClick={() => changeJobStatus(item.status, row)}
                >
                  {item.name}
                </Button>
            ))}
          </ButtonGroup>
      );
    },
  },
];

const adminStatusButtons = [
  {
    id: "danger",
    name: JOB_ADMIN_STATUS.REJECTED.name,
    variant: "danger",
    status: JOB_ADMIN_STATUS.REJECTED.code,
  },
  {
    id: "warning",
    name: JOB_ADMIN_STATUS.REKYL.name,
    variant: "warning",
    status: JOB_ADMIN_STATUS.REKYL.code,
  },
  {
    id: "success",
    name: JOB_ADMIN_STATUS.APPROVED.name,
    variant: "success",
    status: JOB_ADMIN_STATUS.APPROVED.code,
  },
];

const responsiblePersonStatusButtons = [
  {
    id: "danger",
    name: JOB_RESPONSIBLE_PERSON_STATUS.REJECTED.name,
    variant: "danger",
    status: JOB_RESPONSIBLE_PERSON_STATUS.REJECTED.code,
  },
  {
    id: "success",
    name: JOB_RESPONSIBLE_PERSON_STATUS.APPROVED.name,
    variant: "success",
    status: JOB_RESPONSIBLE_PERSON_STATUS.APPROVED.code,
  },
];

export const formatRows = (tableData) =>
  tableData &&
  tableData.reduce((result, row) => {
    const tempRowValue = {
      id: row.id,
      modalId: row.id,
      name: row.name,
      date: row.date,
      status: row.jobStatus.status,
      createdBy: row.createdBy,
      responsiblePerson: row.responsiblePerson,
      totalUnitsPerUsers: row.totalUnitsPerUsers,
      jobUsersCount: row.jobUsersCount,
      responsiblePersonStatus: row.jobStatus.responsiblePersonStatus,
    };
    result.push(tempRowValue);
    return result;
  }, []);
