export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

const initialState = {
    isAuthenticated: false,
    user: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                isAuthenticated: true,
                user: action.payload,
            };
        case CLEAR_CURRENT_USER: {
            return { ...initialState };
        }
        default:
            return state;
    }
};