import * as React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { headerFormatter } from '../../../utilities/TableHelper';
import {Button} from "react-bootstrap";
import ConfirmDialog from "../../shared/modals/ConfirmDialog";

class JobDescriptionTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: null,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    this.formatRows(data);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.formatRows(data);
    }
  }

  getColumns() {
    const { handleDelete } = this.props;
    return [
      {
        text: 'Darbo aprašymas',
        dataField: 'name',
        sort: true,
        headerFormatter,
      },
      {
        text: 'Veiksmas',
        dataField: 'id',
        sort: true,
        headerFormatter,
        formatter: (cell, row) => (
            <Button
                variant="danger"
                size="sm"
                onClick={
                  () => ConfirmDialog({ text: `Ar tikrai norite ištrinti ${row.name}?`, onConfirm: () => handleDelete(row.id) })
                }
            >
              Trinti
            </Button>
        ),
      },
    ];
  }

  formatRows(data) {
    const rows = data.map((row) => ({
      id: row.id,
      name: row.name,
    }));

    this.setState({
      rows,
    });
  }

  render() {
    const { rows } = this.state;

    return (
      <>
        { rows && (
          <BootstrapTable
            keyField="id"
            data={rows}
            columns={this.getColumns()}
            bootstrap4
            striped
            hover
          />
        )}
      </>
    );
  }
}

export default JobDescriptionTable;

JobDescriptionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  handleDelete: PropTypes.func.isRequired,
};
