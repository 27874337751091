import { JOB_TYPE } from "../form/constants";

export const toFormView = (data) => ({
    name: data.name,
    date: data.date,
    descriptions: data.descriptions ? data.descriptions : [],
    description: data.description,
    note: data.note || '',
    responsiblePersonNote: data.responsiblePersonNote || '',
    adminNote: data.adminNote || '',
    responsiblePerson: data.responsiblePerson ? [ data.responsiblePerson ] : [],
    jobType: data.jobType || JOB_TYPE.STANDARD.value,
    totalJobHours: data.totalHours,
    users: data.users.map(toFormUserView),
    unitMeId: data.jobUnit?.id || undefined,
    totalMined: data.totalMined || 0,
    minMined: data.minMined,
    files: data.files,
    tempFiles: [],
    projectId: data?.projectId,
    meters: data?.meters
});

const toFormUserView = ({ id, user, hours, readOnly, unitMeId }) => ({
    id,
    user: user ? [ user ] : [],
    unitMeId,
    hours,
    readOnly
});

const isStandard = type => type === JOB_TYPE.STANDARD.value;

export const toDTO = (form) => {
    const dto = mapJobFormToDTO(form);
    const userMapper = isStandard(form.jobType)
        ? mapStandardJobUserFormToDTO
        : mapMiningJobUserFormToDTO;

    return {
        ...dto,
        existingUsers: form.users.filter(u => !!u.id).map(user => userMapper(user, form.unitMeId)),
        users: form.users.filter(u => !u.id).map(user => userMapper(user, form.unitMeId))
    };
}

const mapJobFormToDTO = form => ({
    name: form.name,
    description: form.description,
    note: form.note,
    responsiblePersonNote: form.responsiblePersonNote,
    adminNote: form.adminNote,
    date: form.date,
    descriptions: form.descriptions.map(jd => jd.id),
    responsiblePersonId: form.responsiblePerson[0]?.userId,
    jobType: form.jobType,
    unitMeId: form?.unitMeId || undefined,
    totalMined: form?.totalMined || 0,
    projectId: form?.projectId || undefined,
    meters: form?.meters || undefined
});

const mapStandardJobUserFormToDTO = ({ id, user, unitMeId, hours }) => ({
    id,
    userId: user[0]?.userId,
    unitMeId,
    hours
});

const mapMiningJobUserFormToDTO = ({ id, user }, unitMeId) => ({
    id,
    userId: user[0].userId,
    unitMeId,
    hours: 0
});
