import PropTypes from "prop-types";
import * as React from "react";

class TextOrNothing extends React.Component {
    render() {
        const { object, field } = this.props;
        return (
            <>{object ? object[field] : ''}</>
        )
    }
}

export default TextOrNothing;

TextOrNothing.propTypes = {
    object: PropTypes.object,
    field: PropTypes.string.isRequired,
};

TextOrNothing.defaultProps = {
    object: null,
};

