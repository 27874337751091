import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import "./DMScrollableUserSearch.scss"
import DMChatAvatar from "./DMChatAvatar";
import {DEFAULT_PAGINATION} from "./DMChat";
import {FaTrash} from "react-icons/all";
import Button from "react-bootstrap/Button";

const DMScrollableUserSearch = ({filteredUsers, setCurrentSelectedUser,
                                    currentSelectedUser, getRecentChatsByUser, setSelectedUserChat, setPageNr, onDeleteClick}) => {

    const onUserCardClick = (user) => {
        setCurrentSelectedUser(user)
        setSelectedUserChat(null)
        setPageNr(DEFAULT_PAGINATION.pageNr)
        getRecentChatsByUser(user.id, DEFAULT_PAGINATION.pageNr, DEFAULT_PAGINATION.size)
    }

    const userCardDisabledForChosenUser = (user) => {
        if(!currentSelectedUser) return false;
        return user.id === currentSelectedUser.id
    }

    return (
        <div className={"dm-scrollable-user-search__wrapper"}>
            <ul className="list-unstyled">
                {filteredUsers && filteredUsers.length > 0 ? filteredUsers.map(user => (<li key={user.id} className="dm-scrollable-user-search__list-item">
                    <button className={`dm-scrollable-user-search__button ${(currentSelectedUser && currentSelectedUser.id === user.id) && "active"}`}
                            onClick={() => onUserCardClick(user)}
                            disabled={userCardDisabledForChosenUser(user)}
                    >
                        <div className={"d-flex justify-content-between w-100"}>
                            <div className={"d-flex"}>
                                <div>
                                    <DMChatAvatar userName={`${user.name?.trim()} ${user.surname?.trim()}`} />
                                </div>
                                <div>
                                    <p className="dm-scrollable-user-search__name">{user.name.trim()} {user.surname.trim()}</p>
                                </div>
                            </div>
                            <div>
                                <p className="dm-scrollable-user-search__message-time">{user.latestMessage ? moment(user.latestMessageCreatedAt).format("YYYY-MM-DD HH:mm") : "N/A"}</p>
                            </div>
                        </div>
                        <div className={"d-flex justify-content-between w-100"}>
                            <div className={"w-65"}>
                                <p className="dm-scrollable-user-search__latest-message p-sm-0">{user.latestMessage ? user.latestMessage : "Pokalbis nepradėtas"}</p>
                            </div>
                            <div className={"w-35 d-flex justify-content-end align-items-center"}>
                                {user.unreadMessagesCount ? <div className="dm-scrollable-user-search__unread-message-count">{user.unreadMessagesCount}</div> : ""}
                                { !userCardDisabledForChosenUser(user) &&
                                <Button variant="danger"
                                        size="sm"
                                        onClick={() => onDeleteClick(user?.id)}>
                                    <FaTrash/>
                                </Button> }
                            </div>
                        </div>
                    </button>
                </li>)) : <h2>Vartotojų nerasta</h2>}
            </ul>
        </div>
    );
};

DMScrollableUserSearch.propTypes = {
    filteredUsers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        latestMessage: PropTypes.string,
        latestMessageCreatedAt: PropTypes.string,
        phoneNr: PropTypes.string,
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        unreadMessagesCount: PropTypes.number
    })),
    currentSelectedUser: PropTypes.shape({
        id: PropTypes.number.isRequired,
        latestMessage: PropTypes.string,
        latestMessageCreatedAt: PropTypes.string,
        phoneNr: PropTypes.string,
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        unreadMessagesCount: PropTypes.number
    }),
    setCurrentSelectedUser: PropTypes.func.isRequired,
    getRecentChatsByUser: PropTypes.func.isRequired,
    setSelectedUserChat: PropTypes.func.isRequired,
    setPageNr: PropTypes.func.isRequired
};

export default DMScrollableUserSearch;
