import * as React from "react";
import { Badge } from "react-bootstrap";
import * as PropTypes from "prop-types";
import { FaExclamation, FaQuestion, TiTick } from 'react-icons/all';
import { JOB_RESPONSIBLE_PERSON_STATUS } from "../util/JobStatus";

class JobUserTlStatusIconBadge extends React.Component {
    render() {
        const { status } = this.props;
        let variant, label;
        switch (JOB_RESPONSIBLE_PERSON_STATUS[status].code) {
            case JOB_RESPONSIBLE_PERSON_STATUS.NOT_APPROVED.code:
                variant = 'secondary';
                label = <FaQuestion/>;
                break;
            case JOB_RESPONSIBLE_PERSON_STATUS.APPROVED.code:
                variant = 'success';
                label = <TiTick />;
                break;
            default:
                variant = 'danger';
                label = <FaExclamation/>
                break;
        }
        return (
          <Badge pill variant={variant}>{label}</Badge>
        );
    }
}

export default JobUserTlStatusIconBadge;

JobUserTlStatusIconBadge.propTypes = {
    status: PropTypes.string.isRequired,
}
