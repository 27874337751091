import React from "react"
import {
    IconAndroidEdgeAddToPhone,
    IconAndroidFirefoxInstall,
    IconAndroidOperaHomeScreen,
    IconIOsQuickActionShare, IconKebabHorizontalMenu,
    IconKebabMenu
} from "./GuideIcons";

export const installationInstructions = {
    iOS: {
        MobileSafari: <span>Galite instaliuoti šią programą paspaudus <IconIOsQuickActionShare/> "Add to Home Screen"</span>,
    },
    Android: {
        Opera: <span>Galite instaliuoti šią programą paspaudus <IconKebabMenu/> <IconAndroidOperaHomeScreen/> "Home Screen".</span>,
        Firefox: <span>Galite instaliuoti šią programą paspaudus <IconKebabMenu/> <IconAndroidFirefoxInstall/> "Install".</span>,
        Edge: <span>Galite instaliuoti šią programą paspaudus <IconKebabHorizontalMenu/> <IconAndroidEdgeAddToPhone/> "Add to phone".</span>,
    }
}