import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoginComponent from './Components/auth/LoginComponent';
import Navigation from './Components/shared/navigation/Navigation';
import ReportsHome from './Components/reports/ReportsHome';
import AuthenticatedRoute from './Components/auth/AuthenticatedRoute';
import NotFoundComponent from './Components/errorPages/NotFoundComponent';
import JobsHome from './Components/jobs/JobsHome';
import AddJob from './Components/jobs/AddJob';
import EditJob from './Components/jobs/EditJob';
import SettingsHome from './Components/settings/SettingsHome';
import ProfileHome from './Components/profile/ProfileHome';
import RegisterComponent from './Components/auth/RegisterComponent';
import AdminUsersHome from './Components/users/admin/AdminUsersHome';
import AddUser from './Components/users/admin/AddUser';
import EditUser from './Components/users/admin/EditUser';
import UsersHome from './Components/users/UsersHome';
import LogoutComponent from './Components/auth/LogoutComponent';
import HistoryHome from './Components/history/HistoryHome';
import { isUserLoggedIn } from './Components/auth/AuthenticationService';
import CompanyHome from './Components/company/CompanyHome';
import EditCompany from './Components/company/EditCompany';
import AddCompany from './Components/company/AddCompany';
import CustomerHome from "./Components/customers/CustomerHome";
import AddCustomer from "./Components/customers/AddCustomer";
import EditCustomer from "./Components/customers/EditCustomer";
import JobReportsHome from "./Components/jobs/reports/JobReportsHome";
import JobsByDateHome from './Components/jobs/reports/date/JobsByDateHome';
import ResetPasswordComponent from "./Components/auth/ResetPasswordComponent";
import ChangeForgottenPassComponent from "./Components/auth/ChangeForgottenPassComponent";
import NotificationHome from "./Components/notification/NotificationHome";
import VacationsHome from "./Components/vacations/VacationsHome";
import AddVacation from "./Components/vacations/AddVacation";
import CalendarView from "./Components/calendar/CalendarView";
import Chat from "./Components/messages/conversations/chat/Chat";
import MessagingGateway from "./Components/messages/gateway/MessagingGateway";
import OffDutyUsers from "./Components/users/offduty/OffDutyUsers";
import { getIsNavigationDisplayed } from "./redux/navigation/navigationSelectors";
import DMChat from "./Components/direct_messaging/DMChat";


class Router extends React.Component {
  render() {
    const { isNavigationDisplayed } = this.props;
    return (<React.Fragment>
          <BrowserRouter forceRefresh>
            { isUserLoggedIn() ? (
              <>
                {isNavigationDisplayed && <Navigation />}
                <MessagingGateway/>
                <Switch>
                  <Route path="/logout" component={LogoutComponent} />
                  <Route path="/login" exact component={LoginComponent} />
                  <Route path="/reset-pass" exact component={ResetPasswordComponent} />
                  <Route path="/change-forgotten-pass/:token" exact component={ChangeForgottenPassComponent} />
                  <AuthenticatedRoute path="/jobs" exact component={JobsHome} />
                  <AuthenticatedRoute path="/jobs/add" exact component={AddJob} />
                  <AuthenticatedRoute path="/jobs/:id" exact component={EditJob} />
                  <AuthenticatedRoute path="/profile" exact component={ProfileHome} />
                  <AuthenticatedRoute path="/" exact component={JobsHome} />
                  <AuthenticatedRoute path="/users/off-duty" exact component={OffDutyUsers} />
                  <AuthenticatedRoute path="/users" exact component={UsersHome} />
                  <AuthenticatedRoute path="/management/jobs/reports" exact component={JobReportsHome} />
                  <AuthenticatedRoute path="/management/jobs/date" exact component={JobsByDateHome} />
                  <AuthenticatedRoute path="/administration/history" exact component={HistoryHome} />
                  <AuthenticatedRoute path="/administration/users" exact component={AdminUsersHome} />
                  <AuthenticatedRoute path="/administration/users/add" exact component={AddUser} />
                  <AuthenticatedRoute path="/administration/users/edit" exact component={EditUser} />
                  <AuthenticatedRoute path="/administration/reports" exact component={ReportsHome} />
                  <AuthenticatedRoute path="/administration/settings" exact component={SettingsHome} />
                  <AuthenticatedRoute path="/administration/settings/unit-measurement" exact component={SettingsHome} />
                  <AuthenticatedRoute path="/administration/settings/user-duties" exact component={SettingsHome} />
                  <AuthenticatedRoute path="/administration/settings/job-descriptions" exact component={SettingsHome} />
                  <AuthenticatedRoute path="/administration/company" exact component={CompanyHome} />
                  <AuthenticatedRoute path="/administration/company/add" exact component={AddCompany} />
                  <AuthenticatedRoute path="/administration/company/:id" exact component={EditCompany} />
                  <AuthenticatedRoute path="/administration/customer" exact component={CustomerHome} />
                  <AuthenticatedRoute path="/administration/customer/add" exact component={AddCustomer} />
                  <AuthenticatedRoute path="/administration/customer/:id" exact component={EditCustomer} />
                  <AuthenticatedRoute path="/notifications" exact component={NotificationHome} />
                  <AuthenticatedRoute path="/calendar" exact component={CalendarView} />
                  <AuthenticatedRoute path="/vacations" exact component={VacationsHome} />
                  <AuthenticatedRoute path="/vacations/add" exact component={AddVacation} />
                  <AuthenticatedRoute path="/conversations/:id" exact component={Chat} />
                  <AuthenticatedRoute path="/messaging" exact component={DMChat} />
                  <Route path="*" component={NotFoundComponent} />
                </Switch>
              </>
            )
              : (
                <Switch>
                  <Route path="/login" component={LoginComponent} />
                  <Route path="/reset-pass" exact component={ResetPasswordComponent} />
                  <Route path="/change-forgotten-pass/:token" exact component={ChangeForgottenPassComponent} />
                  <Route path="/register/:token" exact component={RegisterComponent} />
                  <Route path="*" component={NotFoundComponent} />
                </Switch>
              )}
          </BrowserRouter>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isNavigationDisplayed: getIsNavigationDisplayed(state),
  };
}
export default connect(mapStateToProps)(Router);

Router.propTypes = {
  isNavigationDisplayed: PropTypes.bool.isRequired
}
