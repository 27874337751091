
class ProfileSchema {
  constructor() {
    this.uiSchema = {
      password: {
        'ui:options': {
          inputType: 'password',
          placeholder: 'Įveskite slaptažodį norėdami atnaujinti profilį',
        },
      },
    };
  }

  initSchema(phone) {
    return {
      type: 'object',
      required: ['password', 'phone'],
      properties: {
        password: { type: 'string', title: 'Slaptažodis', minLength: 7 },
        phone: {
          type: 'string', title: 'Tel. nr.', minLength: 9, default: phone,
        },
      },
    };
  }
}

export default (new ProfileSchema());
