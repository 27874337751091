import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/en-gb';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CALENDAR_MESSAGES, CALENDAR_VIEWS, eventPropGetter } from "./calendarConstants";

moment.locale("en-GB");
export const localizer = momentLocalizer(moment);

class CalendarContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            view: CALENDAR_VIEWS.MONTH,
            range: []
        };

        this.onRangeChange = this.onRangeChange.bind(this);
        this.onViewChange = this.onViewChange.bind(this);
        this.constructDateFilter = this.constructDateFilter.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { view, range } = this.state;
        const { onRangeChange } = this.props;

        if (prevState.view !== view || prevState.range !== range) {
            onRangeChange(this.constructDateFilter(range, view));
        }
    }

    constructDateFilter(range, view) {
        switch (view) {
            case CALENDAR_VIEWS.WEEK:
                return {
                    start: moment(range[0]).toDate(),
                    end: moment(range.pop()).toDate()
                }
            case CALENDAR_VIEWS.DAY:
                return {
                    start: moment(range[0]).toDate(),
                    end: moment(range[0]).add(1, 'days').toDate()
                }
            default:
                return range;
        }
    }

    onViewChange(view) {
        this.setState({
            view
        });
    }

    onRangeChange(range) {
        this.setState({
            range
        });
    }

    render() {
        const { events } = this.props;

        return (<Calendar
            events={events}
            messages={CALENDAR_MESSAGES}
            startAccessor="start"
            endAccessor="end"
            onView={this.onViewChange}
            onRangeChange={this.onRangeChange}
            views={Object.values(CALENDAR_VIEWS)}
            culture="en-GB"
            localizer={localizer}
            eventPropGetter={eventPropGetter}
            step={60}
            showMultiDayTimes
            style={{ height: "80vh" }}
            defaultDate={moment().toDate()}
        />);
    }
}

CalendarContainer.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        start: PropTypes.object.isRequired,
        end: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        createdBy: PropTypes.object.isRequired,
        description: PropTypes.string.isRequired
    }).isRequired).isRequired,
    onRangeChange: PropTypes.func.isRequired
}

export default CalendarContainer;
