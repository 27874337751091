import { Badge } from "react-bootstrap";
import getUserColorByRole from "../../../utilities/getUserColorByRole";
import React from "react";

export default ({ user }) => (
    <Badge pill
           variant="info"
           style={{ backgroundColor: getUserColorByRole(user.role) }}
           key={user.userId}>
        {user.name}
    </Badge>);