import * as React from 'react';
import PropTypes from 'prop-types';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import CalcHelper from '../../utilities/CalcHelper';
import { headerFormatter, userCellFormatter, zeroValueFormatter } from '../../utilities/TableHelper';
import './ReportsTable.css';
import { formatStandartDate } from '../../utilities/DateHelper';
import { Button } from 'react-bootstrap';

class ReportsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
    };

    this.afterSaveCell = this.afterSaveCell.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    this.formatRows(data);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.formatRows(data);
    }
  }

  getColumns() {
    const { handleExtrasChange } = this.props;
    return [
      {
        text: 'Vardas',
        dataField: 'name',
        sort: true,
        editable: false,
        headerFormatter,
        filter: textFilter({
          placeholder: 'Ieškoti',
        }),
        formatter: (cell, row) => userCellFormatter(row.user)
      },
      {
        text: 'Valandinis',
        dataField: 'salary',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#c7a488'
        },
        formatter: zeroValueFormatter
      },
      {
        text: '1-15d.',
        dataField: 'firstHalf',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#b6b5b5'
        },
        formatter: zeroValueFormatter
      },
      {
        text: '16-31d.',
        dataField: 'secondHalf',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#b6b5b5'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'Suma',
        editable: false,
        dataField: 'sumHalfs',
        sort: true,
        headerFormatter,
        style: {
          backgroundColor: '#849fb0'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'Nuoma',
        dataField: 'rent',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#b3b89d'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'Vyk. raštai',
        dataField: 'executiveLogs',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#9a5d74'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'Kelionės išlaidos',
        dataField: 'travelExpenses',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#a5753a'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'Bauda',
        dataField: 'penalty',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#bd635c'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'Avansas',
        dataField: 'advancedPayment',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#bd635c'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'kasimai / slypai 1-15',
        dataField: 'fieldFirstHalf',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#d5af47'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'kasimai / sklypai 16-31:',
        dataField: 'fieldSecondHalf',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#d5af47'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'Už 100',
        dataField: 'forHundred',
        headerFormatter,
        sort: true,
        style: {
          backgroundColor: '#d5af47'
        },
        formatter: zeroValueFormatter
      },
      {
        text: 'Papildomi',
        dataField: 'extra',
        headerFormatter,
        sort: true,
        editable: false,
        formatter: (cell, row) => (
          <Button variant="info" size="sm" disabled={cell === null} onClick={() => handleExtrasChange(cell)}>
            {`Priedai (${row.totalExtra ? row.totalExtra : 0})`}
          </Button>
        ),
      },
      {
        text: 'Komentaras',
        editable: true,
        dataField: 'comment',
        sort: false,
        headerFormatter,
      },
      {
        text: 'Suma',
        editable: false,
        dataField: 'sumTotal',
        sort: true,
        headerFormatter,
        style: {
          backgroundColor: '#a2b17f'
        },
        formatter: zeroValueFormatter
      },
    ];
  }

  // formats rows. If we don't have report data, we set everything to 0, and take data from user
  // if we have data for report, we set it for the table to handle it
  formatRows(data) {
    // TODO: make sure it assigns id!!!!

    const rows = data.map((row) => {
      if (row.report) {
        return ({
          id: row.report.id,
          user: row.user,
          userId: row.user.userId,
          name: row.user.name,
          firstHalf: row.report.firstHalf,
          secondHalf: row.report.secondHalf,
          sumHalfs: CalcHelper.safeAdd(row.report.firstHalf, row.report.secondHalf),
          executiveLogs: row.report.executiveLogs,
          travelExpenses: row.report.travelExpenses,
          penalty: row.report.penalty,
          advancedPayment: row.report.advancedPayment,
          fieldFirstHalf: row.report.fieldFirstHalf,
          fieldSecondHalf: row.report.fieldSecondHalf,
          forHundred: row.report.forHundred,
          salary: row.report.salary,
          rent: row.report.rent,
          comment: row.report.comment,
          extra: row.report.id,
          totalExtra: row.report.totalExtra,
          sumTotal: CalcHelper.calcTotalAmount(row.report),
        });
      }
      return ({
        id: null,
        user: row.user,
        userId: row.user.userId,
        name: row.user.name,
        firstHalf: 0,
        secondHalf: 0,
        sumHalfs: 0,
        executiveLogs: 0,
        travelExpenses: 0,
        penalty: 0,
        advancedPayment: 0,
        fieldFirstHalf: 0,
        fieldSecondHalf: 0,
        forHundred: 0,
        salary: row.user.salary,
        rent: row.user.rent,
        totalExtra: 0,
        comment: '',
        extra: null,
        sumTotal: 0,
      });
    });

    this.setState({
      rows,
    });
  }

  afterSaveCell(oldValue, newValue, row) {
    // TOTALS
    const sumHalfs = CalcHelper.safeAdd(row.firstHalf, row.secondHalf);
    const sumTotal = CalcHelper.calcTotalAmount(row);
    Object.assign(row, { sumHalfs });
    Object.assign(row, { sumTotal });


    // API data
    const { period, handleCellSave } = this.props;
    const date = formatStandartDate(period);

    Object.assign(row, { date });
    Object.assign(row, { user: { id: row.userId } });

    // if the field is null, lets make it a zero
    Object.assign(row, {
      firstHalf: row.firstHalf === null ? 0 : row.firstHalf,
      secondHalf: row.secondHalf === null ? 0 : row.secondHalf,
      sumHalfs: row.sumHalfs === null ? 0 : row.sumHalfs,
      executiveLogs: row.executiveLogs === null ? 0 : row.executiveLogs,
      travelExpenses: row.travelExpenses === null ? 0 : row.travelExpenses,
      penalty: row.penalty === null ? 0 : row.penalty,
      advancedPayment: row.advancedPayment === null ? 0 : row.advancedPayment,
      fieldFirstHalf: row.fieldFirstHalf === null ? 0 : row.fieldFirstHalf,
      fieldSecondHalf: row.fieldSecondHalf === null ? 0 : row.fieldSecondHalf,
      forHundred: row.forHundred === null ? 0 : row.forHundred,
      salary: row.salary === null ? 0 : row.salary,
      rent: row.rent === null ? 0 : row.rent,
      totalExtra: row.totalExtra === null ? 0 : row.totalExtra,
    });
    handleCellSave(row);
    return row;
  }

  render() {
    const { rows } = this.state;
    const columns = this.getColumns();

    return (
            <BootstrapTable
              keyField="userId"
              data={rows}
              columns={columns}
              cellEdit={cellEditFactory({
                mode: 'click',
                afterSaveCell: this.afterSaveCell,
              })}
              filter={filterFactory()}
              rowClasses={() => 'report-table-row'}
              bootstrap4
              striped
              hover
              condensed
            />
    );
  }
}

export default ReportsTable;

ReportsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.shape({
      userId: PropTypes.number.isRequired,
      salary: PropTypes.number.isRequired,
      rent: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    report: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstHalf: PropTypes.number,
      secondHalf: PropTypes.number,
      executiveLogs: PropTypes.number,
      travelExpenses: PropTypes.number,
      penalty: PropTypes.number,
      advancedPayment: PropTypes.number,
      fieldFirstHalf: PropTypes.number,
      fieldSecondHalf: PropTypes.number,
      forHundred: PropTypes.number,
      salary: PropTypes.number,
      rent: PropTypes.number,
      reportSalary: PropTypes.number,
      reportRent: PropTypes.number,
      comment: PropTypes.string,
      totalExtra: PropTypes.number,
    }),
  })).isRequired,
  period: PropTypes.object.isRequired,
  handleExtrasChange: PropTypes.func.isRequired,
  handleCellSave: PropTypes.func.isRequired,
};
