import * as React from 'react';
import { Alert, Container } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import axios from 'axios';
import FormSubmitBtn from '../shared/util/FormSubmitBtn';
import CustomerSchema from '../../schemas/CustomerSchema';
import { handleError } from '../../utilities/ErrorHandler';

class AddCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValues: null,
      error: null,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
  }

  onError(error) {
    this.setState({
      error,
    });
  }

  onSubmit() {
    const { formValues } = this.state;
    axios.post(`${process.env.REACT_APP_API_URL}/customer`, formValues)
      .then((response) => {
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        this.props.history.push(`/administration/customer/${response.data.id}`);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleFormChange(e) {
    this.setState({
      formValues: e.formData,
    });
  }

  render() {
    const { formValues, error } = this.state;
    return (
      <Container>
        <h2 className="text-center">Kurti naują klientą</h2>
        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}
        <Form
          schema={CustomerSchema.initSchema()}
          formData={formValues}
          onChange={this.handleFormChange}
          onSubmit={this.onSubmit}
          uiSchema={CustomerSchema.uiSchema}
        >
          <FormSubmitBtn />
        </Form>
      </Container>
    );
  }
}

export default AddCustomer;
