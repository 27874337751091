import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { Form } from 'react-bootstrap';
import { getColumns } from './constants/columnDefs';

export default (
    {
        isShowAllNotifications,
        onShowAllNotifications,
        notifications,
        onChangeNotificationStatus,
        onConversationClick,
        onDownloadFile
    }) => (
    <Form>
        <Form.Check
            checked={isShowAllNotifications}
            onChange={onShowAllNotifications}
            type="checkbox"
            label="Rodyti visus ir perskaitytus pranešimus"
            id="show-all-notifications"
        />
        <BootstrapTable
            className="mt-2"
            keyField="id"
            data={notifications}
            columns={getColumns(onChangeNotificationStatus, onConversationClick, onDownloadFile)}
            filter={filterFactory()}
            bootstrap4
            striped
            hover
            condensed
        />
    </Form>
);