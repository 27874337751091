import React from 'react';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/auth/authActions';
import { getAuthState } from "../../redux/auth/authSelectors";

class LogoutComponent extends React.Component {

  componentDidMount() {
    const { logoutAction } = this.props;
    logoutAction();
    window.location.href = '/login';
  }

  render() {
    return (
      <>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: getAuthState(state),
  };
}

export default connect(mapStateToProps, { logoutAction })(LogoutComponent);
