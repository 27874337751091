import { SET_JOB_PAGE_SCROLL_Y } from "./jobActions";

const initialState = {
  jobPageScrollY: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_JOB_PAGE_SCROLL_Y:
      return {
        jobPageScrollY: action.jobPageScrollY,
      };
    default:
      return state;
  }
};
