import * as React from 'react';
import { Alert, Container } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import UserSchema from '../../../schemas/UserSchema';
import FormSubmitBtn from '../../shared/util/FormSubmitBtn';
import { customSelectComponent, datePickerWidget } from '../../../schemas/Widgets';
import { formatStandartDate } from '../../../utilities/DateHelper';
import { handleError } from '../../../utilities/ErrorHandler';
import SearchableUserSelector from "../../shared/select/user/SearchableUserSelector";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: null,
      error: null,
      backendData: null,
      duties: null,
      isInvited: false,
      isSuccess: false,
      employmentDate: null,
      firedDate: null,
      rentStartDate: null,
      subordinates: [],
      users: []
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.fetchUserDetails = this.fetchUserDetails.bind(this);
    this.handleInviteClick = this.handleInviteClick.bind(this);
    this.handleFiredDateChange = this.handleFiredDateChange.bind(this);
    this.handleEmploymentDateChange = this.handleEmploymentDateChange.bind(this);
    this.handleRentStartDateChange = this.handleRentStartDateChange.bind(this);
    this.handleIsManager = this.handleIsManager.bind(this);
    this.handleOnSubordinatesChange = this.handleOnSubordinatesChange.bind(this);
    this.handleJobUsersFilterChange = this.handleJobUsersFilterChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const { state } = location;
    this.fetchUserDetails(state);
  }

  handleFormChange(e) {
    const { duties, backendData } = this.state;
    if (e.formData.duties && e.formData.duties !== duties) {
      const { salary } = backendData.duties.all.find((x) => x.id === e.formData.duties);
      this.setState({
        formValues: { ...e.formData, salary },
        duties: e.formData.duties,
      });
      return;
    }
    this.setState({
      formValues: e.formData,
    });
  }

  onError(error) {
    this.setState({
      error,
    });
  }

  onSubmit() {
    const { formValues, subordinates } = this.state;
    const { location } = this.props;
    const { state } = location;
    Object.assign(formValues, {
      ...formValues,
      id: state,
      userDuty: { id: formValues.duties },
      email: !formValues.email ? null : formValues.email,
      duties: !formValues.duties ? null : formValues.duties,
      subordinates: !subordinates ? null : subordinates,
      manager: !formValues.manager ? false : true,
    });
    axios.put(`${process.env.REACT_APP_API_URL}/user/${state}`, formValues)
      .then((response) => {
        this.setState({
          isSuccess: true,
        });
        this.handleSingleUserResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  fetchUserDetails(id) {
    axios.get(`${process.env.REACT_APP_API_URL}/user/${id}`)
      .then((response) => {
        this.handleSingleUserResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
      });
  }

  handleSingleUserResponse(userData) {
    const data = userData.user;
    const users = userData.users;
    const formValues = Object.assign(data, {
      email: !data.email ? '' : data.email,
      duties: !data.duties ? '' : data.duties,
      firedDate: !data.firedDate ? '' : data.firedDate,
    });
    this.setState({
      error: null,
      formValues,
      employmentDate: data.employmentDate,
      firedDate: data.firedDate,
      rentStartDate: data.rentStartDate,
      backendData: UserSchema.formatBackendData(data),
      users,
      subordinates: [...data.subordinates]
    });
  }

  handleInviteClick() {
    const { location } = this.props;
    const { state } = location;
    this.setState({
      isInvited: true,
      isSuccess: false,
    });

    axios.post(`${process.env.REACT_APP_API_URL}/user/invite/${state}`)
      .then((response) => {
        this.handleSingleUserResponse(response.data);
      })
      .catch((err) => {
        handleError(err, this.onError);
        this.setState({
          isSuccess: false,
          isInvited: false,
        });
      });
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  canUserBeInvited() {
    const { formValues } = this.state;

    if (!formValues) {
      return false;
    }

    if (formValues.isInvited) {
      return false;
    }

    if (!formValues.possibleToInvite) {
      return false;
    }

    return this.validateEmail(formValues.email);
  }


  handleEmploymentDateChange(value) {
    const { formValues } = this.state;
    const employmentDate = formatStandartDate(value);
    this.setState({
      formValues: { ...formValues, employmentDate },
      employmentDate,
    });
  }

  handleFiredDateChange(value) {
    const { formValues } = this.state;
    const firedDate = formatStandartDate(value);
    this.setState({
      formValues: { ...formValues, firedDate },
      firedDate,
    });
  }

  handleRentStartDateChange(value) {
    const { formValues } = this.state;
    const rentStartDate = formatStandartDate(value);
    this.setState({
      formValues: { ...formValues, rentStartDate },
      rentStartDate,
    });
  }

  handleIsManager(manager) {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, manager },
      manager,
    });
  }

  handleOnSubordinatesChange(subordinates) {
    const { formValues } = this.state;
    this.setState({
      formValues: { ...formValues, subordinates },
      subordinates,
    });
  }

  handleJobUsersFilterChange(users = []) {
    this.setState({
          subordinates: users
        });
  }

  render() {
    const {
      formValues, error, backendData, isInvited, employmentDate, firedDate, rentStartDate, isSuccess} = this.state;

    const widgets = {
      employmentDatePicker: (props) => datePickerWidget(props, employmentDate, this.handleEmploymentDateChange),
      firedDatePicker: (props) => datePickerWidget(props, firedDate, this.handleFiredDateChange),
      rentStartDatePicker: (props) => datePickerWidget(props, rentStartDate, this.handleRentStartDateChange),
      // CheckboxWidget: (props) => checkboxWidget(props, formValues.manager, this.handleIsManager),
      CustomSelect: (props) => customSelectComponent(props, formValues.subordinates, this.handleOnSubordinatesChange),
    };

    return (
      <Container>
        <h2 className="text-center">
          Redaguoti vartotoją
          {' '}
          {formValues && formValues.name}
        </h2>
        {isSuccess && (
          <Alert variant="success" className="my-2">
            Vartotojas atnaujintas sėkmingai!
          </Alert>
        )}
        {error && (
        <Alert variant="danger" className="my-2">
          {error}
        </Alert>
        )}
        { backendData && (
            <div className={"m-1 p-1"}>
              <Form
                schema={UserSchema.initSchema(backendData)}
                validate={UserSchema.validate}
                showErrorList={false}
                formData={formValues}
                onChange={this.handleFormChange}
                onSubmit={this.onSubmit}
                uiSchema={UserSchema.uiSchema}
                widgets={widgets}
              >
                {!isInvited && (
                <div className="d-flex mb-4">
                  <Button variant="primary" type="button" disabled={!this.canUserBeInvited()} onClick={this.handleInviteClick}>Pakviesti į sistemą</Button>
                </div>
                )}
                {isInvited && (
                  <Alert variant="success" className="d-flex mb-2">Vartotojas sėkmingai pakviestas į sitemą.</Alert>
                )}
                <div className={"px-3"}>
                  <input type="checkbox" onChange={(e)=> this.handleIsManager(e.currentTarget.checked)} defaultChecked={formValues.manager} id={"isManager"} />
                  <label htmlFor={"isManager"} className={"ml-2"}>Ar vartotojas turi pavaldinių?</label>
                </div>
                {formValues.manager &&
                    <div className="p-3">
                      <SearchableUserSelector
                          className="w-100 p-0"
                          placeholder="Pasirinkite kuriuos pavaldinius norite priskirti prie vadovo"
                          options={[...this.state.users]}
                          multiple
                          onChange={this.handleJobUsersFilterChange}
                          labelKey="name"
                          id="job-creators"
                          selected={[...this.state.subordinates]}
                      />
                    </div>
                }
                <div className={"mb-2"}>
                  <FormSubmitBtn />
                </div>
              </Form>
            </div>
        )}
      </Container>
    );
  }
}

export default EditUser;
