import PropTypes from 'prop-types';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Modal } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';

const ConfirmDialog = (props) => confirmAlert({
  customUI: ({ onClose }) => (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.text}</Modal.Title>
      </Modal.Header>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={onClose}>
      Ne
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            props.onConfirm();
            onClose();
          }}
        >
      Taip!
        </Button>
      </Modal.Footer>
    </Modal>
  ),
});

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
