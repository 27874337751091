import * as React from "react";
import { Form } from "react-bootstrap";

const UploadFile = ({ onUploadFile }) => (
    <Form.File
        id="file-id"
        label="Pridėkite failą"
        data-browse="Ieškoti"
        onChange={(event) => {
            onUploadFile(event.target.files[0]);
            event.target.value = null;
        }}
        accept="
             application/msword,
             application/vnd.ms-excel,
             application/vnd.ms-powerpoint,
             text/plain,
             application/pdf,
             image/*"
        custom
    />
);

export default UploadFile;

