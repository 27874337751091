import { Badge, Card } from 'react-bootstrap';
import * as React from 'react';
import PropTypes from 'prop-types';

class StatList extends React.Component {
  render() {
    const { elements } = this.props;
    return (
      <div className="d-flex p-0" style={{maxWidth: '100%', overflow: 'auto'}}>
        {[...elements.keys()].map((key) => (
          <Card body key={key + elements.get(key)} className="ml-1" style={{minWidth: '150px'}}>
            {key}
            {': '}
            <Badge pill variant="primary">{elements.get(key)}</Badge>
          </Card>
        ))}
      </div>
    );
  }
}

export default StatList;

StatList.propTypes = {
  elements: PropTypes.instanceOf(Map).isRequired,
};
