import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Container } from 'react-bootstrap';
import axios from "axios";
import DatePicker from "react-datepicker";
import lt from "date-fns/locale/lt";
import OffDutyUsersTable from "./OffDutyUsersTable";
import ResponseContainer from "../../shared/containers/ResponseContainer";
import { getSelectedOffDutyUsersDate } from "../../../redux/users/offDuty/offDutySelectors";
import { updateSelectedOffDutyUsersDate } from "../../../redux/users/offDuty/offDutyActions";
import ReturnHandler from "../../shared/buttons/ReturnHandler";
import { handleError } from "../../../utilities/ErrorHandler";
import { formatStandartDate } from "../../../utilities/DateHelper";

export default () => {
    const [ users, setUsers ] = useState([]);
    const [ error, setError ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const dispatch = useDispatch();
    const selectedDate = useSelector(getSelectedOffDutyUsersDate);

    useEffect(() => {
        const fetchUsers = date => {
            setIsLoading(true);
            axios.get(`${process.env.REACT_APP_API_URL}/user/off-duty`, { params: { date } })
                .then(({ data }) => setUsers(data))
                .catch(err => handleError(err, setError));
            setIsLoading(false);
        };

        fetchUsers(formatStandartDate(selectedDate));
    }, [ selectedDate ]);


    return (
        <Container fluid>
            <div className="m-2">
                <h3>Nedirbančių darbuotojų sąrašas</h3>
                <ResponseContainer error={error} isLoading={isLoading}/>
                <div className="d-flex flex-row justify-content-start">
                    <ReturnHandler/>
                    <div className="mx-2 my-auto">
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => dispatch(updateSelectedOffDutyUsersDate(date))}
                            dateFormat="yyyy-MM-dd"
                            locale={lt}
                        />
                    </div>
                </div>
            </div>
            <OffDutyUsersTable
                rows={users}
            />
        </Container>
    );
};