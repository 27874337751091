import * as React from 'react';
import {Button, Container, Form,} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { setJobPageScrollY } from '../../redux/job/jobActions';
import JobsTable from './JobsTable';
import {
  formatPeriodUrl,
  formatStandartDate,
  getDateAfterDays,
  getFirstOfThisMonth,
  getPeriodFromLocation,
  isDateValid
} from '../../utilities/DateHelper';
import JobEmployeesModal from './modals/JobEmployeesModal';
import { getUserRole, isPrivilegedRole, ROLES } from '../auth/AuthenticationService';
import PeriodHandler from './PeriodHandler';
import StatList from '../shared/containers/StatList';
import ResponseContainer from "../shared/containers/ResponseContainer";
import { handleError } from '../../utilities/ErrorHandler';
import { getStatsMap } from './helpers/helper';
import { JOB_ADMIN_STATUS, JOB_RESPONSIBLE_PERSON_STATUS } from "./util/JobStatus";
import { getAuthState } from "../../redux/auth/authSelectors";
import { navigationDisplayed } from "../../redux/navigation/navigationActions";
import UserSelector from "../shared/select/user/UserSelector";
import SearchableUserSelector from "../shared/select/user/SearchableUserSelector";
import { FILTER_DEBOUNCE_TIME, toFilterRequest } from "../shared/table/filter";
import CustomPagination from "../pagination/CustomPagination";

class JobsHome extends React.Component {
  constructor(props) {
    super(props);

    const role = getUserRole(props.auth);
    const pageNumber = Number(new URLSearchParams(this.props.location.search).get("pageNumber")) || Number(props.pageable.pageNumber);
    const pageSize = Number(new URLSearchParams(this.props.location.search).get("pageSize")) || Number(props.pageable.pageSize);
    this.state = {
      error: null,
      jobs: [],
      selectedJobId: null,
      success: null,
      stats: new Map(),
      showModal: false,
      period: props.period,
      isLoading: true,
      role,
      responsiblePersons: [],
      users: [],
      selectedResponsiblePersonId: null,
      selectedStatus: null,
      selectedResponsiblePersonStatus: null,
      selectedJobUserIds: [],
      selectedJobCreatorIds: [],
      filterParams: [],
      isShowBySubordinates: false,
      canUserFilterBySubordinates: false,
      pageable: { pageSize, pageNumber, totalPages: props.totalPages },
    };

    this.fetchAllJobs = this.fetchAllJobs.bind(this);
    this.changeJobStatus = this.changeJobStatus.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.toggleJobEmployeesModal = this.toggleJobEmployeesModal.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.onPageableChange = this.onPageableChange.bind(this);
    this.onError = this.onError.bind(this);
    this.fetchUsersByRole = this.fetchUsersByRole.bind(this);
    this.handleResponsiblePersonFilterChange = this.handleResponsiblePersonFilterChange.bind(this);
    this.handleJobUsersFilterChange = this.handleJobUsersFilterChange.bind(this);
    this.handleJobCreatorsFilterChange = this.handleJobCreatorsFilterChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleResponsiblePersonStatusChange = this.handleResponsiblePersonStatusChange.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.handleIsShowBySubordinatesChange = this.handleIsShowBySubordinatesChange.bind(this);
  }

  componentWillMount() {
    const { location } = this.props;
    const period = getPeriodFromLocation(location);
    if (period.length !== 0) {
      this.setState({
        period
      });
    }
  }

  componentWillUnmount() {
    const { jobScrollYValue } = this.props;
    jobScrollYValue(0);
  }

  componentDidMount() {
    this.props.navigationDisplayed();
    this.fetchAllJobs();
    this.fetchUsers();
  }

  fetchUsers() {
    return Promise.all([
      this.fetchUsersByRole(ROLES.ADMIN),
      this.fetchUsersByRole(ROLES.TEAM_LEADER),
      this.fetchUsersByRole(ROLES.USER)
    ]).then(response =>
        this.setState({
          responsiblePersons: [...response[0].data.users, ...response[1].data.users],
          users: response[2].data.users
        })
    );
  }

  onError(error) {
    this.setState({
      error,
      success: null,
      isLoading: false,
    });
  }

  async onPeriodChange(date, index) {
    if (isDateValid(date)) {
      const { period } = this.state;
      period[index] = date;
      this.setState({
        period,
        isLoading: true,
      }, () => {
        const { history } = this.props;
        history.push(`/jobs${formatPeriodUrl(this.state.period)}`);
      });
      await this.fetchAllJobs();
    }
  }

  async onPageableChange(pageNumber) {
    const { pageable } = this.state;
    this.setState({
      pageable: { ...pageable, pageNumber },
      isLoading: true,
    }, this.fetchAllJobs);
  }

  fetchAllJobs(isFilter) {
    this.setState({
      isLoading: true
    });
    return axios.post(`${process.env.REACT_APP_API_URL}/job/all?size=${this.state.pageable.pageSize}&page=${isFilter ? 0 : this.state.pageable.pageNumber}`, this.prepareJobsFilter())
      .then(({ data }) => this.handleJobsResponse(data))
      .catch((err) => handleError(err, this.onError));
  }

  fetchUsersByRole(role) {
    const req = `?role=${role}`
    return axios.get(`${process.env.REACT_APP_API_URL}/user${req}`)
      .catch((err) => handleError(err, this.onError));
  }

  changeJobStatus(jobId) {
    axios.put(`${process.env.REACT_APP_API_URL}/job/${jobId}/completion`)
      .then(() =>
          this.setState({
            success: 'Darbo statusas pakeistas!',
          }))
      .then(this.fetchAllJobs)
      .catch((err) => handleError(err, this.onError));
  }

  deleteRow(jobId) {
    const { jobScrollYValue } = this.props;

    axios.post(`${process.env.REACT_APP_API_URL}/job/disable/${jobId}`)
      .then(() => {
        this.setState({
          success: 'Darbas sėkmingai ištrintas!',
        });
        jobScrollYValue(window.scrollY);
      })
      .then(this.fetchAllJobs)
      .catch((err) => handleError(err, this.onError));
  }

  handleJobsResponse(data) {
    const {
      jobs, myTotalHours, myWorkedDays, totalUnitCount, canUserFilterBySubordinates, totalMeters, totalMyMeters
    } = data;
    const { stats, pageable } = this.state;
    this.setState({
      jobs: jobs.content, pageable: { ...pageable, totalPages: jobs.totalPages },
        stats: getStatsMap(stats, totalUnitCount, myTotalHours, myWorkedDays, totalMeters, totalMyMeters),
      isLoading: false,
      canUserFilterBySubordinates,
    });
  }

  prepareJobsFilter() {
    const {
      period,
      selectedResponsiblePersonId,
      selectedJobUserIds,
      selectedJobCreatorIds,
      selectedResponsiblePersonStatus,
      selectedStatus,
      filterParams,
      isShowBySubordinates,
    } = this.state;

    const request = {
      "responsiblePersonStatus": selectedResponsiblePersonStatus,
      "status": selectedStatus,
      "jobCreatorIds": selectedJobCreatorIds,
      "jobUserIds": selectedJobUserIds,
      "responsiblePersonId": selectedResponsiblePersonId,
      "periodFrom": formatStandartDate(period[0]),
      "periodTo": formatStandartDate(period[1]),
      "isShowBySubordinates": isShowBySubordinates,
    };

    return Object.assign(request, ...filterParams);
  }

  handleModalClick(jobId) {
    const { jobScrollYValue } = this.props;
    jobScrollYValue(window.scrollY);
    this.toggleJobEmployeesModal();
    this.setState({ selectedJobId: jobId });
  }

  toggleJobEmployeesModal() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal
    }), () => {
      if (this.state.showModal === false) {
        return this.fetchAllJobs();
      }
    });
  }

  onTableChange(type, { filters }) {
    const { pageable } = this.state;
    const fetch = debounce(() => {
      const filterParams = Object.keys(filters).map(key => toFilterRequest(key, filters[key]));
      this.setState({
        filterParams,
        pageable: { ...pageable, pageNumber: 0 }
      }, () => {
        this.fetchAllJobs(true)
      });
    }, FILTER_DEBOUNCE_TIME);
    fetch();
  }

  handleResponsiblePersonFilterChange(event) {
    const { pageable } = this.state;
    const id = event.target.value;
    this.setState({
        selectedResponsiblePersonId: id,
        pageable: { ...pageable, pageNumber: 0 }
      },
      this.fetchAllJobs);
  }

  handleJobUsersFilterChange(users) {
    const { pageable } = this.state;
    this.setState({
          selectedJobUserIds: users.map(u => u.userId),
          pageable: { ...pageable, pageNumber: 0 }
        },
        this.fetchAllJobs);
  }

  handleJobCreatorsFilterChange(users) {
    const { pageable } = this.state;
    this.setState({
          selectedJobCreatorIds: users.map(u => u.userId),
          pageable: { ...pageable, pageNumber: 0 }
        },
        this.fetchAllJobs);
  }

  handleResponsiblePersonStatusChange(event) {
    const status = event.target.value;
    const { pageable } = this.state;
    this.setState({
          selectedResponsiblePersonStatus: status ? status : null,
          pageable: { ...pageable, pageNumber: 0 }
        },
        this.fetchAllJobs);
  }

  handleStatusChange(event) {
    const status = event.target.value;
    const { pageable } = this.state;
    this.setState({
          selectedStatus: status ? status : null,
          pageable: { ...pageable, pageNumber: 0 }
        },
        this.fetchAllJobs);
  }

  handleIsShowBySubordinatesChange(isShowBySubordinates) {
    const { pageable } = this.state;
    this.setState({
          isShowBySubordinates,
          pageable: { ...pageable, pageNumber: 0 }
        }, this.fetchAllJobs);
  }

  render() {
    const {
      error, jobs, success, period, stats, showModal, role, isLoading, responsiblePersons, users, selectedJobId
    } = this.state;

    const multiSelectInputStyle = {
      inputField: { width: '100%' }
    };

    return (
      <Container fluid>
        <div className="m-2">
          <h3>Darbai</h3>
          <div className="d-flex flex-row justify-content-between">
            <div className="mb-2">
              <NavLink to="/jobs/add">
                <Button className="m-1" variant="primary">Pridėti darbą</Button>
              </NavLink>
              {isPrivilegedRole(role) && (
                <React.Fragment>
                  <NavLink to="/management/jobs/reports">
                    <Button className="m-1" variant="info">Darbuotojų ataskaitos</Button>
                  </NavLink>
                  <NavLink to="/management/jobs/date">
                    <Button className="m-1" variant="info">Darbai pagal datą</Button>
                  </NavLink>
                  <NavLink to="/users/off-duty">
                    <Button className="m-1" variant="info">Nedirbantys darbuotojai</Button>
                  </NavLink>
                </React.Fragment>
              )}
            </div>

          <StatList elements={stats} />
          </div>
          <div className="d-flex flex-row mb-2 mt-2">
            {isPrivilegedRole(role) && <div className="col-md-3">
              <SearchableUserSelector
                  style={multiSelectInputStyle}
                  className="w-100"
                  placeholder="Darbuotojai dirbę darbą"
                  options={[...responsiblePersons, ...users]}
                  multiple
                  onChange={this.handleJobUsersFilterChange}
                  labelKey="name"
                  id="job-workers"
              />
            </div>}
            {isPrivilegedRole(role) && <div className="col-md-3">
              <SearchableUserSelector
                  style={multiSelectInputStyle}
                  className="w-100"
                  placeholder="Darbuotojai sukūrę darbą"
                  options={[...responsiblePersons, ...users]}
                  multiple
                  onChange={this.handleJobCreatorsFilterChange}
                  labelKey="name"
                  id="job-creators"
              />
            </div>}
            <div className="col-md-2">
              <UserSelector
                  options={responsiblePersons}
                  label="Atsakingas asmuo"
                  onChange={this.handleResponsiblePersonFilterChange}
              />
            </div>
            <div className="col-md-2">
              <Form.Control as="select" onChange={this.handleStatusChange}>
                <option key="" value="">Statusas</option>
                {Object.values(JOB_ADMIN_STATUS)
                    .map(({code, name}) => (
                        <option key={code} value={code}>{name}</option>
                    ),
                )}
              </Form.Control>
            </div>
            <div className="col-md-2">
              <Form.Control as="select" placeholder="aaa" onChange={this.handleResponsiblePersonStatusChange}>
                <option key="" value="">Atsakingo asmens statusas</option>
                {Object.values(JOB_RESPONSIBLE_PERSON_STATUS)
                    .map(({code, name}) => (
                        <option key={code} value={code}>{name}</option>
                    ),
                )}
              </Form.Control>
            </div>
          </div>
          {this.state.canUserFilterBySubordinates &&
              <div className={"pl-2"}>
                <input type="checkbox" onChange={(e)=> this.handleIsShowBySubordinatesChange(e.currentTarget.checked)} defaultChecked={this.state.isShowBySubordinates} id={"isShowBySubordinates"} />
                <label htmlFor={"isShowBySubordinates"} className={"ml-2"}>Rodyti darbus pagal pavaldinius</label>
              </div>
          }
          <div className="d-flex justify-content-center">
            <PeriodHandler period={period} onPeriodChange={this.onPeriodChange} />
          </div>
          <ResponseContainer error={error} success={success} isLoading={isLoading}/>
          <div className="mt-2 pb-2">
            <JobsTable
              isLoading={isLoading}
              onTableChange={this.onTableChange}
              jobs={jobs}
              period={period}
              changeStatus={this.changeJobStatus}
              handleDelete={this.deleteRow}
              handleModalClick={this.handleModalClick}
              role={role}
              isSubordinateUsersBeingDisplayed={this.state.isShowBySubordinates}
            />
            {this.state.pageable.totalPages ? <CustomPagination
                onPageChange={(pageNumber) => this.onPageableChange(pageNumber)}
                totalPageCount={this.state.pageable.totalPages - 1}
                currentPage={this.state.pageable.pageNumber}
                pageSize={this.state.pageable.pageSize}
            /> : null}
          </div>
        </div>
        <JobEmployeesModal
          toggleJobEmployeesModal={this.toggleJobEmployeesModal}
          showModal={showModal}
          jobId={selectedJobId}
          role={role}
          isForSubordinates={this.state.canUserFilterBySubordinates}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: getAuthState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return({
    jobScrollYValue: value => dispatch(setJobPageScrollY(value)),
    navigationDisplayed: () => dispatch(navigationDisplayed())
   })
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsHome);

JobsHome.propTypes = {
  period: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  pageable: PropTypes.shape({
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    totalPages: PropTypes.number
  })
};

JobsHome.defaultProps = {
  period: [getFirstOfThisMonth(), getDateAfterDays(30)],
  pageable: {
    pageNumber: 0,
    pageSize: 100,
    totalPages: 1
  }
};
