import * as React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import UserSearchableSelectorOption from "./SearchableUserSelectorOption";

export const SearchableUserSelector = (
    {
        id,
        style,
        options,
        selected,
        name,
        labelKey,
        placeholder,
        onChange,
        inputProps,
        multiple,
        disabled
    }) => (
    <Typeahead
        id={id}
        style={style}
        disabled={disabled}
        labelKey={labelKey}
        renderMenuItemChildren={(option, props, index) => <UserSearchableSelectorOption key={index} option={option}/>}
        onChange={(value) => onChange(value, name)}
        options={options}
        multiple={multiple}
        placeholder={placeholder}
        defaultSelected={selected}
        selected={selected}
        inputProps={inputProps}
        name={name}
    />
);

export default SearchableUserSelector;