export const getStatsMap = (stats, totalUnitCount, myTotalHours, myWorkedDays, totalMeters, totalMyMeters) => {
    stats.clear();
    Object.keys(totalUnitCount).forEach(key => {
        stats.set(`Viso vienetų (${key}) kiekis`, totalUnitCount[key].toFixed(2));
    })
    stats.set('Viso mano valandų', myTotalHours);
    stats.set("Viso dirbta dienų", myWorkedDays);
    if (totalMeters) {
        stats.set('Viso metrų', totalMeters);
    }
    if (totalMyMeters) {
        stats.set('Viso mano metrų', totalMyMeters);
    }
    return stats;
}

export const getCommonDescription = (jobDescriptions, formJobDescriptions, commonDescription) => {
    const selectedJobDescs = jobDescriptions.map(jd => jd.name).join(',');
    let oldDescription = commonDescription;
    formJobDescriptions.forEach(jd => {
        oldDescription = oldDescription.replace(',' + jd.name, '')
            .replace(jd.name + ',', '')
            .replace(jd.name, '');
    });
    oldDescription = oldDescription.trim();
    return oldDescription.length === 0 ? selectedJobDescs : oldDescription + ' ' + selectedJobDescs;
}

export const formatHoursValue = (val) => {
    if (val && !isNaN(val)) {
        return val;
    } else {
        if (val.includes('.')) {
            return val.replace(".", ",").replace(/[^0-9,]+/, '');
        } else {
            return val.replace(",", ".").replace(/[^0-9.]+/, '');
        }
    }
}
