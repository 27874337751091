import axios from "axios";
import {handleError} from "../../utilities/ErrorHandler";

export const FETCH_DM_UNREAD_MESSAGES_COUNT_BEGIN = 'FETCH_DM_UNREAD_MESSAGES_COUNT_BEGIN';
export const FETCH_DM_UNREAD_MESSAGES_COUNT_FAILURE = 'FETCH_DM_UNREAD_MESSAGES_COUNT_FAILURE';
export const FETCH_DM_UNREAD_MESSAGES_COUNT_SUCCESS = 'FETCH_DM_UNREAD_MESSAGES_COUNT_SUCCESS';

export const fetchDMUnreadMessagesBegin = () => ({
    type: FETCH_DM_UNREAD_MESSAGES_COUNT_BEGIN
});

export const fetchDMUnreadMessagesFailure = (value) => ({
    type: FETCH_DM_UNREAD_MESSAGES_COUNT_FAILURE,
    payload: { value }
});

export const fetchDMUnreadMessagesSuccess = (value) => ({
    type: FETCH_DM_UNREAD_MESSAGES_COUNT_SUCCESS,
    payload: { value }
});



export const fetchDMNotifications = () =>
    (dispatch) => {
        dispatch(fetchDMUnreadMessagesBegin());
        return axios.get(`${process.env.REACT_APP_API_URL}/dm/unread`)
            .then(({ data }) => dispatch(fetchDMUnreadMessagesSuccess(data)))
            .catch(err => dispatch(fetchDMUnreadMessagesFailure(handleError(err))));
    };