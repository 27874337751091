import { STORAGE_TOKEN_KEY } from "../../redux/store";
import { map } from "lodash";

export const ROLES = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  TEAM_LEADER: 'TEAM_LEADER'
};

const PRIVILEGED_ROLES = [
  ROLES.TEAM_LEADER,
  ROLES.ADMIN
];

export const ROLE_TRANSLATIONS = {
  ADMIN: 'Vadovas',
  TEAM_LEADER: 'Team Leader',
  USER: 'Darbuotojas'
}

export const getSelectableRoles = () => {
  return map(ROLES, value => ({
    name: ROLE_TRANSLATIONS[value],
    value
  }));
}

export function isUserLoggedIn() {
  return localStorage.getItem(STORAGE_TOKEN_KEY) !== null;
}

export function getToken() {
  return localStorage.getItem(STORAGE_TOKEN_KEY);
}

export function isPrivilegedRole(role) {
  return PRIVILEGED_ROLES.includes(role);
}

export function isAdminRole(role) {
  return role === ROLES.ADMIN;
}

export function isUserRole(role) {
  return role === ROLES.USER;
}

export function isTeamLeaderRole(role) {
  return role === ROLES.TEAM_LEADER;
}

export function getUserRole(auth) {
  const { role } = auth.user;
  return role;
}

export function getUserId(auth) {
  const { userId } = auth.user;
  return userId;
}

export function getRoles() {
  return Object.values(ROLES);
}
