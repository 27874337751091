import * as React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import FormSubmitBtn from '../../shared/util/FormSubmitBtn';
import ReportExtraSchema from '../../../schemas/ReportExtraSchema';

class ReportExtraModal extends React.Component {
  render() {
    const {
      showModal, handleModalClose, handleModalSave, modalData,
    } = this.props;

    return (
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Papildomi priedai</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            schema={ReportExtraSchema.initSchema()}
            formData={modalData}
            onSubmit={(form) => handleModalSave(form.formData)}
            uiSchema={ReportExtraSchema.uiSchema}
          >
            <FormSubmitBtn />
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}


export default ReportExtraModal;

ReportExtraModal.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalSave: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  modalData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      operator: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      comment: PropTypes.string,
    }),
  ),
};

ReportExtraModal.defaultProps = {
  modalData: [],
};
