import { Form } from "react-bootstrap";
import React from "react";

export default ({ totals }) => (
    <div>
        <Form.Label>Viso valandų šiam periodui</Form.Label>
        <Form.Control type="text" disabled value={totals.jobPeriodHours}/>
        <Form.Label>Viso valandų</Form.Label>
        <Form.Control type="text" disabled value={totals.jobHours}/>
    </div>
);