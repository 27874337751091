import * as React from "react";
import DatePicker from "react-datepicker";
import lt from "date-fns/locale/lt";
import PropTypes from "prop-types";

class MonthPicker extends React.Component {
    render() {
        const { onChange, date } = this.props;
        return (
            <div>
                <span className="mr-1">Periodas: </span>
                <DatePicker
                    className="pl-1"
                    selected={date}
                    onChange={(dt) => onChange(dt)}
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                    locale={lt}
                />
            </div>
        );
    }
}

export default MonthPicker;

MonthPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
};