import * as React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { dateTimeFormatter, userCellFormatter } from "../../utilities/TableHelper";

class HistoryTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: null,
    };
  }

  componentDidMount() {
    const { records } = this.props;
    this.formatRows(records);
  }

  getColumns() {
    return [
      {
        text: 'Vartotojas',
        dataField: 'user',
        sort: true,
        formatter: userCellFormatter
      },
      {
        text: 'Laikas',
        dataField: 'createdAt',
        sort: true,
      },
      {
        text: 'Veiksmas',
        dataField: 'message',
        sort: true,
      },
    ];
  }

  formatRows(records) {
    const rows = records.map((row) => ({
      id: row.id,
      message: row.message,
      createdAt: dateTimeFormatter(row.createdAt),
      user: row.user,
    }));

    this.setState({
      rows,
    });
  }

  render() {
    const { rows } = this.state;
    return (
      <>
        { rows
        && (
          <BootstrapTable
            keyField="id"
            data={rows}
            columns={this.getColumns()}
            bootstrap4
            striped
            hover
            condensed
          />
        )}
      </>
    );
  }
}

export default HistoryTable;

HistoryTable.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    createdAtFormatted: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number,
      fullName: PropTypes.string.isRequired,
    }),
  }).isRequired).isRequired,
};
