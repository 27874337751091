import * as React from 'react';
import { Alert, Container } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import ResponseContainer from "../shared/containers/ResponseContainer";
import { handleError } from '../../utilities/ErrorHandler';
import VacationsTable from "./VacationsTable";
import { getUserRole } from "../auth/AuthenticationService";
import { getAuthState } from "../../redux/auth/authSelectors";

class VacationsHome extends React.Component {
    constructor(props) {
        super(props);
        const role = getUserRole(props.auth);

        this.state = {
            error: null,
            vacations: [],
            success: null,
            isLoading: true,
            role: role
        };

        this.fetchAllVacations = this.fetchAllVacations.bind(this);
        this.onError = this.onError.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    componentDidMount() {
        this.fetchAllVacations();
    }

    onError(error) {
        this.setState({
            error,
            success: null,
            isLoading: false,
        });
    }

    fetchAllVacations() {
        axios.get(`${process.env.REACT_APP_API_URL}/vacations`)
            .then((response) => this.handleResponse(response.data))
            .catch((err) => handleError(err, this.onError));
    }

    handleResponse(vacations) {
        this.setState({
            vacations,
            isLoading: false
        });
    }

    changeStatus(id, status) {
        this.setState({
            isLoading: true
        });
        axios.patch(`${process.env.REACT_APP_API_URL}/vacations/${id}/${status}`)
            .then(() => this.fetchAllVacations())
            .catch((err) => handleError(err, this.onError));
    }

    render() {
        const {
            error, vacations, success, isLoading, role
        } = this.state;

        return (
            <Container fluid>
                <div className="m-2">
                    <h3>Atostogų prašymai</h3>
                    <ResponseContainer error={error} success={success} isLoading={isLoading}/>
                    {!isLoading && vacations.length > 0 && (
                        <VacationsTable
                            rows={vacations}
                            changeStatus={this.changeStatus}
                            role={role}
                        />
                    )}
                    {!isLoading && vacations.length === 0 && (
                        <Alert variant="info">
                            Atostogų prašymų nėra.
                        </Alert>
                    )}
                </div>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: getAuthState(state),
    };
}

export default connect(mapStateToProps)(VacationsHome);

